import React, { useState } from 'react';
import {
  CssBaseline,
  styled,
  Box,
  Divider,
  Stepper,
  Step,
  StepLabel,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { StepIconProps } from '@mui/material/StepIcon';

import { headerStyle, pageWrapper, titleUnderline, titleNote } from '../profile/form-component/styles';

import HDButton from '../../hd-ui-kit/HDButton/HDButton';
import HDAccordion from '../../hd-ui-kit/HDAccordion';
import { reactGAEvents } from '../../commons/commonFunctions';
import Link from '@mui/material/Link';

const QontoStepIconRoot = styled('div')<{ ownerState: { active?: boolean; completed?: boolean } }>(
  ({ theme, ownerState }) => ({
    color: theme.palette.mode === 'dark' ? '#FFFFFF' : '#002136',
    '& .QontoStepIcon-circle': {
      width: 7,
      height: 7,
      borderRadius: '50%',
      border: '1.5px solid #002136',
      borderColor: theme.palette.mode === 'dark' ? '#FFFFFF' : '#002136',
    },
  })
);

function QontoStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active, completed }} className={className}>
      <div className="QontoStepIcon-circle" />
    </QontoStepIconRoot>
  );
}

function DevelopmentPlan(props: any) {
  const theme = useTheme();
  const themeMode = theme.palette.mode;
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const planData = props?.developmentPlanData || '';

  const [expanded, setExpanded] = useState<string | false>('panel0');

  const handleChange = (panel: string, ind: number) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    let action = '';
    if (newExpanded) {
      action = ind === 0 ? 'open-theme-1' : ind === 1 ? 'open-theme-2' : 'open-theme-3';
    } else {
      action = ind === 0 ? 'close-theme-1' : ind === 1 ? 'close-theme-2' : 'close-theme-3';
    }

    reactGAEvents('coach-development-plan', action);
    setExpanded(newExpanded ? panel : false);
  };

  const fontFamily = { fontFamily: 'Aventa' };
  const fontColor = { color: (themeMode === 'light') ? '#002136' : '#fff' };
  const ArrowIcon = styled(ArrowForwardIcon)(() => ({
    color: (themeMode === 'light') ? '#66FCFC' : '#18768C',
  }));

  const sxReminderTitle = {
    fontFamily: 'Aventa',
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '19px',
    color: (themeMode === 'light') ? '#002136' : '#fff',
    marginBottom: '16px',
  };

  const sxReflectionTitle = {
    fontFamily: 'Aventa',
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '19px',
    color: (themeMode === 'light') ? '#002136' : '#fff',
    marginTop: '48px',
    marginBottom: '16px',
  };

  const sxNumberedDescription = {
    ...fontFamily,
    ...fontColor,
    position: 'relative',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '22.96px',
    display: 'flex',
    alignItems: 'center',
    padding: '12px 50px 12px',
  };

  const sxLinkButton = {
    ...fontFamily,
    color: (themeMode === 'light') ? '#18768C' : '#2CDADA',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '22.96px',
    textDecorationLine: 'underline',
    paddingLeft: '6px',
    cursor:'pointer'
  };

  const SCNummberBox = {
    ...fontFamily,
    position: 'absolute',
    left: 0,
    width: '39px',
    height: '39px',
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '8px',
    gap: '10px',
    background: '#18768C',
    color: '#fff',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '22.96px',
    marginRight: '16px',
  };

  const scheduleSessionStyle = {
    marginTop: '16px',
    marginBottom: '32px',
  };

  const sxStepDivider = {
    height: '90%',
    width: 11,
    position: 'absolute',
    zIndex: 1,
    borderColor: 'rgba(100, 106, 124, 0.2)',
    top: '20px'
  };

  const sxLeftStepper = {
    position: 'relative',
    width: '40px',
  };

  const SCReminder = styled(Box)(() => ({
    background: (themeMode === 'light') ? '#F8F8F8' : '#002136',
    border: (themeMode === 'light') ? '1px solid rgba(100, 106, 124, 0.2)' : 'none',
    borderRadius: '6px',
    padding: '27px 32px',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: (isMobile) ? 'column' : 'row',
    marginBottom: '16px',
  }));

  const handleViewSession = () => {
    reactGAEvents('coach-development-plan', 'dp-not-started-view-session'); props?.handleTabChange({}, 0)
  };

  return (
    <>
      <CssBaseline />
      <Box sx={pageWrapper(isMobile, themeMode)}>
        {planData ? (
          <>
            <Typography sx={titleNote(themeMode)}>Development Plan</Typography>
            <Typography sx={headerStyle(themeMode)}>What are you working on to position yourself for a CMO role?</Typography>
            <Divider sx={titleUnderline(themeMode)} />
            {planData?.map((planItem: any, ind: number) => {
              return (
                <HDAccordion
                  title={planItem.theme_name}
                  expanded={expanded === `panel${ind}`}
                  onChange={handleChange(`panel${ind}`, ind)}
                  key={ind}
                >
                  <Box sx={sxLeftStepper}>
                    <Divider orientation="vertical" sx={sxStepDivider} flexItem />
                    <Stepper
                      orientation="vertical"
                      connector={null}
                      sx={{ top: 0, width: '40px' }}
                    >
                      <Step sx={{ paddingLeft: '7px', paddingRight: '30px' }}>
                        <StepLabel
                          sx={{
                            padding: '10px 0 60px 0',
                            zIndex: '9',
                            position: 'relative'
                          }}
                          StepIconComponent={QontoStepIcon}
                        />
                      </Step>
                      <Step sx={{ paddingLeft: '7px', paddingRight: '30px' }}>
                        <StepLabel
                          sx={{
                            padding: '10px 0 60px 0',
                            zIndex: '9',
                            position: 'relative'
                          }}
                          StepIconComponent={QontoStepIcon}
                        />
                      </Step>
                    </Stepper>
                  </Box>
                  <Box>
                    <Typography sx={sxReminderTitle}>Practice these behaviors repeatedly</Typography>
                    {planItem?.reminders?.map((reminderItem: any, reminderInd: number) => {
                      return (
                        <SCReminder key={reminderInd}>{reminderItem}</SCReminder>
                      );
                    })}
                    <Typography sx={sxReflectionTitle}>Notes from my coach</Typography>
                    <ul>
                      {planItem?.objectives?.map((objectivesItem: any, objectivesInd: number) => {
                        return (
                          <li key={objectivesInd}>{objectivesItem}</li>
                        );
                      })}
                    </ul>
                  </Box>
                </HDAccordion>
              );
            })}
          </>
        ) : (
          <>
            <Typography sx={titleNote(themeMode)}>Development Plan</Typography>
            <Typography sx={headerStyle(themeMode)}>You don’t have a development plan yet. <br /> Here’s how you’ll get one:</Typography>
            <Divider sx={titleUnderline(themeMode)} />
            <Box sx={sxNumberedDescription}>
              <Box sx={SCNummberBox}>1.</Box>Join your <Link tabIndex={0} underline='none' onClick={() => handleViewSession()}  sx={sxLinkButton} >first coaching session</Link>
            </Box>
            <Box sx={sxNumberedDescription}>
              <Box sx={SCNummberBox}>2.</Box>Discuss your reports and goals with your coach.
            </Box>
            <Box sx={sxNumberedDescription}>
              <Box sx={SCNummberBox}>3.</Box>Your coach will assign you actionable behaviors to help you gain the experience and skills you need to become a CMO.
            </Box>
            <HDButton
              text={'View session details'}
              showAnimation
              endIcon={<ArrowIcon />}
              variant={'contained'}
              sx={scheduleSessionStyle}
              onClick={() => handleViewSession()}
            />
          </>
        )}
      </Box>
    </>
  );
}

export default DevelopmentPlan;
