import React from 'react';
import { Box, IconButton, styled, Typography, useTheme, useMediaQuery, Divider } from '@mui/material';
import HDButton from '../../hd-ui-kit/HDButton';
import ArrowForwardSharpIcon from '@mui/icons-material/ArrowForwardSharp';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import HDNotificationBanner from '../../hd-ui-kit/HDNotificationBanner';
import { FullScreenModal } from '../FullScreenModal';
import SelectCoach from './SelectCoach';
import SelectDateTime from './SelectDateTime';
import { reactGAEvents } from '../../commons/commonFunctions';

const FindCoach = (props: any) => {
  const { step, setStep, selectedCoachItem, hideBackBtn } = props;
  const theme = useTheme();
  const isDarkTheme = useTheme().palette.mode === 'dark';
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const StyledBackBox = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
  }));

  const StyledArrowLeftIcon = styled(KeyboardArrowLeftIcon)(() => ({
    width: 28,
    height: 28,
    color: isDarkTheme ? '#fff' : '#002136',
    marginRight: '-1px',
    cursor: 'pointer',
  }));

  const StyledBackText = styled(Typography)(() => ({
    fontFamily: 'Avenir',
    fontWeight: 300,
    fontSize: 16,
    lineHeight: '24px',
    color: isDarkTheme ? '#ccc' : '#334255',
    marginTop: '1.5px',
    cursor: 'pointer',
  }));

  const btnStyle = {
    background: '#F1F1F1',
    color: '#002136',
    fontFamily: 'Aventa',
    fontWeight: 600,
    fontSize: '15px',
    lineHeight: '22px',
    marginRight: '9px',
    whiteSpace: 'nowrap',
    '&:hover': {
      background: '#F1F1F1',
    },
    '&:focus': {
      border: '1px solid #F1F1F1 !important;'
    },
    '&::before': {
      background: '#F1F1F1 !important;',
      color: '#002136',
    }
  };

  const StyledStepTypo = styled(Typography)(() => ({
    fontFamily: 'Avenir',
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '16px',
    lineHeight: '24px',
    color: isDarkTheme ? '#CCCCCC' : '#002136',
  }));
  const StyledSelectText = styled(Typography)(() => ({
    fontFamily: 'Aventa',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '31px',
    lineHeight: '41px',
    color: isDarkTheme ? '#FFFFFF' : '#002136',
    marginBottom: '16px',
  }));

  const StyledDividerSmall = styled(Divider)(() => ({
    color: isDarkTheme ? '#66FCFC' : '#18768C',
    marginTop: '-2px',
    marginBottom: '6px',
    width: '4%',
    backgroundColor: isDarkTheme ? '#66FCFC' : '#18768C',
  }));

  const sxFormTitleSection = { display: 'flex', justifyContent: 'space-between', alignItems: 'center' };

  const ArrowForwardSharpIconStyled = styled(ArrowForwardSharpIcon)(() => ({
    color: '#18768C',
  }));

  const StyledParentBox = {
    display: isMobile ? 'flex' : 'block',
    width: '100%',
    background: '#18768C',
  };

  const title = step === 1 ? `Select a date and time for your session with ${selectedCoachItem.first_name}` : 'Select a recommended coach';

  const CaochHDFooter = () => {
    return (
      <HDNotificationBanner
        StyledParentBox={StyledParentBox}
        content={'Struggling to find a coach? We can help.'}
        variant={'filled'}
        severity={'success'}
        icon={false}
        action={
          <>
            <HDButton
              showAnimation={true}
              text={'Contact us'}
              variant={'contained'}
              size={'medium'}
              sx={btnStyle}
              endIcon={<ArrowForwardSharpIconStyled />}
              onClick={() => {
                reactGAEvents('coach-select-coach', 'select-coach-support');
                window.open('https://heidricksupport.zendesk.com/hc/en-us', '_blank', 'noopener,noreferrer');
              }}
            />
          </>
        }
        style={{
          textAlign: 'left',
          height: isMobile ? '100px' : '70px',
          alignItems: isMobile ? 'flex-start' : 'center',
          flexDirection: isMobile ? 'column' : 'row',
          '& .MuiAlert-action': {
            margin: isMobile ? '5px 0px 0px 0px ' : '0px -8px 0px auto',
            padding: isMobile ? '0px' : '4px 0 0 16px',
          },
          '& .MuiAlert-message': {
            fontFamily: 'Aventa',
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '23px'
          }
        }}
      />
    );
  };

  return (
    <FullScreenModal
      handleFSModal={props.handleFSModal}
      openFSModal={props.openFSModal}
      footer={step === 1 || props?.errorMsg ? null : <CaochHDFooter />}
      modalRef={props.coachModalRef}
    >
      {step === 1 && !hideBackBtn && (
        <StyledBackBox>
          <StyledArrowLeftIcon />
          <StyledBackText onClick={() => setStep(0)}>Back</StyledBackText>
        </StyledBackBox>
      )}
      <Box sx={sxFormTitleSection}>
        <StyledStepTypo>STEP {step + 1} OF 2</StyledStepTypo>
        <IconButton onClick={props.handleFSModal}>
          <img alt='Close modal' src={(window.location.origin) + '/res/hd-ui-kit-images/' + ((isDarkTheme) ? 'close-dark.svg' : 'close.svg')} />
        </IconButton>
      </Box>
      <StyledSelectText>{title}</StyledSelectText>
      <StyledDividerSmall />
      {step === 1 ? (
        <SelectDateTime
          availableDates={props?.availableDates}
          availabilityAllData={props?.availabilityAllData}
          selectedCoach={selectedCoachItem}
          nextSessionId={props?.nextSessionId}
        />
      ) : (
        <SelectCoach
          loading={props.loading}
          loadingMsg={props.loadingMsg}
          tabsDataCoach={props.tabsDataCoach}
          allCoachData={props.allCoachData}
          errorMsg={props?.errorMsg}
        />
      )}
    </FullScreenModal>
  );
};

export default FindCoach;
