import React, { useRef } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled, useTheme, useMediaQuery } from '@mui/material';
import HDCard from '../HDCard/index';
import HDButton from '../HDButton';
import HDProgressBar from '../HDProgressBar';
import CloseIcon from '@mui/icons-material/Close';
import HDSpinner from '../HDSpinner';
import ConfirmationModal from '../../organisms/modals/ConfirmationModal';

export interface HDDocUploadTypes {
  handleFileUpload: Function;
  progress?: number;
  handleFileDelete: Function;
  fileData?: any;
  errorMsg?: any;
  handleClickDownload?: any;
  onOpenDelete?: any;
  onCloseDelete?: any;
  showDeleteModal?: boolean;
  dataTestId?: any;
  downBtnDisable?: any
}

const HDDocUpload = ({ downBtnDisable, dataTestId, handleFileUpload, progress, handleFileDelete, fileData, errorMsg, handleClickDownload, onCloseDelete, onOpenDelete, showDeleteModal }: HDDocUploadTypes) => {
  const inputRef = useRef<any>(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const wrapStyling = {
    flexGrow: 1,
    alignItems: 'center',
    display: 'flex',
  };

  const startedCardStyle = {
    background: theme.palette.mode == 'light' ? '#f8f8f8' : '#002136',
  };

  const TypographyAddResume = styled(Typography)(() => ({
    marginLeft: '7px',
    fontFamily: 'Aventa',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '23px',
    color: theme.palette.mode == 'light' ? '#002136' : '#FFFFFF',
  }));

  const MainWrapper = styled(Box)(() => ({
    marginLeft: '30px',
    textAlign: 'start',
  }));

  const MainBtnWrapper = styled(Box)(() => ({
    display: 'flex',
  }));

  const TypographyFormatText = styled(Typography)(() => ({
    fontFamily: 'Avenir',
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '16px',
    lineHeight: '24px',
    color: theme.palette.mode == 'light' ? '#334255' : '#CCCCCC',
    wordBreak: 'break-all',
  }));

  const TypographyFormatTextSize = styled(Typography)(() => ({
    fontFamily: 'Avenir',
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '16px',
    lineHeight: '24px',
    color: theme.palette.mode == 'light' ? '#334255' : '#CCCCCC',
    marginTop: '6px',
  }));

  const btnStyleStart = {
    border: theme.palette.mode == 'dark' ? (progress ? '1px solid #FFFFFF' : '1px solid #99A6AF') : progress ? '1px solid #002136' : '1px solid #646A7C',
    fontWeight: 600,
    fontFamily: 'Aventa',
    fontStyle: 'normal',
    padding: isMobile ? '6px 15px' : '7px 25px',
  };

  const BtnStyledDel = {
    paddingLeft: '0px',
    fontWeight: 600,
    fontFamily: 'Aventa',
    fontStyle: 'normal',
  };

  const handleClick = () => {
    inputRef.current.click();
  };

  const ErrorStyle = {
    color: '#cc33a1 !important',
    fontFamily: 'Avenir',
    textAlign: 'start',
    fontSize: '16px',
    marginTop: '20px',
  };

  const noBtnSx = { padding: '11px 10px' };

  return (
    <HDCard sx={startedCardStyle}>
      <Box sx={wrapStyling}>
        <img alt='Add resume logo' src={theme.palette.mode == 'light' ? '../res/hd-ui-kit-images/plusUploadIconLight.svg' : '../res/hd-ui-kit-images/plusUploadIconDark.svg'} />

        {(fileData?.fileName) ? (
          <TypographyAddResume>Your resume has been successfully added to our database.</TypographyAddResume>
        )
          : (
            <TypographyAddResume> Add Resume (optional)</TypographyAddResume>
          )}
      </Box>
      <Box>&nbsp;</Box>
      <MainWrapper>
        <Box>
          {(fileData?.fileName) ? (
            <Box>
              <TypographyFormatText>{fileData?.fileName}</TypographyFormatText>
              <TypographyFormatTextSize>{fileData?.fileSize}</TypographyFormatTextSize>
            </Box>
          ) : (
            <TypographyFormatText>PDF (Preferred), DOCX, DOC, RTF, TXT up to 2MB</TypographyFormatText>
          )}
          {errorMsg && (
            <Typography variant="h2" sx={ErrorStyle}>
              {errorMsg}
            </Typography>
          )}
        </Box>

        {progress && !fileData ? <HDProgressBar benchMark={0} progressDescription={''} progress={progress} primaryColor={''} aboveBMBarColor={''} belowBMBarColor={''} /> : <></>}
        <Box>&nbsp;</Box>
        <MainBtnWrapper>
          {(fileData?.fileName) ? (
            <></>
          ) : (
            <Box>
              <input data-testId={dataTestId} multiple={false} hidden accept=".docx,.pdf,.doc,.rtf,.txt" ref={inputRef} type="file" disabled={Boolean(progress)} onChange={(e) => handleFileUpload(e)} />

              <HDButton
                startIcon={progress ? <HDSpinner buttonColor={true} size={'la-sm'} /> : <img alt='Document upload logo' src={theme.palette.mode == 'light' ? '../res/hd-ui-kit-images/iconUploadLight.svg' : '../res/hd-ui-kit-images/iconUploadDark.svg'} />}
                onClick={handleClick}
                text={'Add new'}
                sx={btnStyleStart}
                variant={'outlined'}
              />
            </Box>
          )}
          <Box>{(fileData?.fileName) ? <HDButton startIcon={<CloseIcon />} onClick={() => onOpenDelete()} text={'Delete'} sx={BtnStyledDel} variant={'text'} /> : <></>}</Box>
          {(fileData?.fileName) && (
            <Box>
              <HDButton disabled={downBtnDisable} startIcon={downBtnDisable ? <HDSpinner buttonColor={true} size={'la-sm'} /> : <img alt='Download logo' src={theme.palette.mode == 'light' ? '../res/downloadLight.svg' : '../res/downloadDark.svg'} />} onClick={() => handleClickDownload(fileData?.id)} text={'Download'} sx={btnStyleStart} variant={'outlined'} />
            </Box>
          )}
        </MainBtnWrapper>
      </MainWrapper>
      {showDeleteModal && <ConfirmationModal header="Delete Resume" description="Confirm that you want to delete your resume." noBtnText={'No, I’ll keep it'} noBtnSx={noBtnSx} onYes={() => handleFileDelete()} onNo={() => onCloseDelete()} onClose={() => onCloseDelete()} />}
    </HDCard>
  );
};

export default HDDocUpload;
