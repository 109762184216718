import React, { useEffect, useState } from 'react';
import Header from '../../commons/Header';
import BreadCrumb from '../../hd-ui-kit/HDBreadcrumb';
import Footer from '../../commons/Footer';
import { Box, Grid, Typography, Container, ListItem, List, useTheme, useMediaQuery, CssBaseline, styled, Divider } from '@mui/material';
import HDStatusProgressBar from '../../hd-ui-kit/HDStatusProgressBar';
import HDCard from '../../hd-ui-kit/HDCard';
import { useLocation, useNavigate } from 'react-router-dom';
import { reactGAEvents, LegendContent, redirectionApi, backgroundBottom, backgroundBottomDark, CoachingBanner } from '../../commons/commonFunctions';

function SkillReportDetails() {
  const [progressBar, setprogressBar] = useState<any>({});
  const [cardsData, setCardsData] = useState<any>({});
  const location = useLocation();

  const locationParam: any = location?.state;
  const theme = useTheme();
  const isDark = theme.palette.mode === 'dark';
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  let navigate = useNavigate();

  const breadCrumbRoutes = [
    { text: 'Home', href: '/home' },
    { text: 'Heidrick Capabilities Report', href: '/leadership-skills/overview' },
    { text: locationParam?.skillDetails?.title, href: '#' },
  ];

  const gaTagEvents = [
    () => {
      reactGAEvents('my-report-leadership-skills-details', 'my-reports');
    },
    () => {
      reactGAEvents('my-report-leadership-skills-details', ' leadership-capabilities-report');
    },
    () => { },
  ];

  const BodyWrapper = styled(Box)(() => ({
    marginBottom: '60px',
  }));

  const DescriptionBox = styled(Box)(() => ({
    textAlign: 'left',
    marginBottom: '30px',
  }));

  const ProgressBarWrap = styled(Box)(() => ({
    border: '1px solid #CCD3D7',
    borderRadius: '5px',
    padding: '30px',
    textAlign: 'left',
    marginBottom: '60px',
    fontFamily: 'Aventa',
    fontSize: '20px',
    color: '#646A7C',
    fontWeight: 500,
  }));

  const descStyle = {
    fontFamily: 'Avenir',
    fontWeight: 300,
    fontSize: '16px',
    color: isDark ? '#fff' : '#334255',
  };

  const wrapStyling = {
    flexGrow: 1,
    alignItems: 'center',
    display: 'flex',
    marginBottom: '20px',
  };

  const cardBackground = {
    background: isDark ? '#002136' : '#F8F8F8',
    textAlign: 'left',
    border: '1px solid ' + (isDark ? 'transparent' : '#DADCDF'),
  };

  const descWrap = {
    marginTop: '10px',
  };

  const ListStyles = {
    listStyleType: 'disc',
    padding: '0 0 0 30px',
    fontFamily: 'Avenir',
    fontSize: '16px',
    color: isDark ? '#fff' : '#334255',
    fontWeight: 300,
    lineHeight: '24px',
  };

  const ListItemStyle = {
    display: 'list-item',
    padding: 0,
    marginBottom: '15px',
  };

  useEffect(() => {
    setprogressBar(locationParam?.skillDetails);
    setCardsData(locationParam?.skillDetails?.cardsData);
    redirectionApi(navigate);
  }, []);

  const titleHeadStyle = {
    fontSize: '31px',
    fontWeight: 500,
  };

  const subTitleStyle = {
    fontSize: '22px',
    fontWeight: 400,
  };

  const cardHeadingStyle = {
    fontSize: '16px',
    fontWeight: 500,
  };

  const SCDivider = styled(Divider)(() => ({
    marginTop: '32px !important',
    marginBottom: '32px !important',
  }));

  const headWrapStyle = {
    marginTop: '48px',
    marginBottom: '48px',
  };

  const descStyleSubtitle = {
    paddingTop: '15px',
    fontFamily: 'Avenir',
    fontWeight: 300,
    fontSize: '16px',
    color: isDark ? '#fff' : '#334255',
  };

  return (
    <>
      <CssBaseline />
      <Header />
      <Box sx={isDark ? { ...backgroundBottomDark } : { ...backgroundBottom }}>
        <Container maxWidth='lg'>
          <BodyWrapper>
            <Box sx={headWrapStyle}>
              <BreadCrumb routes={breadCrumbRoutes} gaTagEvents={gaTagEvents} />
              {!isMobile && <SCDivider />}
              <DescriptionBox>
                <Typography variant='h1' sx={titleHeadStyle}>
                  {progressBar?.title}
                </Typography>
                <Typography sx={descStyle}>{progressBar?.description}</Typography>
                <Typography sx={descStyleSubtitle}>As you review your Heidrick capabilities, consider where you meet this target and identify opportunities for growth and development. Talk with your coach to explore ways to achieve your leadership goals.</Typography>
              </DescriptionBox>
            </Box>
            <Box sx={headWrapStyle}>
              {<CoachingBanner />}
            </Box>

            <ProgressBarWrap>
              <HDStatusProgressBar progress={progressBar?.youPercent} showProgressDots={false} mark={progressBar?.rolePercent} markName={locationParam?.userDetails?.businessRole?.name} height={20} markColor={isDark ? '#fff' : '#0B0C10'} legendText={<LegendContent />} />
            </ProgressBarWrap>

            <Grid container spacing={4} sx={{ flexDirection: { xs: 'column', md: 'row' } }}>
              <Grid item xs={12} md={6}>
                <Box sx={wrapStyling}>
                  <Typography variant='h2' sx={subTitleStyle}>
                    Understanding your score
                  </Typography>
                </Box>

                {cardsData && cardsData?.whereAreYouNow && (
                  <HDCard sx={cardBackground}>
                    <Box>
                      <Box>
                        <Typography variant='h6' sx={cardHeadingStyle}>
                          Where are you now?
                        </Typography>
                      </Box>
                      <Box sx={descWrap}>
                        {Array.isArray(cardsData?.whereAreYouNow) ? (
                          <List sx={ListStyles}>
                            {cardsData?.whereAreYouNow?.map((arrVal: any, index: number) => (
                              <ListItem sx={ListItemStyle} key={'listitem' + index}>
                                {arrVal}
                              </ListItem>
                            ))}
                          </List>
                        ) : (
                          <Typography sx={descStyle}>{cardsData?.whereAreYouNow}</Typography>
                        )}
                      </Box>
                    </Box>
                  </HDCard>
                )}
                <br />

                {cardsData && cardsData.devTips && (
                  <HDCard sx={cardBackground}>
                    <Box>
                      <Box>
                        <Typography variant='h6' sx={cardHeadingStyle}>
                          Development tips
                        </Typography>
                      </Box>
                      <Box sx={descWrap}>
                        {Array.isArray(cardsData?.devTips) ? (
                          <List sx={ListStyles}>
                            {cardsData?.devTips?.map((arrVal: any, index: number) => (
                              <ListItem sx={ListItemStyle} key={'listitem' + index}>
                                {arrVal}
                              </ListItem>
                            ))}
                          </List>
                        ) : (
                          <Typography sx={descStyle}>{cardsData?.devTips}</Typography>
                        )}
                      </Box>
                    </Box>
                  </HDCard>
                )}
                <br />

                {cardsData && cardsData.whatGreatLooksLike && (
                  <HDCard sx={cardBackground}>
                    <Box>
                      <Box>
                        <Typography variant='h6' sx={cardHeadingStyle}>
                          What great '{locationParam?.skillDetails?.title?.toLowerCase()}' looks like
                        </Typography>
                      </Box>
                      <Box sx={descWrap}>
                        {Array.isArray(cardsData?.whatGreatLooksLike) ? (
                          <List sx={ListStyles}>
                            {cardsData?.whatGreatLooksLike?.map((arrVal: any, index: number) => (
                              <ListItem sx={ListItemStyle} key={'listiem' + index}>
                                {arrVal}
                              </ListItem>
                            ))}
                          </List>
                        ) : (
                          <Typography sx={descStyle}>{cardsData?.whatGreatLooksLike}</Typography>
                        )}
                      </Box>
                    </Box>
                  </HDCard>
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                <Box sx={wrapStyling}>
                  <Typography variant='h2' sx={subTitleStyle}>
                    What makes up '{locationParam?.skillDetails?.title?.toLowerCase()}'?
                  </Typography>
                </Box>
                {cardsData &&
                  cardsData['energizers'] &&
                  Object.keys(cardsData?.['energizers'])?.map((data: any, index: number) => (
                    <React.Fragment key={index}>
                      <HDCard sx={cardBackground}>
                        <Box>
                          <Box>
                            <Typography variant='h6' sx={cardHeadingStyle}>
                              {cardsData?.['energizers']?.[data]?.['name']}
                            </Typography>
                          </Box>
                          <Box sx={descWrap}>
                            <Typography sx={descStyle}>{cardsData?.['energizers']?.[data]?.['description']}</Typography>
                          </Box>
                        </Box>
                      </HDCard>
                      <br />
                    </React.Fragment>
                  ))}
              </Grid>
            </Grid>
          </BodyWrapper>
        </Container>
      </Box>
      <Footer />
    </>
  );
}

export default SkillReportDetails;
