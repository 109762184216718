import React, { useState } from 'react';
import HDCoachProfileCard from '../../hd-ui-kit/HDCoachProfileCard/HDCoachProfileCard';
import { Box, useTheme, IconButton, useMediaQuery } from '@mui/material';
import { CoachBioDescrition } from '../../commons/CoachComponents';

const ViewCoachProfile = (props: any) => {
  const { data, lastName, firstName, avatar } = props;
  const [isReadMore, setIsReadMore] = useState(true);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const isDarkTheme = useTheme().palette.mode === 'dark';

  const sxFormTitleSection = { display: 'flex', justifyContent: 'end', alignItems: 'center', marginBottom: '20px' };
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };


  return (
    <>
      <Box sx={sxFormTitleSection}>
        <IconButton onClick={props.handleFSModal}>
          <img alt="Close modal logo" src={window.location.origin + '/res/hd-ui-kit-images/' + (isDarkTheme ? 'close-dark.svg' : 'close.svg')} />
        </IconButton>
      </Box>
      <HDCoachProfileCard
        showButton={false}
        showAnimation={true}
        profileBGMobile={isDarkTheme ? './res/coachProfileBGMobileDark.svg' : './res/coachProfileBGMobileLight.svg'}
        profileBGDark={isDarkTheme ? './res/coachProfileBGDark.svg' : './res/coachProfileBGLight.svg'}
        profileImg={avatar}
        firstName={firstName}
        lastName={lastName}
      />

      {CoachBioDescrition(data, isMobile, isDarkTheme, isReadMore, toggleReadMore, 'coachProfile')}
    </>
  );
};

export default ViewCoachProfile;
