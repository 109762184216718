import * as React from 'react';
import {
  Box,
  Card,
  CardContent,
  Grid,
  styled,
  Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import HDSlider from '../HDSlider/HDSlider';
import { useNavigate } from 'react-router';
import Link from '@mui/material/Link';
import { detailsBtnStyle, handleForward , ResponsiveThemeValue, ResponsiveMobileValue} from '../../commons/commonFunctions';

export interface HDLeadershipSkillCardTypes {
  title: string;
  description: string;
  youPercent: number;
  rolePercent: number;
  roleName: string;
  isPDFCard?: boolean;
  forwardClick?: () => void;
  pathname: any | null;
  state: any;
}

function HDLeadershipSkillCard({
  title,
  description,
  youPercent,
  rolePercent,
  roleName,
  isPDFCard = false,
  forwardClick,
  pathname,
  state
}: HDLeadershipSkillCardTypes) {
  const theme = useTheme();
  const isDark = theme.palette.mode === 'dark';
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  let navigate = useNavigate();

  const StyledCardBox = styled(Card)(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    background: isPDFCard ? 'transparent' : ResponsiveThemeValue(theme,  '#f8f8f8','#002136'),
    color: isDark ? '#cccccc' : '#002136',
    width: '100%',
    borderRadius: '6px',
    padding: isPDFCard ? '6px 0' : isMobile ? '1.5rem' : '2rem',
    boxShadow: 'none',
    border: '1px solid ' + (ResponsiveThemeValue(theme, '#DADCDF', 'transparent' )),
  }));

  const StyledCardContent = styled(CardContent)(() => ({
    width: '100%',
    padding: '0px',
    marginBottom: isPDFCard ? '12px' : '43.5px',
  }));

  const StyledCardContentLeft = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    borderRight: isPDFCard ? 'none' : ResponsiveMobileValue(isMobile,'none' , '1px solid rgb(100, 106, 124, 0.3)'),
    borderBottom: isPDFCard ? 'none' : ResponsiveMobileValue(isMobile,'1px solid rgb(100, 106, 124, 0.3)' , 'none'),
    paddingBottom: isPDFCard ? 0 : ResponsiveMobileValue(isMobile, 24 , 0),
    paddingRight: '1rem',
  }));

  const StyledCardTitle = styled(Typography)(() => ({
    fontFamily: 'Aventa',
    fontWeight: 500,
    fontSize: 20,
    lineHeight: '28.7px',
    color: ResponsiveThemeValue(theme,  '#002136', '#fff'),
    marginBottom: ResponsiveMobileValue(isMobile, 16 , 0),
  }));

  const StyledDescription = styled(Typography)(({ theme }) => ({
    ...theme.typography.body1,
    fontFamily: 'Avenir',
    fontWeight: 300,
    lineHeight: '24px',
    marginTop: '2px',
    color: ResponsiveThemeValue(theme, '#334255','#cccccc')
  }));

  const StyledScoreContentWrapper = styled(Box)(() => ({
    fontFamily: 'Avenir',
    position: 'relative',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    paddingLeft: ResponsiveMobileValue(isMobile, 0 , 32),
  }));

  const StyledScoreContent = styled(Box)(() => ({
    position: 'relative',
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'Avenir',
    fontWeight: '300',
    fontSize: '16px',
    lineHeight: '24px',
    paddingTop: ResponsiveMobileValue(isMobile, 16 , 0),
  }));

  const StyledDivider = styled(Typography)(() => ({
    position: 'relative',
  }));

  const StyledBottomLineWrapper = styled(Box)(() => ({
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    height: '4px'
  }));

  const StyledBottomLine = styled('div')(() => ({
    width: ResponsiveMobileValue(isMobile,'92%' , '86%'),
    height: '1px',
    backgroundColor: ResponsiveThemeValue(theme, 'rgb(0, 33, 54, 0.2)' ,'rgb(255, 255, 255, 0.6)'),
    paddingRight: '15px'
  }));
  const StyledStatusWrapper = styled('div')(() => ({
    fontWeight: 300,
    lineHeight: '24px',
    marginTop: '30px',
    fontFamily: 'Avenir',
    fontStyle: 'normal',
    fontSize: '16px',
    color: ResponsiveThemeValue(theme, '#002136', '#CCCCCC'),
  }));
  const statusText = rolePercent > youPercent ? 'on your way to' : 'exceed';
  const statusTextAbbre = rolePercent > youPercent ? 'are ' : '';
  const percentColor = isDark ? rolePercent > youPercent ? '#FEC350' : '#2CDADA' : rolePercent > youPercent ? '#BC7D01' : '#18768C';

  const StyledYouStatusText = styled('span')(() => ({
    color: !isDark && rolePercent > youPercent ? '#9E5F00' : percentColor,
    fontWeight: 600,
  }));

  return (
    <StyledCardBox>
      <StyledCardContent>
        <Grid container spacing={0}>
          <Grid item sm={6} xs={12}>
            <StyledCardContentLeft>
              <StyledCardTitle>{title}</StyledCardTitle>
              <StyledDescription>{description}</StyledDescription>
            </StyledCardContentLeft>
          </Grid>
          <Grid item sm={6} xs={12}>
            <StyledScoreContentWrapper>
              <StyledDivider />
              <StyledScoreContent>
                <HDSlider
                  progress={youPercent}
                  benchMark={rolePercent}
                />
                <StyledStatusWrapper>
                  You {statusTextAbbre}<StyledYouStatusText>{statusText}</StyledYouStatusText> the {roleName} target.
                </StyledStatusWrapper>
              </StyledScoreContent>
            </StyledScoreContentWrapper>
          </Grid>
        </Grid>
      </StyledCardContent>
      {!isPDFCard && (
        <StyledBottomLineWrapper>
          <StyledBottomLine />
          {/* <HDButton
            variant={'text'}
            text={'View details'}
            onClick={forwardClick}
            endIcon={<ArrowForwardIcon />}
            sx={detailsBtnStyle}
            disableRipple={true}
          /> */}
          <Link
            onClick={() => handleForward('/leadership-skills/details', navigate, state)}
            sx={detailsBtnStyle(theme.palette.mode)}
            tabIndex={0}
            underline="none"
          >
            View details
            <ArrowForwardIcon style={{ marginLeft: '5px', fontSize: '20px' }} />
          </Link>
        </StyledBottomLineWrapper>
      )}
    </StyledCardBox>
  );
}

export default HDLeadershipSkillCard;
