import React, { useEffect, useState, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, Typography, Container, useTheme, useMediaQuery, Divider, CssBaseline, styled } from '@mui/material';
import Header from '../../commons/Header';
import BreadCrumb from '../../hd-ui-kit/HDBreadcrumb';
import Footer from '../../commons/Footer';
import Loader from '../../commons/Loader';
import HDProgressBar from '../../hd-ui-kit/HDProgressBar';
import HDLeadershipSkillCard from '../../hd-ui-kit/HDLeadershipSkillCard';
import HDButton from '../../hd-ui-kit/HDButton';
import Api from '../../Api';
import LeadershipReportDetails from './LeadershipReportDetails';
import { reactGAEvents, getCapabilityScore, LegendContent, backgroundTopLeft, backgroundTopLeftDark, CoachingBanner, getAssesmentStatus, exportPDF, exportBtnStyle } from '../../commons/commonFunctions';
import { globalContext } from '../../store';
import Link from '@mui/material/Link';
import HDNotificationBanner from '../../hd-ui-kit/HDNotificationBanner';

function ReportOverview() {
  const [showLoader, setShowLoader] = useState<boolean>(true);
  const theme = useTheme();
  const isDark = theme.palette.mode === 'dark';
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  let navigate = useNavigate();
  const { dispatch, globalState } = useContext(globalContext);
  const { pathname } = useLocation();
  const [apiError, setApiError] = useState<string>('');
  const gaTags = { category: 'my-reports-experience-overview', tagName: 'export-pdf-impact' };

  const breadCrumbRoutes = [
    { text: 'Home', href: '/home' },
    { text: 'Heidrick Capabilities Report', href: '#' },
  ];

  const BodyWrapper = styled(Box)(() => ({
    marginBottom: '60px',
  }));

  const DescriptionBox = styled(Box)(() => ({
    textAlign: 'left',
  }));

  const BodyDetails = styled(Box)(() => ({
    borderRadius: '10px',
    border: '1px solid #CAD4D8',
    padding: '30px',
  }));

  const ProgressWrap = styled(Box)(() => ({
    textAlign: 'left',
  }));

  const DetailsWrap = styled(Box)(() => ({
    textAlign: 'left',
    marginTop: '30px',
  }));

  const descTitleStyle = {
    fontWeight: 500,
    fontSize: '41px',
    color: isDark ? '#fff' : '#002136',
    marginBottom: '10px',
  };

  const descStyle = {
    fontFamily: 'Avenir',
    fontWeight: 300,
    fontSize: '16px',
    color: isDark ? '#fff' : '#334255',
    display: 'flex',
    flexDirection: isMobile ? 'column' : 'row',
    justifyContent: 'space-between',
  };

  const progressTitleStyle = {
    fontFamily: 'Aventa',
    fontSize: '28px',
    fontWeight: 500,
    color: isDark ? '#fff' : '#002136',
  };

  const SCSubtitle = styled(Typography)(() => ({
    marginBottom: isMobile ? '8px !important' : 0,
    fontSize: '16px !important',
    lineHeight: '24px !important',
    fontFamily: 'Avenir !important',
  }));

  const sortLeadershipSkills = (skillList: any) => {
    if (!skillList) return [];
    return skillList.sort((skill1: any, skill2: any) => skill2.youPercent - skill1.youPercent);
  };

  useEffect(() => {
    if (!globalState?.profile) {
      getProfileData();
    } else {
      setShowLoader(false);
    }
  }, []);

  const getProfileData = async () => {
    const { response } = await Api.getProfile();
    if (response && response.status === 200) {
      const { data } = response;
      const dataRes = data?.data?.[0] ? data.data[0] : data.data;
      if (dataRes) {
        dispatch({ type: 'SET_PROFILE', payload: dataRes });
        const assessmentId = dataRes.assessments.find((el: any) => el.name === 'Capabilities').uuid;
        const roleId = dataRes.roleProfileId;

        const capabilityReportData = await getCapabilityScore(assessmentId, roleId, dataRes, navigate);
        dispatch({ type: 'SET_CAPABILITY_REPORT', payload: capabilityReportData });
        const assesmentStatusData = await getAssesmentStatus();
        dispatch({ type: 'SET_ASSESSMENT_STATUS', payload: assesmentStatusData });
        setShowLoader(false);
      }
    }
  };

  const exportBtnStyleMobile = {
    display: 'none',
  };

  const SCDivider = styled(Divider)(() => ({
    marginTop: '32px !important',
    marginBottom: '32px !important',
  }));

  const headWrapStyle = {
    marginTop: '48px',
    marginBottom: '48px',
  };

  return (
    <>
      <CssBaseline />
      {showLoader && <Loader />}
      <Header />
      {apiError && <HDNotificationBanner content={apiError} severity={'info'} variant={'filled'} />}

      <Box sx={isDark ? { ...backgroundTopLeftDark } : { ...backgroundTopLeft }}>
        <Container maxWidth="lg">
          <BodyWrapper>
            <Box sx={headWrapStyle}>
              <BreadCrumb routes={breadCrumbRoutes} handleClick={(el: any) => reactGAEvents('my-report-leadership-skills-overview', 'my-reports')} />
              {!isMobile && <SCDivider />}
              <DescriptionBox id="pageTitle">
                <Box sx={{ justifyContent: 'space-between', display: 'flex' }}>
                  <Typography variant="h4" sx={descTitleStyle}>
                    Heidrick Capabilities Report
                  </Typography>
                  {!isMobile && (
                    <Link
                      onClick={() => exportPDF(setShowLoader, 'hfl_capabilities', 'heidrick-capabilities-report.pdf', gaTags, setApiError)}
                      sx={exportBtnStyle(isMobile, theme.palette.mode)}
                      tabIndex={0}
                      underline="none"
                    >
                      Export PDF
                      <img style={{ height: '24px', width: '24px', marginLeft: '10px' }} alt="Export pdf logo" src={isDark ? '/res/ExportPDFIconDark.svg' : '/res/ExportPDFIcon.svg'} />
                    </Link>
                  )}
                </Box>
                <Typography sx={descStyle}>
                  <SCSubtitle>How do your Heidrick capabilities compare to targets for {globalState?.profile?.businessRole?.name}?</SCSubtitle>
                  {globalState?.capabilityReport?.LastAssessmentDate ? <SCSubtitle>Last assessment: {globalState?.capabilityReport?.LastAssessmentDate}</SCSubtitle> : ''}
                  {isMobile && <HDButton variant={'text'} text={'Export PDF'} endIcon={<img alt="Export pdf logo" src="/res/ExportPDFIcon.svg" />} sx={exportBtnStyleMobile} disableRipple={true} />}
                </Typography>
              </DescriptionBox>
            </Box>

            <Box sx={headWrapStyle}>{<CoachingBanner />}</Box>
            <BodyDetails>
              <ProgressWrap id="pageTopSection">
                <HDProgressBar
                  benchMark={50}
                  progressDescription={`You align with ~${globalState?.capabilityReport?.percentage}%~ of ${globalState?.profile?.businessRole?.name} targets for ${globalState?.capabilityReport ? Object.keys(globalState?.capabilityReport?.results)?.length : ''} core Heidrick capabilities`}
                  progress={globalState?.capabilityReport?.percentage}
                  progressNote={"These skills are based on Heidrick's proprietary leadership data."}
                  height={15}
                  titleStyle={progressTitleStyle}
                  progressLegend={<LegendContent />}
                />
              </ProgressWrap>

              <DetailsWrap>
                {sortLeadershipSkills(globalState?.capabilityReport?.skills?.[0])?.map((ind: any, key: number) => {
                  return (
                    <Box key={key} id={`compactCard${key}`}>
                      <HDLeadershipSkillCard {...ind} pathname={pathname} />
                      <br />
                      <Box id={`details${key}`} className={'cardDescription'} style={{ display: 'none' }}>
                        <LeadershipReportDetails skillDetails={ind} cardDetails={ind.cardData} />
                        <br />
                      </Box>
                    </Box>
                  );
                })}
              </DetailsWrap>
            </BodyDetails>
          </BodyWrapper>
        </Container>
      </Box>
      <Footer />
    </>
  );
}

export default ReportOverview;
