import * as React from 'react';
import { styled } from '@mui/material/styles';
import { FormControlLabel, useTheme, Box } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';

export interface HDCheckBoxTypes {
  sx?: object;
  error?: boolean;
  disabled?: boolean;
  // eslint-disable-next-line
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value: any;
  labelTxt: string;
  defaultChecked?: boolean;
  showBackground?: boolean;
  wrapperSX?: object;
  checked?: any;
  id?: any;
}

const HDCheckBox = (props: HDCheckBoxTypes) => {
  const theme = useTheme()?.palette.mode;
  const HDChkBoxIcon = styled('span')(({ theme }) => ({
    borderRadius: 4,
    width: 24,
    height: 24,
    border:
      '1px solid ' +
      (props.error
        ? theme.palette.mode === 'dark'
          ? '#E449BD'
          : '#C02195'
        : theme.palette.mode === 'dark'
          ? '#A9A9A9'
          : '#646A7C'),
    backgroundColor: theme.palette.mode === 'dark' ? '#0B0C10' : '#fff',
    '.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      border:
        '2px solid ' +
        (props.error
          ? theme.palette.mode === 'dark'
            ? '#E449BD'
            : '#C02195'
          : theme.palette.mode === 'dark'
            ? '#197E95'
            : '#18768C'),
    },
    'input:focus ~ &': {
      border:
        '2px double ' +
        (props.error
          ? theme.palette.mode === 'dark'
            ? '#E449BD'
            : '#C02195'
          : theme.palette.mode === 'dark'
            ? '#197E95'
            : '#18768C'),
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      backgroundColor: theme.palette.mode === 'dark' ? '#334255' : '#CCCCCC',
      border: 'none',
      backgroundImage:
        theme.palette.mode === 'dark'
          ? 'url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iMTEiIHZpZXdCb3g9IjAgMCAxNSAxMSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEzLjU5IDBMNiA3LjU4TDEuNDEgM0wwIDQuNDFMNiAxMC40MUwxNSAxLjQxTDEzLjU5IDBaIiBmaWxsPSIjMEIwQzEwIi8+Cjwvc3ZnPgo=)'
          : 'url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iMTEiIHZpZXdCb3g9IjAgMCAxNSAxMSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEzLjU5IDBMNiA3LjU4TDEuNDEgM0wwIDQuNDFMNiAxMC40MUwxNSAxLjQxTDEzLjU5IDBaIiBmaWxsPSIjNjQ2QTdDIi8+Cjwvc3ZnPgo=)',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
    },
  }));

  const HDCheckedIcon = styled(HDChkBoxIcon)({
    display: 'block',
    width: 24,
    height: 24,
    backgroundImage:
      theme === 'light'
        ? 'url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iMTEiIHZpZXdCb3g9IjAgMCAxNSAxMSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEzLjU5IDBMNiA3LjU4TDEuNDEgM0wwIDQuNDFMNiAxMC40MUwxNSAxLjQxTDEzLjU5IDBaIiBmaWxsPSIjMDAyMTM2Ii8+Cjwvc3ZnPgo=)'
        : 'url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iMTEiIHZpZXdCb3g9IjAgMCAxNSAxMSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEzLjU5IDBMNiA3LjU4TDEuNDEgM0wwIDQuNDFMNiAxMC40MUwxNSAxLjQxTDEzLjU5IDBaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K)',
    content: '""',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
  });

  const StyledBoxWrapper = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    padding: '10px 0px 10px 20px',
    background: theme === 'light' ? '#F8F8F8' : '#002136',

    border:
      theme === 'light' && props.error
        ? '2px solid #C02195'
        : props.error
          ? '2px solid #E449BD'
          : '',
    borderRadius: '8px',
    ':hover': {
      border: !props.error && !props.disabled && '2px solid #18768C',
    },
  }));

  const StyledFormControlLabel = {
    color: theme === 'light' ? '#002136' : '#CCC',
    '.MuiFormControlLabel-label': {
      fontWeight: '500',
      color:
        theme === 'light'
          ? props.disabled
            ? '#646A7C  !important'
            : '#002136'
          : props.disabled
            ? '#A9A9A9  !important'
            : '#CCC',
      fontFamily: 'Aventa',
      fontStyle: 'normal',
      fontSize: '16px',
    },
    marginRight: '5px'
  };

  return props.showBackground ? (
    <StyledBoxWrapper sx={props.wrapperSX}>
      <FormControlLabel
        id={props.id}
        sx={StyledFormControlLabel}
        value={props.value}
        label={props.labelTxt}
        disabled={props.disabled}
        control={
          <Checkbox
            sx={{
              '&:hover': { bgcolor: 'transparent' },
            }}
            disableRipple
            color="default"
            checkedIcon={<HDCheckedIcon />}
            icon={<HDChkBoxIcon />}
            inputProps={{ 'aria-label': 'Checkbox demo' }}
            disabled={props.disabled}
            defaultChecked={props.defaultChecked}
            onChange={props.onChange}
            checked={props.checked}
            value={props.value}
          />
        }
      />
    </StyledBoxWrapper>
  ) : (
    <FormControlLabel
      id={props.id}
      sx={StyledFormControlLabel}
      value={props.value}
      label={props.labelTxt}
      disabled={props.disabled}
      control={
        <Checkbox
          sx={{
            '&:hover': { bgcolor: 'transparent' },
          }}
          disableRipple
          color="default"
          checkedIcon={<HDCheckedIcon />}
          icon={<HDChkBoxIcon />}
          inputProps={{ 'aria-label': 'Checkbox demo' }}
          disabled={props.disabled}
          defaultChecked={props.defaultChecked}
          onChange={props.onChange}
          checked={props.checked}
          value={props.value}
        />
      }
    />
  );
};

export default HDCheckBox;