import HDStepper from '../../hd-ui-kit/HDStepper';
import React from 'react';

export const Stepper = (activeStep: number) => {
  return (
    <HDStepper
      size={1000}
      steps={[
        {
          label: 'Create an Account',
        },
        {
          label: 'Setup Account Detail',
        },
        {
          label: 'Compensation Details',
        }, {
          label: 'Knowlege',
        },
      ]}
      activeStep={activeStep}
    />
  );
};

export const ContainerStyle = (isMobile: boolean, isTablet: boolean) => {
  return {
    position: 'relative',
    paddingBottom: (isMobile || isTablet) ? '172px' : 0,
  };
};

export const contentWrapStyle = (isMobile: boolean, isTablet: boolean) => {
  return {
    display: 'flex',
    flexWrap: 'wrap',
    position: 'relative',
    marginTop: isMobile || isTablet ? '32px' : '64px',
    paddingBottom: isMobile || isTablet ? 0 : '64px',
  };
};

export const backImage = (isMobile: boolean, isTablet: boolean) => {
  return {
    display: 'block',
    position: 'absolute',
    width: '100%',
    height: '100%',
    content: '""',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: (isMobile || isTablet) ? 'right bottom' : 'right top',
    right: (isMobile || isTablet) ? 0 : '-30px',
    top: (isMobile || isTablet) ? 'auto' : 0,
    bottom: 0,
    backgroundSize: (isMobile || isTablet) ? '90%' : '52%',
  };
};

export const styledCardWrappers = (isMobile: boolean, isTablet: boolean) => {
  return {
    width: (!isMobile && !isTablet) ? '52%' : 'auto',
    textAlign: 'left'
  };
};

export const formWrapper = () => {
  return {
    marginTop: '24px'
  };
};

export const titleStyle = (themeMode: string) => {
  return {
    fontFamily: 'Aventa',
    fontWeight: 500,
    fontSize: '41px',
    lineHeight: '54px',
    color: (themeMode === 'light') ? '#002136' : '#fff'
  };
};

export const subTitleStyle = (themeMode: string) => {
  return {
    fontFamily: 'Aventa',
    fontWeight: 500,
    fontSize: '22px',
    lineHeight: '32px',
    color: (themeMode === 'light') ? '#002136' : '#fff',
  };
};

export const fieldStyle = () => {
  return {
    marginBottom: '36px'
  };
};

export const chkBoxStyle = () => {
  return {
    marginBottom: '24px'
  };
};

export const btnLinkStyle = () => {
  return {
    fontSize: '14px',
    padding: 0,
    margin: 0,
    fontWeight: 400,
    textDecoration: 'underline',
  };
};

export const btnStyle = (themeMode: string) => {
  return {
    backgroundColor: (themeMode == 'light') ? '#334255' : '#F1F1F1',
    color: (themeMode == 'light') ? '#FFFFFF' : '#002136',
    marginTop: '32px',
  };
};

export const dividerStyle = (themeMode: string) => {
  return {
    borderColor: (themeMode === 'light') ? 'rgba(100, 106, 124, 0.2)' : 'rgba(255, 255, 255, 0.2)',
    marginTop: '20px',
    marginBottom: '20px',
  };
};

export const borderFormWrap = (themeMode: string) => {
  return {
    padding: '24px',
    border: '1px solid' + ((themeMode === 'light') ? '#E0E1E5' : '#3C3D40'),
    borderRadius: '6px',
    marginTop: '32px',
  };
};

export const buttonStylesLink = (themeMode: string) => {
  return {
    fontSize: '14px',
    padding: 0,
    margin: 0,
    fontWeight: 400,
    textDecoration: 'underline',
    letterSpacing: '0px',
    color: themeMode == 'light' ? '#18768C' : '#66FCFC',
    cursor: 'pointer'
  };
};

export const noteStyle = (themeMode: string) => {
  return {
    fontFamily: 'Aventa',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    color: themeMode == 'light' ? '#002136' : '#CCC',
    marginBottom: '32px',
    marginTop: '32px',
  };
};
