import { FormControl, FormHelperText, styled, FormLabel, TextField, useTheme, TextFieldProps, InputAdornment, IconButton } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';

import React from 'react';

export interface HDDatePickerTypes {
  disabled?: boolean,
  error?: boolean,
  disableRipple?: boolean,
  placeholder?: string,
  id?: string,
  lblText?: string,
  errorMsg?: string,
  belowHelperTxt?: string,
  aboveHelperTxt?: string,
  views: Array<'day' | 'month' | 'year'>,
  inputFormat?: string,
  sx?: Object
  value?: any;
  onChange?: any;
  minDate?: any;
  onClick?: any;
}

const HDDatePicker = (props: HDDatePickerTypes) => {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme()?.palette.mode;
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const FormHelperTextStyled = styled(FormHelperText)(() => ({
    fontFamily: 'Avenir',
    fontWeight: 300,
    fontSize: '16px',
    lineHeight: '24px',
    color: (theme === 'light') ? '#646A7C' : '#A9A9A9',
    marginLeft: 0,
    marginTop: '8px',
  }));

  const FormHelperTextAboveStyled = styled(FormHelperText)(() => ({
    fontFamily: 'Avenir',
    fontWeight: 300,
    fontSize: '16px',
    lineHeight: '24px',
    color: (theme === 'light') ? '#646A7C' : '#A9A9A9',
    marginLeft: 0,
    marginTop: 0,
    marginBottom: '10px',
  }));

  const ErrorIcon = styled('img')(() => ({
    marginRight: '8px'
  }));

  return (
    <FormControl fullWidth={true} >
      {props.lblText &&
        <FormLabel htmlFor={props?.id} error={props.error}>
          {props.lblText}
        </FormLabel>
      }
      {props.aboveHelperTxt && <FormHelperTextAboveStyled>{props.aboveHelperTxt}</FormHelperTextAboveStyled>}
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <DatePicker
          open={open}
          onOpen={handleOpen}
          onClose={handleClose}
          views={props.views}
          value={props.value}
          minDate={props.minDate}
          onChange={(newValue: any) => {
            props.onChange(newValue);
          }}
          inputFormat={(props.inputFormat) ? props.inputFormat : 'MM/yyyy'}
          renderInput={(params: any) => (
            <TextField
              {...params}
              error={props.error}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end" onClick={handleOpen}>
                      <CalendarTodayOutlinedIcon sx={{ color: ((theme === 'light') ? '#002136' : '#FFF') }} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onClick={props?.onClick}
            />
          )}
        />
      </LocalizationProvider>

      {(props.error) && <FormHelperTextStyled error={true}>
        <ErrorIcon src={(window.location.origin) + '/res/hd-ui-kit-images/' + ((theme === 'light') ? 'warning-icon.svg' : 'warning-icon-dark.svg')} />
        {props.errorMsg}
      </FormHelperTextStyled>}
      {props.belowHelperTxt && <FormHelperTextStyled>{props.belowHelperTxt}</FormHelperTextStyled>}
    </FormControl>
  );
};

export default HDDatePicker;
