import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Container,
  useMediaQuery,
  useTheme,
  CssBaseline, styled
} from '@mui/material';
import Header from '../../commons/Header';
import Footer from '../../commons/Footer';
import HDButton from '../../hd-ui-kit/HDButton';
import ArrowForwardSharpIcon from '@mui/icons-material/ArrowForwardSharp';
import HDTextField from '../../hd-ui-kit/HDTextField';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Api from '../../Api';
import Loader from '../../commons/Loader';
import { reactGAEvents, titleStyle, styledText, styledDividerLeft } from '../../commons/commonFunctions';

const NewPassword = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [resetPassword, setResetPassword] = useState('');
  const [resetPasswordConfrim, setResetPasswordConfirm] = useState('');
  let [params, SetParams] = useSearchParams();
  const [errors, setErrors] = useState<any>({});
  let navigate = useNavigate();
  let code = params.get('code'); // code from email for email activation
  const [loading, setLoading] = useState<boolean>(false);
  const themeMode = theme.palette.mode;
  const [bodyContent, setBodyContent] = useState<any>('');

  const getColorByTheme = (theme:any, lightColor:any, darkColor:any) => {
    return theme.palette.mode === 'light' ? lightColor : darkColor;
  };
  
  const StyledText = {
    fontFamily: 'Avenir',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#E449BD',
    marginTop: '15px',
  };

  const StyledSignInBtn = {
    fontFamily: 'Aventa',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    color: getColorByTheme(theme, '#18768C', '#2CDADA'),
    padding: '0px',
    ':hover': {
      color: getColorByTheme(theme, '#18768C', '#2CDADA'),
    },
  };

 

  useEffect(() => {
    if (code) {
      Api.verifyPasswordReset(code)
        .then((res) => {
          if (!res.success) {
            const link = <a href={window.location.origin + '/login'} style={StyledSignInBtn}>Return to sign-in screen</a>;
            const msg = <><p style={StyledText}>Your reset password link has expired.<br /></p>{link}</>;
            setBodyContent(msg);
          }
        })
        .catch(() => {
          navigate('/login');
        });
    }
  }, []); // <-- empty array means 'run once'

  const MainStyledBox = {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'start',
    marginTop: '20px'
  };

  const ArrowForwardSharpIconStyled = styled(ArrowForwardSharpIcon)(() => ({
    color: getColorByTheme(theme, '#66FCFC', '#18768C'),
  }));

  const StyledSignIn = {
    backgroundColor: getColorByTheme(theme, '#334255' , '#F1F1F1'),
    color: getColorByTheme(theme, '#FFFFFF' , '#002136'),
    marginTop: '20px',
    padding: '6px 16px',
    marginBottom: '20px',
  };

  const StyledForgetWrapper = {
    textAlign: 'left',
    marginTop: '20px',
  };

  const ErrorStyle = {
    color: '#cc33a1 !important',
    fontFamily: 'Avenir',
    textAlign: 'start',
    fontSize: '16px',
  };

  let backgroundUrl =
  `url(res/NewPasswordBG${
    theme.palette.mode === 'light' ? 'Light' : 'Dark'
  }${
    isMobile ? 'Mobile' : ''
  }.svg) no-repeat top`;

  const StyledBoxMainWrapper = {
    background: backgroundUrl,
    backgroundPosition: isMobile ? 'right bottom' : 'right',
    height: isMobile ? '640px' : '600px',
  };

  function updatePassword(event: { preventDefault: () => void }) {
    setLoading(true);
    reactGAEvents('reset-new-password', 'update');
    //Reg Ex for finding script tags
    const chkForTagsRegEx = new RegExp('.*\\<[^>]+>.*');//NOSONAR
    event.preventDefault();

    const isValid = validatePasswords();
    if (!isValid) {
      setLoading(false);
      return;
    }

    if (resetPassword.length > 128) {
      setErrors({ password: 'Password should not be more than 128 characters' });//NOSONAR
      setLoading(false);
      return;
    }

    if (chkForTagsRegEx.test(resetPassword)) {
      setErrors({ password: 'Password should not contain HTML Tags' });//NOSONAR
      setLoading(false);
      return;
    }

    if (resetPassword == resetPasswordConfrim) {
      let payload = { code: code, password: resetPassword };
      Api.resetPassword(payload)
        .then((res) => {
          if (res.success) {
            setLoading(false);
            navigate('/login');
          } else {
            const { data } = res;
            if (data?.detail) {
              let non_field_errors = [];
              non_field_errors[0] = data?.detail;
              setErrors({ non_field_errors: non_field_errors });
              setLoading(false);
            } else {
              setErrors(data);
              setLoading(false);
              navigate('/register');
            }
          }
        })
        .catch((err) => {
          setErrors(err);
          setLoading(false);
        });
    } else {
      // error message to user
      setLoading(false);
      setErrors({ password: 'Passwords must match' }); // NOSONAR
    }
  }

  function validatePasswords() {
    const passwordMatch = resetPassword == resetPasswordConfrim;
    let strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{14,})');
    const passwordValid = strongRegex.test(resetPassword);

    if (!passwordMatch) {
      setErrors({ password: 'Passwords must match' }); // NOSONAR
    }

    if (!passwordValid) {
      setErrors({
        password: `Must be 14 characters in length, at least 1 upper and 1 lower case letter,
          at least one number, at least one special character`,
      });
    }

    return passwordValid && passwordMatch;
  }

  useEffect(() => {
    let isAuth = Api.isAuthorized();
    if (isAuth) navigate('/home');
  }, []);


  return (
    <>
      <CssBaseline />
      <Header />
      {loading && <Loader />}
      <Box sx={StyledBoxMainWrapper}>
        <Container maxWidth="lg">
          <Box sx={styledDividerLeft(isMobile, themeMode)}>
            {(bodyContent) ?
              <Box sx={MainStyledBox}>
                <Typography variant="h2" sx={titleStyle(isMobile, themeMode)}>
                  New password
                </Typography>
                {bodyContent}
              </Box>
              :
              <Box>
                <Box sx={MainStyledBox}>
                  <Typography variant="h2" sx={titleStyle(isMobile, themeMode)}>
                    New password
                  </Typography>
                  <Typography variant="h2" sx={styledText(themeMode)}>
                    Please enter your new password to reset it.
                  </Typography>
                </Box>
                <Box sx={MainStyledBox}>
                  <HDTextField
                    lblText="Password"
                    type="password"
                    value={resetPassword}
                    error={errors?.password}
                    onClick={() => reactGAEvents('reset-new-password', 'new-password')}
                    errorMsg={errors?.password ? errors.password : null}
                    onChange={({ target }: any) => setResetPassword(target.value)}
                    id="reset-new-password"
                  />
                </Box>
                <Box sx={MainStyledBox}>
                  <HDTextField
                    lblText="Confirm Password"
                    type="password"
                    value={resetPasswordConfrim}
                    error={errors?.password}
                    errorMsg={errors?.password ? errors.password : null}
                    onClick={() => reactGAEvents('reset-new-password', 'confirm-password')}
                    onChange={({ target }: any) => setResetPasswordConfirm(target.value)}
                    id="reset-confirm-password"
                  />
                </Box>
                <Box sx={StyledForgetWrapper}>

                  {errors?.non_field_errors &&
                    errors.non_field_errors.map(
                      (
                        item:
                          | string
                          | number
                          | boolean
                          | React.ReactFragment
                          | React.ReactPortal
                          | React.ReactElement<any, string | React.JSXElementConstructor<any>>
                        , indx: number) => {
                        return (
                          <Typography variant="h2" key={indx} sx={ErrorStyle}>
                            {item}
                          </Typography>
                        );
                      }
                    )}
                  <Box>
                    <HDButton
                      variant={'contained'}
                      sx={StyledSignIn}
                      endIcon={<ArrowForwardSharpIconStyled />}
                      text={'Update'}
                      onClick={(e) => updatePassword(e)}
                      showAnimation={true}
                    />
                  </Box>
                </Box>
              </Box>
            }
          </Box>
        </Container>
      </Box>
      <Footer />
    </>
  );
};

export default NewPassword;
