import * as React from 'react';
import { Alert, AlertColor, Container, styled, useMediaQuery, useTheme } from '@mui/material';
import Box from '@mui/material/Box';

export interface HDNotificationBannerProps {
  isFullWidth?: boolean;
  content: any;
  severity: AlertColor;
  variant: 'standard' | 'filled' | 'outlined' | undefined;
  borderRadius?: string;
  icon?: true | boolean;
  action?: any,
  style?: Object,
  StyledParentBox?: Object,
}

const HDNotificationBanner = ({ severity, variant, content, icon, action, style, StyledParentBox }: HDNotificationBannerProps): any => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const StyledBox = {
    display: 'block',
    width: '100%',
    background: '#18768C',
    borderRadius: '6px',
    color: '#fff',
  };

  const StyledAlert = styled(Alert)(() => ({
    fontSize: '16px',
    fontWeight: 300,
    fontFamily: 'Avenir',
    padding: '12px 0',
    background: 'none',
    color: '#fff',
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: (isMobile) ? 'column' : 'row',
    '& .MuiAlert-action': {
      marginRight: '0px',
      marginLeft: (isMobile) ? '0px' : 'auto',
      padding: 0,
    }
  }));

  return (
    <Box sx={StyledParentBox ? StyledParentBox : StyledBox}>
      <Container>
        <StyledAlert
          severity={severity}
          variant={variant}
          icon={icon}
          action={action}
          sx={style}
        >
          {content}
        </StyledAlert>
      </Container>
    </Box>
  );
};

export default HDNotificationBanner;
