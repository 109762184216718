import React, { useEffect, useState } from 'react';
import { Box, Divider, Typography, useMediaQuery, useTheme } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { headerStyle, subHeaderStyle, pageWrapper, titleUnderline, btnStyle, btnIconStyle, btnWrapper, infoBoxStyle, titleDivider } from './styles';
import HDDropDown from '../../../hd-ui-kit/HDDropDown';
import HDMultiSelectDropdown from '../../../hd-ui-kit/HDMultiSelect/HDMultiSelectDropdown';
import HDButton from '../../../hd-ui-kit/HDButton';
import formData from '../../../utils/form-data';
import Api from '../../../Api';
import HDSpinner from '../../../hd-ui-kit/HDSpinner/HDSpinner';
import { validateFields, validationOptions } from '../../../ValidationSchema';

interface payloadObjectTypes {
  employer_type: string;
  salary_range: string;
  annual_base_salary_percent: string;
  compensation_package_attributes: any[];
  granted_frequency_of_compensation: string;
  incentives_granted_last_year: string;
}

function Compensation({ data, onSuccess }: any) {
  const theme = useTheme();
  const themeMode = theme.palette.mode;
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const sxStyleMarginBottom = { marginBottom: '20px' };

  const [employerType, setEmployerType] = useState<string>('');
  const [salaryRange, setSalaryRange] = useState<string>('');
  const [annualBaseSalaryPercent, setAnnualBaseSalaryPercent] = useState<string>('');
  const [compensationPackageAttributes, setCompensationPackageAttributes] = useState<any>([]);
  const [grantedFrequencyOfCompensation, setGrantedFrequencyOfCompensation] = useState<string>('');
  const [incentivesGrantedLastYear, setIncentivesGrantedLastYear] = useState<string>('');
  const [btnDisable, setBtnDisable] = useState(false);
  const [errors, setErrors] = useState<any>({});

  useEffect(() => {
    if (data) {
      setFormDataFromContext(data);
    }
  }, [data]);

  const setFormDataFromContext = (data: { [key: string]: React.SetStateAction<string> }) => {
    if (!data) {
      return;
    }

    setEmployerType(data['employer_type']);
    setSalaryRange(data['salary_range']);
    setAnnualBaseSalaryPercent(data['annual_base_salary_percent']);
    setCompensationPackageAttributes(data['compensation_package_attributes']);
    setGrantedFrequencyOfCompensation(data['granted_frequency_of_compensation']);
    setIncentivesGrantedLastYear(data['incentives_granted_last_year']);
  };

  const onSave = () => {
    onSuccess(false);
    setBtnDisable(true);
    setErrors({});
    const employerTypeMsg = validateFields.validate({ dropDown: employerType }, validationOptions)?.error?.details[0]?.message;
    const salaryRangeMsg = validateFields.validate({ dropDown: salaryRange }, validationOptions)?.error?.details[0]?.message;
    const annualBaseSalaryPercentMsg = validateFields.validate({ dropDown: annualBaseSalaryPercent }, validationOptions)?.error?.details[0]?.message;
    const grantedFrequencyOfCompensationMsg = validateFields.validate({ dropDown: grantedFrequencyOfCompensation }, validationOptions)?.error?.details[0]?.message;
    const incentivesGrantedLastYearMsg = validateFields.validate({ dropDown: incentivesGrantedLastYear }, validationOptions)?.error?.details[0]?.message;

    let compensationPackageAttributesMsg;
    if (compensationPackageAttributes.length === 0) {
      compensationPackageAttributesMsg = 'This field may not be blank.';
    }
    if (employerTypeMsg || salaryRangeMsg || annualBaseSalaryPercentMsg || compensationPackageAttributesMsg || grantedFrequencyOfCompensationMsg || incentivesGrantedLastYearMsg) {
      setErrors({
        employer_type: employerTypeMsg,
        salary_range: salaryRangeMsg,
        annual_base_salary_percent: annualBaseSalaryPercentMsg,
        compensation_package_attributes: compensationPackageAttributesMsg,
        granted_frequency_of_compensation: grantedFrequencyOfCompensationMsg,
        incentives_granted_last_year: incentivesGrantedLastYearMsg,
      });
      setBtnDisable(false);
      return;
    }
    const payload: payloadObjectTypes = {
      employer_type: employerType,
      salary_range: salaryRange,
      annual_base_salary_percent: annualBaseSalaryPercent,
      compensation_package_attributes: compensationPackageAttributes,
      granted_frequency_of_compensation: grantedFrequencyOfCompensation,
      incentives_granted_last_year: incentivesGrantedLastYear,
    };

    if (data) {
      Api.updateCompensation(payload, data['id'] as never)
        .then((res) => {
          onApiSuccess(res);
        })
        .catch((err) => {
          setBtnDisable(false);
        });
    } else {
      Api.saveCompensation(payload)
        .then((res) => {
          onApiSuccess(res);
        })
        .catch((err) => {
          console.log(err);
          setBtnDisable(false);
        });
    }
  };

  const onApiSuccess = (res: any) => {
    if (res?.success) {
      setBtnDisable(false);
      onSuccessSave();
    } else {
      setBtnDisable(false);
    }
  };

  const onSuccessSave = () => {
    onSuccess(true);
    window.scrollTo(0, 0);
  };

  return (
    <Box sx={pageWrapper(isMobile, themeMode)}>
      <Typography sx={headerStyle(themeMode)}>Compensation</Typography>
      <Divider sx={titleUnderline(themeMode)} />

      <Box sx={infoBoxStyle(themeMode, 'top')}>
        <Typography sx={subHeaderStyle(themeMode)}>Annual Salary & Incentive</Typography>
        <Divider sx={titleDivider(themeMode)} />

        <Box sx={sxStyleMarginBottom}>
          <HDDropDown
            fieldLabel={'Is your employer a privately owned or public company?'}
            showToolTip={false}
            value={employerType === null ? '' : employerType}
            dropDownItem={formData.EmployerTypeList}
            onChange={({ target }: any) => {
              setEmployerType(target.value);
            }}
            error={errors?.employer_type}
            errorMsg={errors?.employer_type ? errors.employer_type : null}
            id="employerType"
          />
        </Box>
        <Box sx={sxStyleMarginBottom}>
          <HDDropDown
            fieldLabel={'What salary range best describes your base salary level?'}
            value={salaryRange === null ? '' : salaryRange}
            dropDownItem={formData.SalaryRangeList}
            onChange={({ target }: any) => {
              setSalaryRange(target.value);
            }}
            error={errors?.salary_range}
            errorMsg={errors?.salary_range ? errors.salary_range : null}
            id="salaryRange"
          />
        </Box>
        <Box sx={sxStyleMarginBottom}>
          <HDDropDown
            fieldLabel={'What is your annual target incentive as a percentage of annual base salary?'}
            value={annualBaseSalaryPercent === null ? '' : annualBaseSalaryPercent}
            dropDownItem={formData.AnnualBaseSalaryPercentList}
            onChange={({ target }: any) => {
              setAnnualBaseSalaryPercent(target.value);
            }}
            error={errors?.annual_base_salary_percent}
            errorMsg={errors?.annual_base_salary_percent ? errors.annual_base_salary_percent : null}
            id="annualBaseSalaryPercent"
          />
        </Box>
      </Box>

      <Box sx={infoBoxStyle(themeMode)}>
        <Typography sx={subHeaderStyle(themeMode)}>Long-Term Incentives</Typography>
        <Divider sx={titleDivider(themeMode)} />

        <Box sx={sxStyleMarginBottom}>
          <HDMultiSelectDropdown
            label={'Do you receive any of the following as part of your compensation package?'}
            selectValue={compensationPackageAttributes}
            dropDownItem={formData.CompensationPackageAttributesList}
            onChange={(event: any, newValue: any) => {
              const selectedValues = newValue.map((option: any) => option.value);
              setCompensationPackageAttributes(selectedValues);
            }}
            isError={errors?.compensation_package_attributes ? true : false}
            errorMsg={errors?.compensation_package_attributes ? errors.compensation_package_attributes : null}
          />
        </Box>
        <Box sx={sxStyleMarginBottom}>
          <HDDropDown
            fieldLabel={'How frequently are they granted?'}
            value={grantedFrequencyOfCompensation === null ? '' : grantedFrequencyOfCompensation}
            dropDownItem={formData.GrantedFrequencyOfCompensationList}
            onChange={({ target }: any) => {
              setGrantedFrequencyOfCompensation(target.value);
            }}
            error={errors?.granted_frequency_of_compensation}
            errorMsg={errors?.granted_frequency_of_compensation ? errors.granted_frequency_of_compensation : null}
            id="grantedFrequencyOfCompensation"
          />
        </Box>
        <Box sx={sxStyleMarginBottom}>
          <HDDropDown
            fieldLabel={'What is the annual face value (number of shares X stock price on grant date) of all long-term incentive granted to you in the last year?'}
            value={incentivesGrantedLastYear === null ? '' : incentivesGrantedLastYear}
            dropDownItem={formData.IncentivesGrantedLastYearList}
            onChange={({ target }: any) => {
              setIncentivesGrantedLastYear(target.value);
            }}
            error={errors?.incentives_granted_last_year}
            errorMsg={errors?.incentives_granted_last_year ? errors.incentives_granted_last_year : null}
            id="incentivesGrantedLastYear"
          />
        </Box>
      </Box>
      <Box sx={btnWrapper}>
        <HDButton text={'Save'} disabled={btnDisable} endIcon={btnDisable ? <HDSpinner size={'la-sm'} /> : <ArrowForwardIcon sx={btnIconStyle(themeMode)} />} variant={'contained'} sx={btnStyle(themeMode)} onClick={onSave} showAnimation={true} />
      </Box>
    </Box>
  );
}

export default Compensation;
