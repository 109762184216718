import React from 'react';
import { Box, Typography, Divider, styled, useTheme } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { styledTitle, styledDescrition, styledReadMoreText, styledDot, styledDivider } from './CoachStyles';
import HDButton from '../hd-ui-kit/HDButton';
import ArrowForwardSharpIcon from '@mui/icons-material/ArrowForwardSharp';
import {ResponsiveMobileValue, ResponsiveThemeValue} from '../commons/commonFunctions'

export const CoachBioDescrition = (data: any, isMobile:any, isDarkTheme: boolean, isReadMore: any, toggleReadMore: any, pageTpe: string, firstName?: string, props?: any) => {
  const theme = useTheme();
  const StyledDividerSmall = styled(Divider)(() => ({
    color: ResponsiveThemeValue(theme , 'rgba(100, 106, 124, 0.2)',  'rgba(255, 255, 255, 0.2)'),
    marginTop: '7px',
  }));

  const StyledBoxMain = { display: 'flex', flexDirection: ResponsiveMobileValue(isMobile,'column' , 'none'), marginTop: '30px' };
  const StyledBoxForTitle = { marginLeft: '30px', width: '100%' };
  const StyledDescritionBox = { display: 'flex', marginTop: '10px' };
  const styledMarginBox = { marginTop: '10px' };
  const StyledMobileMain = { display: 'flex', flexDirection: 'row' };
  const StyleMobileTitle = { marginLeft: '5px' };
  const StyledBtnBox = { marginTop: '30px' };
  const StyledBtn = {
    backgroundColor: ResponsiveThemeValue(theme, '#334255', '#F1F1F1'),
    color: ResponsiveThemeValue(theme, '#FFFFFF', '#002136' ),
  };

  const ArrowForwardSharpIconStyled = styled(ArrowForwardSharpIcon)(() => ({
    color: ResponsiveThemeValue(theme , '#66FCFC', '#18768C'),
  }));


  return (data?.map((item: any, index: any) => {
    if (index === 0) {
      return (
        <Box sx={StyledBoxMain} key={`coach_${item.title}`}>
          <Box sx={ResponsiveMobileValue(isMobile,StyledMobileMain , {})}>
            <Box>
              <img alt={item.alt} src={item.imgUrl} />
            </Box>
            {isMobile && (
              <Box sx={StyleMobileTitle}>
                <Typography sx={styledTitle(isDarkTheme)}>{item.title}</Typography>
              </Box>
            )}
          </Box>

          <Box sx={ResponsiveMobileValue(isMobile, {} , StyledBoxForTitle)}>
            {!isMobile && (
              <Box>
                <Typography sx={styledTitle(isDarkTheme)}>{item.title}</Typography>
              </Box>
            )}
            <Box sx={styledMarginBox}>
              <Typography sx={{ ...styledDescrition(isDarkTheme), whiteSpace: 'pre-line' }}> {isReadMore && item?.des?.length > 150 ? `${item?.des?.slice(0, 150)}...` : item?.des}</Typography>
            </Box>
            {item?.des?.length > 150 && (
              <Box>
                <Typography sx={styledReadMoreText(isDarkTheme)} onClick={() => toggleReadMore()}>{isReadMore ? 'Read more' : 'Show less'}</Typography>
              </Box>
            )}
            <Divider sx={styledDivider(isDarkTheme)} />
          </Box>
        </Box>
      );
    } else {
      return (
        <>
          {' '}
          <Box sx={StyledBoxMain} key={`coach_${item.title}`}>
            <Box sx={ResponsiveMobileValue(isMobile,StyledMobileMain , {})}>
              <Box>
                <img alt={item.alt} src={item.imgUrl} />
              </Box>
              {isMobile && (
                <Box sx={StyleMobileTitle}>
                  <Typography sx={styledTitle(isDarkTheme)}>{item.title}</Typography>
                </Box>
              )}
            </Box>

            <Box sx={ResponsiveMobileValue(isMobile, {} , StyledBoxForTitle)}>
              {!isMobile && (
                <Box>
                  <Typography sx={styledTitle(isDarkTheme)}>{item.title}</Typography>
                </Box>
              )}
              {item.des.map((description: any) => {
                return (
                  <>
                    {' '}
                    <Box sx={StyledDescritionBox}>
                      <FiberManualRecordIcon sx={styledDot(isDarkTheme)}> </FiberManualRecordIcon>
                      <Typography sx={styledDescrition(isDarkTheme)}>{description}</Typography>
                    </Box>
                  </>
                );
              })}

              {index == 1 && <StyledDividerSmall />}
              {(pageTpe === 'coachBio') ?
                (index === 2) ? (
                  <Box sx={StyledBtnBox}>
                    <HDButton
                      showAnimation={true}
                      variant={'contained'}
                      sx={StyledBtn}
                      endIcon={<ArrowForwardSharpIconStyled />}
                      text={`Select ${firstName}`}
                      onClick={() => props.nextStep(item)}
                    />
                  </Box>
                ) : ''
                : <></>
              }
            </Box>
          </Box>
        </>
      );
    }
  }));
};