import React, { useEffect, useState, useContext } from 'react';
import { Box, Divider, Grid, Typography, Container, useTheme, useMediaQuery, CssBaseline, styled } from '@mui/material';
import Header from '../../commons/Header';
import Footer from '../../commons/Footer';
import BreadCrumb from '../../hd-ui-kit/HDBreadcrumb/HDBreadCrumb';
import HDExperienceCard from '../../hd-ui-kit/HDExperienceCard';
import HDProgressBar from '../../hd-ui-kit/HDProgressBar';
import Loader from '../../commons/Loader';
import Api from '../../Api';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import ExperienceReportDetails from './ExperienceReportDetails';
import HDButton from '../../hd-ui-kit/HDButton';
import HDNotificationBanner from '../../hd-ui-kit/HDNotificationBanner';
import { reactGAEvents, getImpactScore, LegendContent, backgroundTopLeft, backgroundTopLeftDark, CoachingBanner, getAssesmentStatus, exportPDF, exportBtnStyle } from '../../commons/commonFunctions';
import { globalContext } from '../../store';
import Link from '@mui/material/Link';

function ExperienceReport() {
  const theme = useTheme();
  const isDark = theme.palette.mode === 'dark';
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [showLoader, setShowLoader] = useState<boolean>(true);
  const { dispatch, globalState } = useContext(globalContext);
  let navigate = useNavigate();
  const { pathname } = useLocation();
  const [apiError, setApiError] = useState<string>('');
  const gaTags = { category: 'my-reports-experience-overview', tagName: 'export-pdf-impact' };

  const routes = [
    { text: 'Home', href: '/home' },
    { text: 'Business Impact Report', href: '#' },
  ];

  const SCTitleWrapper = styled(Box)(() => ({
    textAlign: 'left',
    marginTop: isMobile ? 16 : 32,
  }));

  const SCContents = styled(Box)(() => ({
    border: isMobile ? 'none' : '1px solid rgba(100, 106, 124, 0.2)',
    borderRadius: '4px',
    textAlign: 'left',
    padding: isMobile ? '0px' : '32px',
  }));

  const backColor = (isDark) ? { ...backgroundTopLeftDark } : { ...backgroundTopLeft };
  const SCPageContainer = styled('div')(() => ({
    width: '100%',
    //background: isDark ? '#0B0C10' : '#f8f8f8',
    ...backColor,
  }));

  const SCPageTitleSection = styled('div')(() => ({
    width: '100%',
    //background:
    //'linear-gradient(179.18deg, rgba(25, 126, 149, 0.1) 0.66%, rgba(248, 248, 248, 0) 72.91%)',
    padding: '48px 0',
  }));

  const SCPageContentSection = styled('div')(() => ({
    width: '100%',
    paddingBottom: '64px',
  }));

  const SCDivider = styled(Divider)(() => ({
    marginTop: '32px !important',
  }));

  const SCTitle = styled(Typography)(() => ({
    fontSize: isMobile ? '31px !important' : '41px !important',
  }));

  const SCDescription = styled(Box)(() => ({
    marginTop: '8px !important',
    display: 'flex',
    flexDirection: isMobile ? 'column' : 'row',
    justifyContent: 'space-between',
  }));

  const SCSubtitle = styled(Typography)(() => ({
    marginBottom: isMobile ? '8px !important' : 0,
    fontSize: '16px !important',
    lineHeight: '24px !important',
    fontFamily: 'Avenir !important',
  }));

  const SCItemContainer = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  }));

  const SCBenchMark = styled(Box)(() => ({
    padding: '1px',
  }));

  const progressTitleStyle = {
    fontFamily: 'Aventa',
    fontSize: '28px',
    fontWeight: 500,
    color: (isDark) ? '#fff' : '#002136',
  };

  const calculatePercentage = (inputReport: any) => {
    if (!inputReport.skills || !inputReport.skills.length) return 0;
    return inputReport.skills.reduce((prev: any, cur: any) => prev + (cur.checked ? 1 : 0), 0) / inputReport.skills.length;
  };

  const sortExperienceReports = (rawReports: any) => {
    if (!rawReports) return [];
    return rawReports.sort((report1: any, report2: any) => calculatePercentage(report2) - calculatePercentage(report1));
  };

  useEffect(() => {
    if (!globalState?.profile) {
      getProfileData();
    } else {
      setShowLoader(false);
    }
  }, []);

  const getProfileData = async () => {
    const { response } = await Api.getProfile();
    if (response && response.status === 200) {
      const { data } = response;
      const dataRes = (data?.data?.[0]) ? data.data[0] : data.data;
      if (dataRes) {
        dispatch({ type: 'SET_PROFILE', payload: dataRes });
        const assessmentId: string = dataRes.assessments.find((el: any) => el.name === 'Impact').uuid;
        const roleId: string = dataRes.roleProfileId;

        const impactReportData = await getImpactScore(roleId, assessmentId, dataRes, navigate);
        dispatch({ type: 'SET_IMPACT_REPORT', payload: impactReportData });
        const assesmentStatusData = await getAssesmentStatus();
        dispatch({ type: 'SET_ASSESSMENT_STATUS', payload: assesmentStatusData });
        setShowLoader(false);
      }
    }
  };

  const mockBenchmarkData = {
    benchMark: 50,
    progressDescription: `You align with ~${globalState?.impactReport?.otherDetails?.percentage}%~ of ${globalState?.profile?.businessRole?.name} targets for ${Object.keys(globalState?.impactReport?.reportData)?.length} core experience areas`,
    progress: globalState?.impactReport?.otherDetails?.percentage,
    primaryColor: '',
    aboveBMBarColor: '',
    belowBMBarColor: '',
    height: 15,
    progressNote: 'These experience areas are based on Heidrick\'s proprietary leadership data.',
    titleStyle: progressTitleStyle,
  };

  const exportBtnStyleMobile = {
    display: 'none',
  };

  const StyledCoachingBanner = {
    marginTop: '40px',
  };

  return (
    <>
      <CssBaseline />
      {showLoader && <Loader />}
      <SCPageContainer>
        <Header />
        {apiError && <HDNotificationBanner content={apiError} severity={'info'} variant={'filled'} />}

        <SCPageContainer>
          <SCPageTitleSection>
            <Container maxWidth='lg'>
              <BreadCrumb routes={routes} handleClick={(el: any) => reactGAEvents('my-reports-experience-overview', 'my-reports')} />
              {!isMobile && <SCDivider />}
              <SCTitleWrapper id='pageTitle'>
                <Box sx={{ justifyContent: 'space-between', display: 'flex' }}>
                  <SCTitle variant="subtitle1">Business Impact Report</SCTitle>
                  {!isMobile && (
                    <Link
                      onClick={() => exportPDF(setShowLoader, 'hfl_impact', 'business-impact-report.pdf', gaTags, setApiError)}
                      sx={exportBtnStyle(isMobile, theme.palette.mode)}
                      tabIndex={0}
                      underline="none"
                    >
                      Export PDF
                      <img style={{ height: '24px', width: '24px', marginLeft: '10px' }} alt='Export pdf logo' src={(isDark) ? '/res/ExportPDFIconDark.svg' : '/res/ExportPDFIcon.svg'} />
                    </Link>
                  )}
                </Box>
                <SCDescription>
                  <SCSubtitle>
                    A {globalState?.profile?.businessRole?.name} has experience in {globalState?.impactReport ? Object.keys(globalState?.impactReport?.reportData)?.length : ''} areas. See how your leadership skills compare.
                  </SCSubtitle>
                  <SCSubtitle>Last assessment: {globalState?.impactReport?.otherDetails?.LastAssessmentDate}</SCSubtitle>

                  {isMobile && <HDButton variant={'text'} text={'Export PDF'} endIcon={<img alt='Export pdf logo' src={isDark ? '/res/ExportPDFIconDark.svg' : '/res/ExportPDFIcon.svg'} />} sx={exportBtnStyleMobile} disableRipple={true} />}
                </SCDescription>
              </SCTitleWrapper>
              {isMobile && <SCDivider />}
              {!showLoader && (
                <Box sx={StyledCoachingBanner}>
                  <CoachingBanner />
                </Box>
              )}
            </Container>
          </SCPageTitleSection>

          <SCPageContentSection>
            <Container maxWidth='lg'>
              <SCContents id={'pageContent'}>
                <Box id='pageTopSection' sx={{ marginBottom: '32px' }}>
                  <SCBenchMark>
                    <HDProgressBar {...mockBenchmarkData} progressLegend={<LegendContent />} />
                  </SCBenchMark>
                </Box>
                <Grid container spacing={4}>
                  {sortExperienceReports(Object.values(globalState?.impactReport?.reportData))?.map((report: any, idx: number) => (
                    <Grid item xs={12} key={`report_${report.id}`}>
                      <SCItemContainer id={`compactCard${idx}`}>
                        <HDExperienceCard {...report} pathname={pathname} />

                        <Box id={`details${idx}`} className={'cardDescription'} style={{ display: 'none' }}>
                          <br />
                          <ExperienceReportDetails report={report} />
                        </Box>
                      </SCItemContainer>
                    </Grid>
                  ))}
                </Grid>
              </SCContents>
            </Container>
          </SCPageContentSection>
        </SCPageContainer>
        <Footer />
      </SCPageContainer>
      {/* <HDModal sx={{ '& .MuiDialog-paper': { maxWidth: '100%' } }} open={openPreview} handleModal={() => setPreviewOpen(false)} title=''>
        <BusinessImpactReport
          lastAssesmentDate={globalState?.impactReport?.otherDetails?.LastAssessmentDate}
          coreSkills={Object.keys(globalState?.impactReport?.reportData)?.length}
          scorePrecentage={globalState?.impactReport?.otherDetails?.percentage}
          expData={globalState?.impactReport?.reportData}
          profileDetails={globalState?.profile}
        />
      </HDModal> */}
    </>
  );
}

export default ExperienceReport;
