import React, { useState } from 'react';
import HDCoachProfileCard from '../../hd-ui-kit/HDCoachProfileCard/HDCoachProfileCard';
import { useTheme, useMediaQuery } from '@mui/material';
import { CoachBioDescrition } from '../../commons/CoachComponents';

const CoachBio = (props: any) => {
  const { data, lastName, firstName, coachItem, avatar } = props;
  const [isReadMore, setIsReadMore] = useState(true);
  const theme = useTheme();
  const isDarkTheme = useTheme().palette.mode === 'dark';
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  return (
    <>
      <HDCoachProfileCard
        showButton={true}
        showAnimation={true}
        profileBGMobile={isDarkTheme ? './res/coachProfileBGMobileDark.svg' : './res/coachProfileBGMobileLight.svg'}
        profileBGDark={isDarkTheme ? './res/coachProfileBGDark.svg' : './res/coachProfileBGLight.svg'}
        profileImg={avatar}
        firstName={firstName}
        lastName={lastName}
        handleClick={() => props.nextStep(coachItem)}
      />

      {CoachBioDescrition(data,isMobile, isDarkTheme, isReadMore, toggleReadMore, 'coachBio', firstName, props)}
    </>
  );
};

export default CoachBio;
