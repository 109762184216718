import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Box, useTheme, Container } from '@mui/material';
export interface HDHeaderTypes {
  logo?: string;
  logoLink?: string;
  logoClick: () => void;
  alt?: string;
  logoHeight?: string | number;
  logoWidth?: string | number;
  children?:
  | string
  | number
  | boolean
  | React.ReactElement<any, string | React.JSXElementConstructor<any>>
  | React.ReactFragment
  | React.ReactPortal
  | null;
  logoStyle?: any;
}

const HDHeader = (props: HDHeaderTypes) => {
  const theme = useTheme();
  const isDark = theme.palette.mode === 'dark';

  const appBarStyle = {
    boxShadow: 'none',
    borderBottom: '1px solid ' + ((isDark) ? '#3C3D40' : '#E0E1E5'),
  };

  return (
    <AppBar position='static' sx={appBarStyle}>
      <Container>
        <Toolbar disableGutters>
          <Box sx={props.logoStyle}>
            <a href={props.logoLink} onClick={() => props.logoClick()}>
              <img
                src={props.logo}
                alt={props.alt}
                height={props.logoHeight}
                width={props.logoWidth}
              />
            </a>
          </Box>
          {props.children}
        </Toolbar>
      </Container>
    </AppBar >
  );
};

export default HDHeader;
