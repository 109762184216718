import React, { useState } from 'react';
import {
  Box,
  Container,
  Divider,
  List,
  useMediaQuery,
  useTheme,
  CssBaseline, styled
} from '@mui/material';
import Header from '../commons/Header';
import Footer from '../commons/Footer';
import HDButton from '../hd-ui-kit/HDButton';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import SimpleImageSlider from 'react-simple-image-slider';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import HDModal from '../hd-ui-kit/HDModal';
import { reactGAEvents } from '../commons/commonFunctions';

function MarketingHomePage() {
  const originHost = window.location.origin;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const [slideCount, setSlideCount] = useState(1);
  const [slideContent, setSlideContent] = useState('Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.');
  const [modalState, setModalState] = useState(false);

  const backgroundBanerColor = (bannerImage: string) => {
    return ({
      background: 'linear-gradient(257.11deg, rgba(11, 12, 16, 0) 27.8 %, #1D2F44 100 %)',
      backgroundImage: `url(${bannerImage})`,
      backgroundColor: 'rgb(11,12,16)',
      backgroundPosition: (isMobile) ? 'top' : (isTablet && !isMobile) ? '250px' : 'right',
      backgroundSize: (isMobile) ? 'contain' : 'cover',
      backgroundRepeat: 'no-repeat',
      minHeight: '465px',
    });
  };

  const textWrapStyle = {
    fontFamily: 'Avenir',
    width: (isMobile) ? '100%' : '50%',
    textAlign: 'left',
    marginTop: (isMobile) ? '240px' : '81px',
    marginBottom: '40px',
  };

  const titleNoteStyle = {
    fontWeight: 300,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#CCCCCC',
    textTransform: 'uppercase',
    marginBottom: '8px',
  };

  const titleStyle = {
    fontFamily: 'Aventa',
    fontWeight: 500,
    fontSize: '41px',
    lineHeight: '54px',
    color: '#FFFFFF',
    marginBottom: '8px',
  };

  const descStyle = {
    fontWeight: 300,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#F8F8F8',
    marginBottom: '16px',
  };

  const btnStyle = {
    backgroundColor: '#F1F1F1',
    color: '#002136',
    border: 'none',
    '&:hover': {
      backgroundColor: '#F1F1F1',
      boxShadow: 'none',
    },
    '&:focus': {
      border: '1px solid #18768C',
      background: 'transparent !important',
    },
    '&:focus:before': {
      content: '""',
      height: '89%',
      width: '96%',
      position: 'absolute',
      borderRadius: 'inherit',
      background: '#F1F1F1',
      zIndex: '-1',
    },
    marginBottom: (isMobile) ? '32px' : '81px',
  };

  const cardWrapper = {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: (isMobile) ? 'column' : 'unset',
  };

  const cardStyle = {
    background: '#002136',
    borderRadius: '6px'
  };

  const cardTextStyle = {
    display: 'flex',
    padding: (isMobile || isTablet) ? '20px 20px' : '48px 55px',
    border: '1px solid rgba(255, 255, 255, 0.1)',
    borderRadius: '6px 6px 0px 0px',
    flexDirection: (isMobile || isTablet) ? 'column' : 'row'
  };

  const cardIcon = {
    width: '30%',
    height: '100px',
    display: 'flex',
    alignSelf: 'center',
    marginBottom: '20px'
  };

  const cardNoteText = {
    fontFamily: 'Aventa',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#F8F8F8',
    textTransform: 'uppercase',
  };

  const cardDescText = {
    fontFamily: 'Aventa',
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '29px',
    color: '#FFFFFF'
  };

  const cardTextWrap = {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
    marginLeft: '22px',
  };

  const cardAction = {
    fontFamily: 'Aventa',
    fontWeight: 500,
    fontSize: '22px',
    lineHeight: '32px',
    textAlign: 'center',
    color: '#FFFFFF',
    alignItems: 'center',
    background: '#197E95',
    paddingTop: '22px',
    paddingBottom: '22px',
    border: '1px solid rgba(255, 255, 255, 0.1)',
    borderRadius: '0px 0px 6px 6px'
  };

  const cardActionBtn = {
    fontFamily: 'Aventa',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '23px',
    textDecorationLine: 'underline',
    color: '#FFFFFF',
    padding: 0,
    '&:hover': {
      color: '#fff'
    },
    '&:focus': {
      color: '#fff'
    }
  };

  const bodyWrapper = {
    backgroundImage: (isMobile || isTablet) ? '' : 'url(res/marketingBG.svg)',
    //backgroundColor: 'radial-gradient(80% 90% at 100% 9.36%, #E3ECEF 0%, rgba(241, 241, 241, 0) 100%), #FFFFFF;',
    backgroundRepeat: 'no-repeat',
    backgroundColor: '#fff'
  };

  const bodyContentWrapper = {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '63px',
  };

  const images = [
    { url: originHost +  '/res/sliderImage1.png' },
    { url: originHost + '/res/sliderImage2.png' },
    { url: originHost + '/res/sliderImage3.png' }
  ];

  const sliderWrapper = {
    width: '50%',
    position: 'relative',
    'button': {
      background: '#002136 !important',
      borderRadius: '50%',
      padding: '4% !important',
      width: '64px !important',
      height: '64px !important',
      display: 'flex !important',
      justifyContent: 'center',
    }
  };

  const sliderContentWrapper = {
    width: (isMobile || isTablet) ? '100%' : '50%',
    position: 'relative',
    textAlign: 'left',
    padding: (isMobile || isTablet) ? '0' : '130px 50px 0 50px',
  };

  const contentTitle = {
    fontFamily: 'Aventa',
    fontWeight: 500,
    fontSize: '41px',
    lineHeight: '54px',
    color: '#002136',
  };

  const ListCount = styled(Box)(() => ({
    background: '#197E95',
    width: '58px',
    height: (isMobile) ? '54px' : '58px',
    fontFamily: 'Aventa',
    fontWeight: 500,
    fontSize: '22px',
    lineHeight: '32px',
    color: '#FFFFFF',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '32px',
    position: 'relative',
    top: '10px'
  }));

  const listItemStyle = {
    display: 'flex',
    alignItems: 'baseline',
    flexDirection: 'column'
  };

  const listTitleStyle = {
    fontFamily: 'Aventa',
    fontWeight: 500,
    fontSize: '22px',
    lineHeight: '32px',
    color: '#002136',
  };

  const listDescStyle = {
    fontFamily: 'Avenir',
    fontWeight: 300,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#334255',
  };

  const dividerStyle = {
    color: '#646A7C',
    marginTop: '32px',
    marginBottom: '32px'
  };

  const itemTextStyle = {
    width: '80%'
  };

  const inactiveCount = {
    background: '#fff',
    border: '1px solid rgba(100, 106, 124, 0.2)',
    color: '#0B0C10',
  };

  const onNavigationClick = (event: any) => {
    if (event) {
      if (slideCount >= 3) {
        setSlideCount(1);
        reactGAEvents('home', 'view-1');
      } else {
        setSlideCount(slideCount + 1);
        reactGAEvents('home', `view-${slideCount + 1}`);
      }
    } else {
      if (slideCount === 1) {
        setSlideCount(3);
        reactGAEvents('home', 'view-3');
      } else if (slideCount === 3) {
        setSlideCount(2);
        reactGAEvents('home', 'view-2');
      } else if (slideCount === 2) {
        setSlideCount(1);
        reactGAEvents('home', 'view-1');
      }
    }
  };

  const MarketingCards = () => {
    return (
      <Box sx={cardWrapper}>
        <Box sx={{ ...cardStyle, marginRight: (isMobile) ? 0 : '10px', marginBottom: (isMobile) ? '20px' : 'unset' }}>
          <Box sx={cardTextStyle}>
            <img alt='Marketing target logo' src={'res/marketingIconBenchmark.svg'} style={(isMobile || isTablet) ? cardIcon : {}} />
            <Box sx={cardTextWrap}>
              <Box sx={cardNoteText}>Benchmark report</Box>
              <Box sx={cardDescText}>Learn what you need to work on to level up and thrive as a leader</Box>
            </Box>
          </Box>
          <Box sx={cardAction}>
            Free
          </Box>
        </Box>

        <Box sx={{ ...cardStyle, marginLeft: (isMobile) ? 0 : '10px', marginBottom: (isMobile) ? '32px' : 'unset' }}>
          <Box sx={cardTextStyle}>
            <img alt='Marketing coaching logo' src={'res/marketingIconCoaching.svg'} style={(isMobile || isTablet) ? cardIcon : {}} />
            <Box sx={cardTextWrap}>
              <Box sx={cardNoteText}>Coaching</Box>
              <Box sx={cardDescText}>Elevate your career with the guidance of a Heidrick executive coach</Box>
            </Box>
          </Box>
          <Box sx={{ ...cardAction, paddingTop: '6px', paddingBottom: '6px' }}>
            $499 / month<br />
            <HDButton
              variant={'text'}
              text={'What\'s Included?'}
              sx={cardActionBtn}
              onClick={() => { setModalState(true); reactGAEvents('home', 'coach-pricing-open'); }}
            />
          </Box>
        </Box>
      </Box>
    );
  };

  const dividerStyleModal = {
    borderColor: '#E0E1E5',
    margin: '24px 0'
  };

  const ModalTitle = () => {
    const titleStyle = {
      fontFamily: 'Aventa',
      fontWeight: 500,
      fontSize: '31px',
      lineHeight: '41px',
      color: '#002136'
    };

    return (
      <>
        <Box sx={titleStyle}>
          <span style={{ ...titleStyle, color: '#18768C' }}>$499/month</span> for Heidrick Coaching includes:
        </Box>
        <Divider sx={dividerStyleModal} />
      </>
    );
  };

  const ModalDescription = () => {
    const rowStyle = {
      display: 'flex',
      flexDirection: (isMobile) ? 'column' : 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-start'
    };

    const imgContainer = {
      width: '20%',
      marginRight: '30px',
      display: 'flex',
      justifyContent: 'center'
    };

    const rowTitle = {
      fontFamily: 'Aventa',
      fontWeight: 500,
      fontSize: '20px',
      lineHeight: '29px',
      color: '#002136',
    };

    const rowDescription = {
      fontFamily: 'Avenir',
      fontWeight: 300,
      fontSize: '16px',
      lineHeight: '24px',
      color: '#334255'
    };

    return (
      <Box>
        <Box sx={rowStyle}>
          <Box sx={imgContainer}>
            <img alt='Marketing coaching session logo' src='./res/marketingRow1.svg' />
          </Box>
          <Box>
            <Box sx={rowTitle}>1 Coaching Session Per Month</Box>
            <Box sx={rowDescription}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</Box>
          </Box>
        </Box>
        <Divider sx={dividerStyleModal} />
        <Box sx={rowStyle}>
          <Box sx={imgContainer}>
            <img alt='Marketing leadership development logo' src='./res/marketingRow2.svg' />
          </Box>
          <Box>
            <Box sx={rowTitle}>Leadership Development Plan</Box>
            <Box sx={rowDescription}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</Box>
          </Box>
        </Box>
        <Divider sx={dividerStyleModal} />
        <Box sx={rowStyle}>
          <Box sx={imgContainer}>
            <img alt='Marketing coach communication logo' src='./res/marketingRow3.svg' />
          </Box>
          <Box>
            <Box sx={rowTitle}>Ongoing Coach Communication</Box>
            <Box sx={rowDescription}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</Box>
          </Box>
        </Box>
      </Box>
    );
  };

  const modalStyle = {
    '& .MuiDialog-paper': {
      backgroundColor: '#fff'
    },
    '& .MuiDialog-paper button': {
      color: '#000'
    }
  };

  const handleModal = () => {
    setModalState(false);
    reactGAEvents('home', 'coach-pricing-close');
  };

  const backDropProps = {
    style: {
      backgroundColor: 'rgba(0, 33, 54, 0.9)',
    }
  };

  return (
    <>
      <CssBaseline />
      <Header showSignin={true} signinClick={() => reactGAEvents('home', 'sign-in')} />
      <Box sx={{
        ...backgroundBanerColor(isMobile ?  originHost + '/res/marketingBannerMobile.png' :  originHost + '/res/marketingBanner.jpg'),
        backgroundPosition: (isTablet && !isMobile) ? 'right' : (isMobile) ? 'top' : '100% -2px',
        backgroundSize: (isMobile) ? 'contain' : 'cover',
      }}
      >
        <Container>
          <Box sx={textWrapStyle}>
            <Box sx={titleNoteStyle}>Heidrick for leaders</Box>
            <Box sx={titleStyle}>Take control of your career</Box>
            <Box sx={descStyle}>Join the Heidrick network. Discover your strengths and areas for improvement. And access to Heidrick's world class coaches if you need them.</Box>
            <HDButton
              variant={'contained'}
              text={'Create your profile'}
              endIcon={<ArrowForwardIcon sx={{ color: '#18768C' }} />}
              onClick={() => reactGAEvents('home', 'create-account-top')}
              showAnimation
              sx={btnStyle}
            />
          </Box>

          {isMobile && <MarketingCards />}
        </Container>
      </Box >

      <Box sx={bodyWrapper}>
        <Container sx={{ position: 'relative', top: (isMobile) ? '0' : '-105px' }}>
          {!isMobile && <MarketingCards />}

          <Box sx={bodyContentWrapper}>
            {(!isMobile && !isTablet) &&
              <Box sx={sliderWrapper}>
                <SimpleImageSlider
                  width={'100%'}
                  height={790}
                  images={images}
                  showBullets={false}
                  showNavs={true}
                  navStyle={1}
                  bgColor={'transparent'}
                  onClickNav={(e) => onNavigationClick(e)}
                />
              </Box>
            }
            <Box sx={sliderContentWrapper}>
              <Box sx={contentTitle}>
                Getting started is easy — and free
                <Divider sx={dividerStyle} />
              </Box>
              <Box>
                <List>
                  <ListItem sx={listItemStyle}>
                    <Box sx={{ display: 'flex' }}>
                      <ListCount sx={(slideCount !== 1 || (isMobile || isTablet)) ? inactiveCount : {}}>1</ListCount>
                      <ListItemText
                        sx={itemTextStyle}
                        primary='Create your Heidrick for Leaders profile'
                        primaryTypographyProps={listTitleStyle}
                        secondary={(slideCount === 1 || (isMobile || isTablet)) ? slideContent : ''}
                        secondaryTypographyProps={listDescStyle}
                      />
                    </Box>
                    {(isMobile || isTablet) &&
                      <Box sx={{ display: 'flex' }}>
                        <ListCount sx={{ ...inactiveCount, background: 'transparent', border: 'none' }}></ListCount>
                        {(isMobile || isTablet) && <img alt='Slide right left logo' src='./res/sliderImage1.svg' style={{ width: '80%' }} />}
                      </Box>
                    }
                  </ListItem>
                  <ListItem sx={listItemStyle}>
                    <Box sx={{ display: 'flex' }}>
                      <ListCount sx={(slideCount !== 2) ? inactiveCount : {}}>2</ListCount>
                      <ListItemText
                        sx={itemTextStyle}
                        primary='Take the free Heidrick Benchmark Assessments'
                        primaryTypographyProps={listTitleStyle}
                        secondary={(slideCount === 2 || (isMobile || isTablet)) ? slideContent : ''}
                        secondaryTypographyProps={listDescStyle}
                      />
                    </Box>
                    {(isMobile || isTablet) &&
                      <Box sx={{ display: 'flex' }}>
                        <ListCount sx={{ ...inactiveCount, background: 'none', border: 'none' }}></ListCount>
                        {(isMobile || isTablet) && <img alt='Slide right left logo' src='./res/sliderImage2.svg' style={{ width: '80%' }} />}
                      </Box>
                    }
                  </ListItem>
                  <ListItem sx={listItemStyle}>
                    <Box sx={{ display: 'flex' }}>
                      <ListCount sx={(slideCount !== 3) ? inactiveCount : {}}>3</ListCount>
                      <ListItemText
                        sx={itemTextStyle}
                        primary='Find a coach and schedule your first session'
                        primaryTypographyProps={listTitleStyle}
                        secondary={(slideCount === 3 || (isMobile || isTablet)) ? slideContent : ''}
                        secondaryTypographyProps={listDescStyle}
                      />
                    </Box>
                    {(isMobile || isTablet) &&
                      <Box sx={{ display: 'flex' }}>
                        <ListCount sx={{ ...inactiveCount, background: 'none', border: 'none' }}></ListCount>
                        {(isMobile || isTablet) && <img alt='Slide right left logo' src='./res/sliderImage3.svg' style={{ width: '80%' }} />}
                      </Box>
                    }
                  </ListItem>
                </List>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>

      <Box sx={{
        ...backgroundBanerColor('res/marketingBannerFooter.jpg'), 'minHeight': 'unset',
        backgroundSize: (isMobile) ? 'contain' : (isTablet && !isMobile) ? 'cover' : 'contain',
        backgroundPosition: (isMobile) ? 'top' : (isTablet && !isMobile) ? '250px' : '550px 0px',
      }}
      >
        <Container>
          <Box sx={{ ...textWrapStyle, marginTop: (isMobile) ? '230px' : 'unset', marginBottom: 0 }}>
            <Box sx={{ ...titleStyle, marginTop: '60px' }}>The secrets of executive search are at your fingertips</Box>
            <Box sx={descStyle}>We founded executive search. Now we're sharing what we know about leadership roles to help individuals — like you — reach their full potential.</Box>
            <HDButton
              variant={'contained'}
              text={'Create your profile'}
              endIcon={<ArrowForwardIcon sx={{ color: '#18768C' }} />}
              onClick={() => reactGAEvents('home', 'create-account-bottom')}
              showAnimation
              sx={{ ...btnStyle, marginBottom: '64px' }}
            />
          </Box>
        </Container>
      </Box>

      <HDModal
        sx={modalStyle}
        open={modalState}
        title={<ModalTitle />}
        description={<ModalDescription />}
        handleModal={() => handleModal()}
        backdropProps={backDropProps}
      />
      <Footer />
    </>
  );
}

export default MarketingHomePage;
