import React, { useState, useRef, useContext } from 'react';
import { Box, Divider, Typography, useMediaQuery, useTheme ,CssBaseline, styled} from '@mui/material';
import { headerStyle, pageWrapper, titleUnderline, titleNote } from '../profile/form-component/styles';
import HDButton from '../../hd-ui-kit/HDButton/HDButton';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import FindCoach from './FindCoach';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import Api from './../../Api';
import { reactGAEvents, ResponsiveThemeValue, ResponsiveMobileValue } from '../../commons/commonFunctions';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import Link from '@mui/material/Link';
import { globalContext } from '../../store';
import RescheduleCard from './RescheduleCard';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';

dayjs.extend(utc);
dayjs.extend(timezone);

function SessionDetails(props: any) {
  const {
    skipPreviousSessions,
    sessionState,
    setSessionState,
    allSessionData,
    detailSessionData,
    setActiveTabIndex,
    sessionApiData,
    leaderDetailsData,
    errorMsg,
    futureSessions,
    scheduleFutureSessions,
    previousSessions,
  } = props;
  const theme = useTheme();
  const themeMode = theme.palette.mode;
  const originHost = window.location.origin;

  const isDarkTheme = useTheme().palette.mode === 'dark';
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [openFSModal, setOpenFSModal] = useState<any>(false);
  const coachModalRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState<number>(0);
  const [availabilityAllData, setAvailabilityAllData] = useState<any>();
  const [availableDates, setAvailableDates] = useState({});
  const [selectedCoachItem, setSelectedCoachItem] = useState({});
  const [openToaster, setOpenToaster] = React.useState(false);
  const [isReschedule, setIsReschedule] = React.useState(false);
  const [rescheduleSessionId, setRescheduleSessionId] = React.useState(0);
  const [loadingMsg, setLoadingMsg] = useState('We are fetching all available dates');
  const [errorMessage, setErrorMessage] = useState<any>('');
  const { globalState, dispatch } = useContext(globalContext);
  let todaysDate = new Date();
  todaysDate.setHours(todaysDate.getHours() + 72);
  const handleClick = (link: string) => {
    setOpenToaster(true);
    if ('clipboard' in navigator) {
      navigator?.clipboard?.writeText(link);
    }
  };

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenToaster(false);
  };

  const addMinutes = function (dt: any, minutes: any) {
    return new Date(dt.getTime() + minutes * 60000);
  };

  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
  });

  const BodyWrapper = styled(Box)(() => ({
    marginTop: '25px',
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    flexFlow: ResponsiveMobileValue(isMobile,'column' , 'row'),
  }));

  const LeftSection = styled(Box)(() => ({
    background: ResponsiveThemeValue (theme,'#F8F8F8' , '#002136'),
    border: ResponsiveThemeValue (theme,'1px solid rgba(100, 106, 124, 0.2)' , 'none'),
    borderRadius: '6px',
    padding: '24px',
    width: ResponsiveMobileValue(isMobile,'100%' , '49%'),
    marginBottom: ResponsiveMobileValue(isMobile,'20px' , '0px'),
  }));

  const RightSection = styled(Box)(() => ({
    background: 'transparent',
    border:ResponsiveThemeValue(theme,'1px solid rgba(100, 106, 124, 0.2)' , '1px solid rgba(255, 255, 255, 0.2)'),
    borderRadius: '6px',
    padding: '24px',
    width: isMobile ? '100%' : '49%',
  }));

  const fontFamily = { fontFamily: 'Aventa' };
  const fontColor = { color: themeMode === 'light' ? '#002136' : '#fff' };

  const boxTitleStyle = {
    ...fontFamily,
    ...fontColor,
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '29px',
  };

  const dateStyle = {
    ...fontFamily,
    ...fontColor,
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    paddingTop: '16px',
  };

  const coachLinkStyle = {
    ...fontFamily,
    ...fontColor,
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '23px',
    textDecorationLine: 'underline',
    marginTop: '16px',
    cursor: 'pointer',
  };

  const dividerStyle = {
    marginTop: '16px',
    borderColor: ResponsiveThemeValue (theme,'rgba(100, 106, 124, 0.2)' , 'rgba(255, 255, 255, 0.2)'),
  };

  const zoomBtnWrap = {
    marginTop: '48px',
  };

  const zoomBtnStyle = {
    ':disabled': {
      ...fontFamily,
      ...fontColor,
      fontWeight: 600,
      fontSize: '15px',
      lineHeight: '22px',
      background: ResponsiveThemeValue (theme,'#FFFFFF' , '#0B0C10'),
      borderRadius: '6px',
      width: '100%',
      ':hover': {
        background: ResponsiveThemeValue (theme,'#FFFFFF' , '#0B0C10'),
      },
    },
  };

  const copyLinkStyle = {
    ...fontFamily,
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '23px',
    textDecorationLine: 'underline',
    color: ResponsiveThemeValue (theme,'#646A7C' , '#FFF'),
    padding: 0,
    marginTop: '16px',
    ':hover': {
      color: ResponsiveThemeValue (theme,'#646A7C' , '#FFF'),
    },
    ':focus': {
      color: ResponsiveThemeValue (theme,'#646A7C' , '#FFF'),
    },
    cursor: 'pointer',
    display: 'flex'
  };

  const discussionStyle = {
    fontFamily: 'Avenir',
    fontWeight: 300,
    fontSize: '16px',
    lineHeight: '24px',
    color: ResponsiveThemeValue (theme,'#334255' , '#CCC'),
    marginTop: '16px',
  };

  const TextIconStyle = styled('img')(() => ({
    width: '12px',
    marginRight: '10px',
    top: '1px',
    position: 'relative',
  }));

  const ArrowIcon = styled(ArrowForwardIcon)(() => ({
    color:ResponsiveThemeValue(theme,'#66FCFC' , '#18768C'),
  }));

  const ArrowIconCompleted = styled(ArrowForwardIcon)(() => ({
    color:ResponsiveThemeValue(theme,'#002136' , '#fff'),
    marginLeft: '30px',
  }));

  const CompletedSessionWrap = styled(Box)(() => ({
    marginTop: '16px',
  }));

  const CompletedSession = styled(Box)(() => ({
    background:ResponsiveThemeValue (theme,'#F8F8F8' , '#002136'),
    border:ResponsiveThemeValue (theme,'1px solid rgba(100, 106, 124, 0.2)' , 'none'),
    borderRadius: '6px',
    padding: '27px 32px',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection:ResponsiveMobileValue(isMobile,'column' , 'row'),
    marginBottom: '16px',
  }));

  const SessionName = styled(Box)(() => ({
    ...fontFamily,
    ...fontColor,
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '23px',
  }));

  const StyleUL = styled('ul')(() => ({
    paddingLeft: '33px',
  }));

  const SessionStatus = styled(Box)(() => ({
    ...fontFamily,
    ...fontColor,
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    display: 'flex',
    alignItems: 'center',
    marginTop: ResponsiveMobileValue(isMobile,'10px' , '0px'),
  }));

  const StyledTitle = {
    color: ResponsiveThemeValue(theme,'#646A7C' , '#FFF'),
    fontFamily: 'Aventa',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '23px',
    textDecorationLine: 'underline',
    cursor: 'pointer',
  };

  const divLinkStyle = {
    marginTop: '15px',
  };

  const handleFSModal = () => {
    setOpenFSModal(false);
    coachModalRef.current.style.display = 'none';
  };

  const handleCoachNextStep = async (dateRange: any, sessionId: number, isRescheduleVar: boolean = false) => {
    if (coachModalRef.current) coachModalRef.current.style.display = 'block';
    setLoading(true);
    setOpenFSModal(true);
    setStep(0);
    setIsReschedule(true);
    setRescheduleSessionId(sessionId);
    let res: any;
    if (globalState?.myCoachData) {
      res = globalState.myCoachData;
    } else {
      res = await Api.getMyCoachApi();
      dispatch({ type: 'SET_MYCOACH_DATA', payload: res });
    }
    if (res.success) {
      let isToday = (new Date(dateRange?.startDate).toDateString() == new Date().toDateString());
      let dateRangeN = dateRange;
      if (isToday) {
        dateRangeN = { ...dateRange, startDate: dayjs.utc(new Date()).format('YYYY-MM-DDTHH:mm:ss') };
      }
      let payload = {
        coach_id: res.response.data.coach_id,
        isSessionList: true,
        isSubscribed: true,
        //lastSessionCompletedDate: allSessionData?.sessions?.[0]?.start_time
        ...dateRangeN
      };
      if (isRescheduleVar) {
        Object.assign(payload, { isReschedule: true });
      }

      Api.getCoachAvailability(payload)
        .then((response: any) => {
          if (response.success) {
            setAvailableDates(Object.keys(response?.response?.data));
            setAvailabilityAllData(response?.response?.data);
            setSelectedCoachItem(res.response.data);
            setLoadingMsg('We are fetching all available dates');
            setStep(1);
            setLoading(false);
            setErrorMessage('');
          } else {
            setLoadingMsg('We are fetching all available dates');
            let link = (
              <a href='https://heidricksupport.zendesk.com/hc/en-us' target="_blank" style={{ color: 'inherit' }}>
                contact support
              </a>
            );
            const errorMsg = <p>There seems to be a problem accessing your coach feature. Please contact support for help. {link}.</p>;
            setErrorMessage(errorMsg);
            setLoading(false);
          }
        })
        .catch((err: any) => {
          console.log(err);
        });
    } else {
      setLoadingMsg('We are fetching all available dates');
      let link = (
        <a href="https://heidricksupport.zendesk.com/hc/en-us" target="_blank" style={{ color: 'inherit' }}>
          contact support
        </a>
      );
      const errorMsg = <p>There seems to be a problem accessing your coach feature. Please contact support for help. {link}.</p>;
      setErrorMessage(errorMsg);
      setLoading(false);
    }
  };

  const nextSessionId = () => {
    if (isReschedule) {
      return rescheduleSessionId;
    } else {
      let id: any = null;
      sessionApiData &&
        sessionApiData?.length &&
        sessionApiData?.forEach((item: any, index: any) => {
          if (item.start_date_hfl_specific) {
            id = index + 1;
          }
        });

      const sessionId = id ? sessionApiData?.[id]?.session_id : null;
      return sessionId;
    }
  };

  const requestNewTime = (coachEmail: string, coachName: string, sessionDate: string, sessionId: number, bookingWindow: any) => {
    let sessDate = new Date(sessionDate);

    if (sessDate.getTime() < todaysDate.getTime()) {
      window.location.href = `mailto:${coachEmail}?subject=${leaderDetailsData?.personal_details?.first_name} ${leaderDetailsData?.personal_details?.last_name} would like to reschedule their session&body=Dear ${coachName},%0d%0a%0d%0aI would like to reschedule my appointment.%0d%0a%0d%0aHere are some availabilities: %0d%0a%0d%0a%0d%0a`;
    } else {
      setIsReschedule(true);
      setRescheduleSessionId(sessionId);
      handleCoachNextStep(bookingWindow, sessionId, true);
    }
  };

  const Styledtitles = {
    fontFamily: 'Avenir',
    fontWeight: 300,
    fontSize: '16px',
    marginTop: isMobile ? '35px' : '10px',
  };

  const StyledSubText = {
    fontFamily: 'Avenir',
    fontWeight: 300,
    fontSize: '16px',
    marginTop: isMobile ? '15px' : '10px',
    color: themeMode === 'light' ? '#334255' : '#fff',
  };

  const StyledSubTitles = {
    fontFamily: 'Avenir',
    fontWeight: 300,
    fontSize: '16px',
    height: '41px',
    marginTop: '20px',
  };

  const StyledSessionName = {
    display: 'flex',
    marginTop: '15px',
  };

  const generateDiscussionTopics = (detailSessionData: any) => {
    return (
      <Box>
        {detailSessionData?.action_items && detailSessionData?.action_items?.length ? (
          detailSessionData?.action_items?.map((items: any, index: any) => {
            return (
              <Box key={index}>
                <ul>
                  <li>{items.text} </li>
                </ul>
              </Box>
            );
          })
        ) : (
          <Box sx={discussionStyle}>Your coach has yet to add any information.</Box>
        )}
      </Box>
    );
  };

  return (
    <>
      <CssBaseline />
      <Box sx={pageWrapper(isMobile, themeMode)}>
        {sessionState?.status != 'oldSession' && (
          <>
            <Typography sx={headerStyle(themeMode)}>
              {(sessionState.status == 'notScheduled') ? 'Welcome to your coaching program dashboard' : 'My coaching dashboard'}
            </Typography>
            <Divider sx={titleUnderline(themeMode)} />
            <Typography sx={Styledtitles}>You can schedule your sessions, access your Zoom link, and review the session notes — all in one place.</Typography>
            <StyleUL>
              <li>
                <Typography sx={StyledSubTitles}>You can schedule one or two session(s) per rolling month based on your subscription plan, up to six months in advance.</Typography>
              </li>
              <li>
                <Typography sx={Styledtitles}>When you choose to cancel, all scheduled sessions starting the next rolling month will automatically be canceled.</Typography>
              </li>
            </StyleUL>
          </>
        )}

        {sessionState.status == 'scheduled' && (
          <>
            <Box sx={{ marginTop: '15px' }}>
              <Typography sx={headerStyle(themeMode)}>
                {detailSessionData?.title} <br />
                {detailSessionData?.start_date_hfl_specific ? (
                  <>
                    {dayjs(new Date(detailSessionData?.start_date_hfl_specific?.replace(/-/g, '/'))).format('dddd, MMM D')} at
                    {dayjs(new Date(detailSessionData?.start_date_hfl_specific?.replace(/-/g, '/'))).format(' h:mm A')}
                  </>
                ) : (
                  ''
                )}
              </Typography>

              <BodyWrapper>
                <LeftSection>
                  <Box sx={boxTitleStyle}>
                    Details
                    <Divider sx={dividerStyle} />
                  </Box>
                  <Box sx={dateStyle}>
                    <TextIconStyle src={themeMode === 'light' ? originHost + '/res/calendarLight.svg' : originHost + '/res/calendarDark.svg'} />
                    {detailSessionData?.start_date_hfl_specific ? (
                      <>
                        {dayjs(new Date(detailSessionData?.start_date_hfl_specific?.replace(/-/g, '/'))).format('ddd, MMM D')} at
                        {dayjs(new Date(detailSessionData?.start_date_hfl_specific?.replace(/-/g, '/'))).format(' h:mm A')}{' '}
                      </>
                    ) : (
                      ''
                    )}
                    <Divider sx={dividerStyle} />
                  </Box>
                  <Box sx={dateStyle}>
                    <TextIconStyle src={themeMode === 'light' ? '../res/clockLight.svg' : '../res/clockDark.svg'} />
                    {detailSessionData?.duration} minutes
                    <Divider sx={dividerStyle} />
                  </Box>
                  <Box sx={dateStyle}>
                    <Link tabIndex={0} underline="none" onClick={() => setActiveTabIndex(2)} sx={coachLinkStyle}>
                      <TextIconStyle src={themeMode === 'light' ? '../res/profileLight.svg' : '../res/profileDark.svg'} />
                      {allSessionData?.coach_full_name}
                    </Link>
                  </Box>
                  <Box sx={zoomBtnWrap}>
                    {detailSessionData?.zoom_url ? (
                      <HDButton
                        variant={'contained'}
                        text={'Join video session'}
                        showAnimation={true}
                        endIcon={<ArrowIcon />}
                        onClick={() => {
                          reactGAEvents('coach-session-management', 'start-session');
                          window.open(detailSessionData?.zoom_url, '_blank');
                        }}
                      />
                    ) : (
                      <HDButton variant={'contained'} text={'Zoom link not yet available'} sx={zoomBtnStyle} disabled />
                    )}
                  </Box>
                  {detailSessionData?.zoom_url ? (
                    <Box sx={divLinkStyle}>
                      <Link
                        onClick={() => {
                          reactGAEvents('coach-session-management', 'copy-session-link');
                          handleClick(detailSessionData?.zoom_url);
                        }}
                        sx={copyLinkStyle}
                        tabIndex={0}
                        underline="none"
                      >
                        Copy session link
                      </Link>
                    </Box>
                  ) : (
                    ''
                  )}

                  {new Date(detailSessionData?.start_date_hfl_specific?.replace(/-/g, '/')).getTime() > todaysDate.getTime() ? (
                    <Box sx={divLinkStyle}>
                      {' '}
                      <Link
                        onClick={() => {
                          reactGAEvents('coach-session-management', 'request-new-time');
                          requestNewTime(allSessionData?.coachEmailId, allSessionData?.coach_full_name, detailSessionData?.start_date_hfl_specific, detailSessionData?.session_id, detailSessionData?.booking_window);
                        }}
                        sx={copyLinkStyle}
                        tabIndex={0}
                        underline="none"
                      >
                        Request new time
                      </Link>{' '}
                    </Box>
                  ) : (
                    <Box sx={divLinkStyle}>
                      {' '}
                      <Link
                        onClick={() => {
                          reactGAEvents('coach-session-management', 'request-new-time');
                          requestNewTime(allSessionData?.coachEmailId, allSessionData?.coach_full_name, detailSessionData?.start_date_hfl_specific, detailSessionData?.session_id, detailSessionData?.booking_window);
                        }}
                        sx={copyLinkStyle}
                        tabIndex={0}
                        underline="none"
                      >
                        <EmailOutlinedIcon sx={{ marginRight: '10px' }} />Reschedule
                      </Link>{' '}
                    </Box>
                  )}
                </LeftSection>
                <RightSection>
                  <Box sx={boxTitleStyle}>Discussion Topics</Box>
                  {generateDiscussionTopics(detailSessionData)}
                </RightSection>
              </BodyWrapper>
              <Snackbar open={openToaster} autoHideDuration={3000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                  Session link copied to clipboard!
                </Alert>
              </Snackbar>
            </Box>
          </>
        )}

        {(sessionState.status == 'notScheduled' && scheduleFutureSessions && scheduleFutureSessions?.length) && (
          <Box sx={{ marginTop: '40px' }}>
            <RescheduleCard
              showButton={true}
              variant={'filled'}
              fromCoach={true}
              showAnimation={true}
              profileBGMobile={isDarkTheme ? '../res/coachProfileBGMobileDark.svg' : '../res/coachProfileBGMobileLight.svg'}
              profileBGDark={isDarkTheme ? '../res/coachProfileBGDark.svg' : '../res/coachProfileBGLight.svg'}
              title={`${scheduleFutureSessions?.[0]?.title}: ${scheduleFutureSessions?.[0]?.scheduling_period}`}
              handleClick={() => {
                reactGAEvents('coach-session-management', 'schedule-session');
                handleCoachNextStep(scheduleFutureSessions?.[0]?.booking_window, scheduleFutureSessions?.[0]?.session_id, true);
              }}
              profileImg={allSessionData?.coachAvatar}
            />
          </Box>
        )}

        {(futureSessions && futureSessions?.length && sessionState?.status != 'oldSession') ? (
          <>
            <Typography sx={{ ...titleNote(themeMode), fontFamily: 'Aventa', fontWeight: 500, marginTop: '32px', fontSize: '22px' }}>Future sessions you've scheduled</Typography>

            <CompletedSessionWrap>
              {futureSessions?.length &&
                futureSessions?.map((item: any, index: any) => {
                  return (
                    <CompletedSession key={`seesion_${item.session_id}`}>
                      <SessionName>{item.title}</SessionName>
                      <SessionStatus>
                        {' '}
                        <img alt="Session completed logo" src={originHost + '/res/calenderLogo.svg'} style={{ marginRight: '8px' }} />
                        {dayjs(item?.start_date_hfl_specific?.replace(/-/g, '/')).format('dddd, MMMM DD')} at
                        {dayjs(item?.start_date_hfl_specific?.replace(/-/g, '/')).format(' h:mm A')}
                        <HDButton
                          text={<ArrowIconCompleted />}
                          sx={{ padding: 0 }}
                          onClick={() => {
                            reactGAEvents('coach-session-management', 'schedule-session');
                            handleCoachNextStep(item?.booking_window, item?.session_id, true);
                          }}
                        />
                      </SessionStatus>
                    </CompletedSession>
                  );
                })}
            </CompletedSessionWrap>
          </>
        ) : (
          <></>
        )}

        {(scheduleFutureSessions && scheduleFutureSessions?.length >= 1 && sessionState?.status != 'oldSession') ? (
          <>
            <Typography sx={{ ...titleNote(themeMode), fontFamily: 'Aventa', fontWeight: 500, marginTop: '32px', fontSize: '22px' }}>Schedule future sessions (up to six months out)</Typography>
            <CompletedSessionWrap>
              {scheduleFutureSessions?.length &&
                scheduleFutureSessions?.map((item: any, index: any) => {

                  if (sessionState.status == 'notScheduled' && index === 0) {
                    return (<></>);
                  } else {
                    return (
                      <CompletedSession key={`seesion_${item.session_id}`}>
                        <SessionName>{item.title}</SessionName>
                        <SessionStatus>
                          {item.scheduling_period}

                          <HDButton
                            text={<ArrowIconCompleted />}
                            sx={{ padding: 0 }}
                            onClick={() => {
                              reactGAEvents('coach-session-management', 'schedule-session');
                              handleCoachNextStep(item?.booking_window, item?.session_id, true);
                            }}
                          />
                        </SessionStatus>
                      </CompletedSession>
                    );
                  }
                })}
            </CompletedSessionWrap>
          </>
        ) : (
          <></>
        )}

        {(((previousSessions && previousSessions?.length) || (skipPreviousSessions && skipPreviousSessions?.length)) && sessionState?.status != 'oldSession') ? (
          <>
            <Typography sx={{ ...titleNote(themeMode), fontFamily: 'Aventa', fontWeight: 500, marginTop: '32px', fontSize: '22px' }}>Previous coaching sessions</Typography>
            {skipPreviousSessions &&
              skipPreviousSessions?.length &&
              skipPreviousSessions?.map((item: any, index: any) => {
                return (
                  <Box key={`seesion_${item.session_id}`}>
                    <Divider sx={dividerStyle} />
                    <Box sx={StyledSessionName}>
                      <Typography sx={{ fontWeight: 500 }}>{item.title} — You skipped this session. </Typography>
                    </Box>
                    <Typography sx={StyledSubText}>
                      The scheduling window was&nbsp;
                      {dayjs(new Date(item?.start_date_hfl_specific?.replace(/-/g, '/'))).format('MMM D')} at
                      {dayjs(new Date(item?.start_date_hfl_specific?.replace(/-/g, '/'))).format(' h:mm A')}.&nbsp;
                      <Link
                        href='https://heidricksupport.zendesk.com/hc/en-us'
                        target='_blank'
                        underline={'always'}
                        color='inherit'
                      >
                        Contact support
                      </Link>
                      &nbsp;with any questions.
                    </Typography>
                  </Box>
                );
              })}
            <CompletedSessionWrap>
              {previousSessions &&
                previousSessions?.length &&
                previousSessions?.map((item: any, index: any) => {
                  const completedTime = addMinutes(new Date(item.start_date_hfl_specific?.replace(/-/g, '/')), item.duration).toString();
                  return (
                    <CompletedSession key={`seesion_${item.session_id}`}>
                      <SessionName>{item.title}</SessionName>
                      <SessionStatus>
                        {' '}
                        <img alt='Session completed logo' src={themeMode === 'light' ? originHost + '/res/checkMarkLight.svg' : originHost + '/res/checkMarkDark.svg'} style={{ marginRight: '8px' }} />
                        Completed - {dayjs(completedTime).utc().format('MMM D')}
                        <HDButton
                          text={<ArrowIconCompleted />}
                          sx={{ padding: 0 }}
                          onClick={() => {
                            reactGAEvents('coach-session-management', `completed-session-${index}`);
                            setSessionState({ ...sessionState, status: 'oldSession', oldSessionData: item });
                          }}
                        />
                      </SessionStatus>
                    </CompletedSession>
                  );
                })}
            </CompletedSessionWrap>
          </>
        ) : (
          <></>
        )}

        {sessionState?.status === 'oldSession' && (
          <>
            <Typography
              sx={StyledTitle}
              onClick={() => {
                const CheckUpComingStatus = (detailSessionData && new Date(detailSessionData.start_date_hfl_specific?.replace(/-/g, '/')) >= new Date());
                setSessionState({ ...sessionState, status: CheckUpComingStatus ? 'scheduled' : 'notScheduled' });
              }}
            >
              Back to dashboard
            </Typography>
            <Typography sx={headerStyle(themeMode)}>
              {sessionState?.oldSessionData?.title}<br />
              {dayjs(new Date(sessionState?.oldSessionData?.start_date_hfl_specific?.replace(/-/g, '/'))).format('dddd, MMM D')} at
              {dayjs(new Date(sessionState?.oldSessionData?.start_date_hfl_specific?.replace(/-/g, '/'))).format(' h:mm A')}
            </Typography>
            <SessionStatus sx={{ marginBottom: '16px' }}>
              {/* <img alt='Session completed logo' src={themeMode === 'light' ? '../res/checkMarkLight.svg' : '../res/checkMarkDark.svg'} style={{ marginRight: '8px' }} />
              Completed -{' '} */}

            </SessionStatus>
            <Divider sx={titleUnderline(themeMode)} />

            <BodyWrapper>
              <LeftSection>
                <Box sx={boxTitleStyle}>Post-Session Notes</Box>
                {sessionState?.oldSessionData?.notes?.map((note: any, key:any) => {
                  const parsedData = JSON.parse(note.body);
                  const textContent = parsedData.blocks.map((block: any) => block.text).join(' ');
                  return(
                    <Box sx={discussionStyle}>
                      {textContent}
                    </Box>
                  );
                })}
              </LeftSection>
              <RightSection>
                <Box sx={boxTitleStyle}>Discussion Topics</Box>
                {generateDiscussionTopics(sessionState?.oldSessionData)}
              </RightSection>
            </BodyWrapper>
          </>
        )
        }
      </Box >
      <FindCoach
        hideBackBtn={true}
        selectedCoachItem={selectedCoachItem}
        availableDates={availableDates}
        availabilityAllData={availabilityAllData}
        handleFSModal={handleFSModal}
        openFSModal={openFSModal}
        step={step}
        setStep={setStep}
        loading={loading}
        coachModalRef={coachModalRef}
        nextSessionId={nextSessionId()}
        loadingMsg={loadingMsg}
        errorMsg={errorMsg}
      />
    </>
  );
}

export default SessionDetails;
