import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

export interface HDCardTypes {
  sx?: object;
  children?: any;
  bgcolor?: string;
}

const HDCard = (props: HDCardTypes) => {
  return (
    <Card {...props}>
      <CardContent>{props.children}</CardContent>
    </Card>
  );
};

export default HDCard;
