import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Divider, Typography, CssBaseline, styled, useMediaQuery, useTheme } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import HDButton from '../../../hd-ui-kit/HDButton';
import { pageWrapper, titleUnderline } from './styles';
import { reactGAEvents } from '../../../commons/commonFunctions';
import Api from '../../../Api';

function MyCoachingSubscription() {
  let navigate = useNavigate();
  const theme = useTheme();
  const themeMode = theme.palette.mode;
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [subscriptionStatus, setSubscriptionStatus] = useState('');
  const [subscriptionStartDate, setSubscriptionStartDate] = useState('');
  const [subscriptionDateMonthsAdded, setSubscriptionDateMonthsAdded] = useState(0);
  let todaysDate = new Date();
  todaysDate.setHours(0, 0, 0, 0);
  const getSubscriptionStatus = async () => {
    try {
      const { success, response } = await Api.getSubscriptionStatus();
      if (success) {
        setSubscriptionStatus(response?.data?.status);
        setSubscriptionStartDate(response?.data?.start_date);
        let subDate = new Date(response?.data?.start_date);
        subDate.setHours(0, 0, 0, 0);
        subDate.setMonth(subDate.getMonth() + 3);
        setSubscriptionDateMonthsAdded(subDate.getTime());
      } else {
        setSubscriptionStatus('');
        setSubscriptionStartDate('');
      }
    } catch (err) {
      setSubscriptionStatus('');
      setSubscriptionStartDate('');
    }
  };

  useEffect(() => {
    getSubscriptionStatus();
  }, []);

  const StyledHeader = {
    fontFamily: 'Aventa',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '31px',
    lineHeight: '41px',
    color: themeMode === 'light' ? '#002136' : '#FFFFFF',
    marginBottom: '8px',
  };
  const StyledSubHeader = {
    fontFamily: 'Aventa',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.02em',
    color: themeMode === 'light' ? '#002136' : '#FFFFFF',
  };
  const fontFamily = { fontFamily: 'Aventa' };
  const fontColor = { color: themeMode === 'light' ? '#002136' : '#FFFFFF' };

  const StyledSubHeaderWithMargin = {
    fontFamily: 'Aventa',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.02em',
    color: themeMode === 'light' ? '#002136' : '#FFFFFF',
    marginBottom: '8px',
  };
  const StyledSubscriptionItemWrapper = styled(Box)(() => ({
    background: themeMode === 'light' ? '#F8F8F8' : '#002136',
    border: themeMode === 'light' ? '1px solid rgba(100, 106, 124, 0.2)' : 'none',
    borderRadius: '6px',
    padding: '27px 32px',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    marginBottom: '16px',
  }));

  const StyledSubscriptionName = styled(Box)(() => ({
    ...fontFamily,
    ...fontColor,
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '23px',
  }));

  const ArrowIconCompleted = styled(ArrowForwardIcon)(() => ({
    color: themeMode === 'light' ? '#002136' : '#fff',
    marginLeft: '30px',
  }));

  const StyledButtonWrapper = styled(Box)(() => ({
    ...fontFamily,
    ...fontColor,
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    display: 'flex',
    alignItems: 'center',
    marginTop: '0px',
  }));

  const ArrowIcon = styled(ArrowForwardIcon)(() => ({
    color: themeMode === 'light' ? '#66FCFC' : '#18768C',
  }));

  const handleClick = () => {
    reactGAEvents('my-coach-subscription', 'Learn about coaching');
    navigate('/my-coach');
  };

  const goToSubscriptionManagement = () => {
    if (process.env.REACT_APP_SUBSCRIPTION_MANAGEMENT_URL)
      window.open(process.env.REACT_APP_SUBSCRIPTION_MANAGEMENT_URL, '_blank', 'noopener,noreferrer');
  };

  return (
    <>
      <CssBaseline />
      <Box sx={pageWrapper(isMobile, themeMode)}>
        <Typography sx={StyledHeader}>My coaching subscription</Typography>
        {(subscriptionStatus === 'ACTIVE' || subscriptionStatus === 'NOT_ACTIVE') ? (
          <>
            {(subscriptionStatus === 'ACTIVE') ? (
              <>
                <Typography sx={StyledSubHeader}>Your Heidrick for Leaders Coaching subscription is active.</Typography>
                {/* <Typography sx={StyledSubHeaderWithMargin}> Your next billing date is May 24, 2023</Typography> */}
              </>
            ) : (
              <Typography sx={StyledSubHeader}>Your Heidrick for Leaders Coaching subscription is inactive.</Typography>
            )}
            <Divider sx={titleUnderline(themeMode)} />
            <Box sx={{ marginTop: '50px' }}>
              {(subscriptionStatus === 'ACTIVE') && (
                <StyledSubscriptionItemWrapper>
                  <StyledSubscriptionName>View Subscription Details</StyledSubscriptionName>
                  <StyledButtonWrapper>
                    <HDButton
                      text={<ArrowIconCompleted />}
                      sx={{ padding: 0 }}
                      onClick={() => {
                        reactGAEvents('my-coach-subscription', 'view-subscription-details');
                        goToSubscriptionManagement();
                      }}
                    />
                  </StyledButtonWrapper>
                </StyledSubscriptionItemWrapper>
              )}
              {/*<StyledSubscriptionItemWrapper>
                <StyledSubscriptionName>
                  {subscriptionStatus === 'ACTIVE' ? 'Update Subscription' : 'Update Payment'}
                </StyledSubscriptionName>
                <StyledButtonWrapper>
                  <HDButton
                    text={<ArrowIconCompleted />}
                    sx={{ padding: 0 }}
                    onClick={() => {
                      if (subscriptionStatus === 'ACTIVE')
                        reactGAEvents('my-coach-subscription', 'update-subscription');
                      else
                        reactGAEvents('my-coach-subscription', 'renew-subscription');
                      goToSubscriptionManagement();
                    }}
                  />
                </StyledButtonWrapper>
              </StyledSubscriptionItemWrapper>*/}
              {(subscriptionStatus === 'ACTIVE' && (subscriptionDateMonthsAdded > todaysDate.getTime())) && (
                <StyledSubscriptionItemWrapper>
                  <StyledSubscriptionName>Cancel Subscription</StyledSubscriptionName>
                  <StyledButtonWrapper>
                    <HDButton
                      text={<ArrowIconCompleted />}
                      sx={{ padding: 0 }}
                      onClick={() => {
                        reactGAEvents('my-coach-subscription', 'cancel-subscription');
                        window.open('https://heidricksupport.zendesk.com/hc/en-us', '_blank', 'noopener,noreferrer');
                      }}
                    />
                  </StyledButtonWrapper>
                </StyledSubscriptionItemWrapper>
              )}
              <StyledSubscriptionItemWrapper>
                <StyledSubscriptionName>Contact Support</StyledSubscriptionName>
                <StyledButtonWrapper>
                  <HDButton
                    text={<ArrowIconCompleted />}
                    sx={{ padding: 0 }}
                    onClick={() => {
                      reactGAEvents('my-coach-subscription', 'subscription-contact-support');
                      window.open('https://heidricksupport.zendesk.com/hc/en-us', '_blank', 'noopener,noreferrer');
                    }}
                  />
                </StyledButtonWrapper>
              </StyledSubscriptionItemWrapper>
            </Box>
          </>
        ) : (subscriptionStatus !== '') ? (
          <>
            {' '}
            <Typography sx={StyledSubHeaderWithMargin}>You are not currently subscribed to Heidrick Coaching</Typography>
            <Divider sx={titleUnderline(themeMode)} />
            <Box sx={{ marginTop: '30px' }}>
              <HDButton variant={'contained'} text={'Learn about coaching'} showAnimation={true} endIcon={<ArrowIcon />} onClick={() => handleClick()} />
            </Box>
          </>
        ) : (
          <></>
        )}
      </Box>
    </>
  );
}

export default MyCoachingSubscription;
