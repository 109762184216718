import * as React from 'react';
import {
  Breadcrumbs,
  Box,
  Link,
  styled,
  Typography,
  useTheme,
  useMediaQuery
} from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { useNavigate } from 'react-router-dom';

interface BreadCrumbType {
  text: string;
  href: string;
}

export interface HDBreadCrumbTypes {
  underline?: 'none' | 'hover' | 'always';
  routes: Array<BreadCrumbType>;
  handleClick?: any;
  gaTagEvents?: Array<() => void>;
}

const HDBreadCrumb = ({ underline = 'always', routes, gaTagEvents, handleClick }: HDBreadCrumbTypes): any | null => {
  const theme = useTheme();
  const isDark = theme.palette.mode === 'dark';
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  let navigate = useNavigate();
  const StyledBreadCrumbBox = styled(Box)(() => ({
    display: 'block',
  }));

  const StyledBackBox = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
  }));

  const StyledBackText = styled(Typography)(() => ({
    fontFamily: 'Avenir',
    fontWeight: 300,
    fontSize: 16,
    lineHeight: '24px',
    color: isDark ? '#ccc' : '#334255',
    marginTop: '1.5px',
    cursor: 'pointer',
  }));

  const StyledArrowLeftIcon = styled(KeyboardArrowLeftIcon)(() => ({
    width: 28,
    height: 28,
    color: isDark ? '#fff' : '#002136',
    marginRight: '-1px',
    cursor: 'pointer',
  }));

  if (isMobile) {
    return (
      <StyledBackBox>
        <StyledArrowLeftIcon />
        <Link underline='none'>
          <StyledBackText onClick={() => history.back()}>Back</StyledBackText>
        </Link>
      </StyledBackBox>
    );
  }

  const onItemClick = (el: any, index: any) => {
    if (handleClick) handleClick(el);
    if (gaTagEvents && gaTagEvents[index]) gaTagEvents[index]();
    navigate(el.href);
  };

  return (
    <StyledBreadCrumbBox>
      <Breadcrumbs aria-label='breadcrumb'>
        {routes?.map((el, index) => {
          if (index === (routes.length - 1)) {
            if (el.href === '#') {
              return (
                <div key={`bread_crumb_${el.text}`}>
                  {el.text}
                </div>
              );
            } else {
              return (
                <Link
                  sx={{ cursor: 'pointer' }}
                  key={`bread_crumb_${el.text}`}
                  underline='none'
                  color='inherit'
                  // href={el.href}
                  onClick={() => onItemClick(el, index)}
                >
                  {el.text}
                </Link>
              );
            }
          }

          if (el.href === '#') {
            return (
              <div key={`bread_crumb_${el.text}`}>
                {el.text}
              </div>
            );
          } else {
            return (
              <Link
                sx={{ cursor: 'pointer' }}
                key={`bread_crumb_${el.text}`}
                underline={underline}
                color='inherit'
                // href={el.href}
                onClick={() => onItemClick(el, index)}
              >
                {el.text}
              </Link>
            );
          }
        })}
      </Breadcrumbs>
    </StyledBreadCrumbBox>
  );
};

export default HDBreadCrumb;
