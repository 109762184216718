import React, { useState, useEffect, useContext } from 'react';
import { Box, Divider, Typography, useMediaQuery, useTheme } from '@mui/material';
import ReactGA from 'react-ga4';

import { headerStyle, pageWrapper, titleUnderline, infoBoxStyle } from './styles';
import HDDocUpload from '../../../hd-ui-kit/HDDocUpload';
import eventBus from '../../../Eventbus';
import Api from '../../../Api';
import { reactGAEvents } from '../../../commons/commonFunctions';
import { globalContext } from '../../../store';

function Resume(props: any) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const themeMode = theme.palette.mode;
  const [progress, setProgress] = useState(0);
  const [fileData, setFileData] = useState(null);
  const [data, setData] = useState<any>();
  const [error, setError] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [downBtnDisable, setDownBtnDisable] = useState(false);
  const { dispatch } = useContext(globalContext);


  const textFieldWrap = {
    marginTop: '60px',
  };

  const noteStyle = {
    fontFamily: 'Avenir',
    fontWeight: 300,
    fontSize: '16px',
    color: themeMode === 'light' ? '#334255' : '#CCC',
  };

  const onUploadProgress = (progressEvent: any) => {
    const { loaded, total } = progressEvent;
    let percent = Math.floor((loaded * 100) / total);
    setProgress(percent);
  };

  const getProfileAccountData = () => {
    Api.getAccount()
      .then((res) => {
        if (res.success) {
          let data = res.response.data;
          dispatch({ type: 'SET_ACCOUNT', payload: data });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChange = async (event: any) => {
    let file = event.target.files[0];
    let chkForTagsRegEx = new RegExp('^[a-zA-Z0-9_\\-_\\s.]*$');
    if (!file) {
      // file wans't uploaded
      return;
    }
    props.onSuccess(false);
    if (file.type !== 'application/pdf' && file.type !== 'text/plain' && file.type !== 'application/msword' && file.type !== 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' && file.type !== 'application/rtf') {
      eventBus.dispatch('createAlert', {
        message: 'the selected file for resume upload has incorrect format.',
        severity: 'error',
      });
      setError('Please upload correct format: PDF (preferred), DOCX, DOC, RTF, TXT');
      return;
    }

    if (file.size > 2097152) {
      eventBus.dispatch('createAlert', {
        message: 'the selected file for resume upload has file size is too big. Must be under 2mb',
        severity: 'error',
      });
      setError('File size is too big');
      return;
    }

    if (!chkForTagsRegEx.test(file.name)) {
      eventBus.dispatch('createAlert', {
        message: 'File name should not contain HTML Tags',
        severity: 'error',
      });
      setError('File name should not contain HTML Tags / Special Characters');
      return;
    }

    if (file.name?.length > 100) {
      eventBus.dispatch('createAlert', {
        message: 'File name should not contain more than 100 characters',
        severity: 'error',
      });
      setError('File name should not contain more than 100 characters');
      return;
    }

    event.preventDefault();
    if (fileData) {
      await handleDeleteResume();
    }

    const formData = new FormData();
    formData.append('resume_file', event.target.files[0]);
    await Api.saveResumeWithProgress(formData, onUploadProgress)
      .then((res) => {
        if (res.success) {
          const fileSize = `${res?.response?.data?.document_extension} ${getSize(res?.response?.data?.document_size)}`;
          setProgress(0);
          setFileData({
            ...res?.response?.data,
            fileName: res?.response?.data?.document_name,
            fileSize: fileSize,
          });
          setError(null);
          // navigate to the next form
          ReactGA.event({
            category: 'Profile Edit - Upload your resume',
            action: 'Add new',
          });
        } else {
          setFileData(null);
          setProgress(0);
          const { data } = res;
          setError(data.document_name[0]);
        }
        getProfileAccountData();
      })
      .catch(() => {
        setFileData(null);
        setProgress(0);
        setError(null);
      });
  };

  const onRemoveResumeHandler = async () => {
    ReactGA.event({
      category: 'Profile Edit - Resume',
      action: 'Delete Resume',
    });
    await handleDeleteResume();
  };

  const getSize = (fileSize: any) => {
    if (fileSize > 1024) return (fileSize / 1024).toFixed(2) + ' MB';
    return fileSize + ' KB';
  };

  useEffect(() => {
    if (props.data) {
      setData(props.data);
    }
  }, [props.data]);

  useEffect(() => {
    if (data) {
      const fileSize = `${data?.document_extension} ${getSize(data?.document_size)}`;
      const file = {
        ...data,
        fileName: data?.document_name,
        fileSize: fileSize,
      };
      setFileData(file);
    }
  }, [data]);

  const handleDeleteResume = async () => {
    if (fileData) {
      await Api.deleteResume(fileData.id)
        .then((res) => {
          if (res.success) {
            eventBus.dispatch('createAlert', {
              message: 'Your resume has been deleted.',
              severity: 'success',
            });
            setShowDeleteModal(false);
            setFileData(null);
            setError(null);
            ReactGA.event({
              category: 'Profile Edit - Upload your resume',
              action: 'Delete',
            });
          } else {
            eventBus.dispatch('createAlert', {
              message: 'Something went wrong when trying to delete your resume. Please try again.',
              severity: 'error',
            });
            setError(null);
            setShowDeleteModal(false);
          }
          getProfileAccountData();
        })
        .catch(() => {
          setError(null);
          setShowDeleteModal(false);
        });
    }
  };

  const onCloseDelete = () => {
    reactGAEvents('my-account', 'delete-no');
    setShowDeleteModal(false);
  };

  const onOpenDelete = () => {
    reactGAEvents('my-account', 'delete-yes');
    setShowDeleteModal(true);
  };

  const handleDownload = (id: any) => {
    setDownBtnDisable(true);
    ReactGA.event({
      category: 'Profile Edit - Upload your resume',
      action: 'Download',
    });
    Api.downloadResume(id)
      .then((res) => {
        if (res.success) setDownBtnDisable(false);
      })
      .catch((err) => {
        console.log(err);
        setDownBtnDisable(false);
      });
  };

  return (
    <Box sx={pageWrapper(isMobile, themeMode)}>
      <Typography sx={headerStyle(themeMode)}>Upload your resume</Typography>
      <Divider sx={titleUnderline(themeMode)} />

      <Box sx={textFieldWrap}>
        <Typography sx={noteStyle}>While it's helpful to know about your current role, your full resume gives us added clarity as we try to match you with opportunities. (optional)</Typography>

        <Box sx={infoBoxStyle(themeMode)}>
          <HDDocUpload
            dataTestId={'fileUpload'}
            showDeleteModal={showDeleteModal}
            onOpenDelete={() => onOpenDelete()}
            onCloseDelete={() => onCloseDelete()}
            handleFileUpload={(e: any) => {
              reactGAEvents('my-account', 'add-new-resume');
              handleChange(e);
            }}
            handleFileDelete={() => {
              reactGAEvents('my-account', 'delete');
              onRemoveResumeHandler();
            }}
            downBtnDisable={downBtnDisable}
            progress={progress}
            fileData={fileData}
            errorMsg={error}
            handleClickDownload={(id: any) => {
              reactGAEvents('my-account', 'download');
              handleDownload(id);
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default Resume;
