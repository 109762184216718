import React from 'react';
import { useTheme, useMediaQuery, Box } from '@mui/material';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { CalendarPicker, LocalizationProvider } from '@mui/x-date-pickers';
import {ResponsiveThemeValue} from '../../commons/commonFunctions';

export interface HDCalendarTypes {
  coachAvailableDates: Array<any>;
  onDateSelect: (arg0?: any) => void;
  sx?: Object;
  defaultMonthDate?: any;
  onCalendarMonthChange?: (arg0?: any) => void;
}

const HDCalendar = (props: HDCalendarTypes) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  const StyledCalender = {
    fontFamily: 'Aventa',
    background: ResponsiveThemeValue(theme,'#F8F8F8' , '#002136'),
    borderRadius: '6px',
    width: '100%',
    maxHeight: 'none',
    border: ResponsiveThemeValue(theme,'1px solid #E9EAEC' , 'none'),
    '.MuiCalendarPicker-root': {
      overflowX: 'unset',
      width: '100%',
      maxHeight: '100%',
    },
    '.MuiPickersCalendarHeader-root': {
      borderBottom: '1px solid ' + (ResponsiveThemeValue(theme,'#E9EAEC' , '#334D5E')),
      fontFamily: 'Aventa',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '23px',
      color: ResponsiveThemeValue(theme,'#0B0C10' , '#FFFFFF'),
      paddingBottom: '12px',
      marginLeft: '10px',
      marginRight: '10px',
      paddingLeft: '16px',
      paddingRight: '6px',
    },
    '.MuiDayPicker-slideTransition': {
      height: isMobile ? '200px' : isTablet ? '275px' : '100%',
      minHeight: 0,
    },
    '.MuiDayPicker-header': {
      width: '100%',
    },
    '.MuiDayPicker-monthContainer': {
      position: 'relative',
    },
    '.MuiDayPicker-weekDayLabel': {
      fontFamily: 'Aventa',
      fontWeight: 600,
      fontSize: '15px',
      lineHeight: '22px',
      textAlign: 'center',
      color:ResponsiveThemeValue(theme,'#0B0C10' , '#FFFFFF'),
      padding: '2%',
      width: 'inherit',
      height: 'auto',
    },
    '.MuiDayPicker-weekContainer': {
      width: '100%',
    },
    '.MuiPickersDay-root': {
      padding: '2%',
      fontFamily: 'Aventa',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '20px',
      textAlign: 'center',
      color: ResponsiveThemeValue(theme,'#0B0C10' , '#FFFFFF'),
      width: 'inherit',
      height: 'auto',
      borderRadius: 0,
      background: 'transparent',
    },
    '.MuiPickersDay-today': {
      color: ResponsiveThemeValue(theme,'#18768C' , '#66fcfc'),
      border: 'none !important',
    },
    '.MuiPickersDay-today:after': {
      width: '4px',
      height: '4px',
      background: ResponsiveThemeValue(theme,'#18768C' , '#2CDADA'),
      borderRadius: '20px',
      content: '""',
      position: 'absolute',
      bottom: isMobile ? '7px' : '10px',
    },
    '.MuiPickersDay-root:hover': {
      background: 'transparent',
    },
    '.MuiPickersDay-dayOutsideMonth': {
      color: ResponsiveThemeValue(theme,'#A9A9A9' , '#CCC'),
    },
    '.MuiDayPicker-weekContainer button:nth-last-child(2), .MuiDayPicker-weekContainer button:last-child': {
      color: ResponsiveThemeValue(theme,'#A9A9A9' , '#CCC'),
      //textDecorationLine: 'line-through',
    },
    '.Mui-disabled': {
      textDecorationLine: 'line-through',
    },
    '.MuiDayPicker-monthContainer button[tabindex="0"]': {
      background: ResponsiveThemeValue(theme,'#18768C' , '#2CDADA'),
      borderRadius: '10px',
      color: ResponsiveThemeValue(theme,'#FFFFFF' , '#0B0C10'),
    },
    '.MuiButtonBase-root.MuiPickersDay-root.Mui-selected': {
      background: ResponsiveThemeValue(theme,'#18768C' , '#2CDADA'),
      borderRadius: '10px',
      transition: 'unset !important'
    },
    '.PrivatePickersYear-yearButton.Mui-selected': {
      background: 'transparent',
      color: ResponsiveThemeValue(theme,'#18768C' , '#66fcfc'),
    },
    '.PrivatePickersYear-yearButton:hover': {
      background: 'transparent',
    },
    '.PrivatePickersYear-yearButton.Mui-selected:after': {
      width: '4px',
      height: '4px',
      background: ResponsiveThemeValue(theme,'#18768C' , '#66FCFC'),
      borderRadius: '20px',
      content: '""',
      position: 'relative',
      display: 'block',
      left: '48%',
    },
    '.PrivatePickersYear-root button[tabindex="0"]': {
      background: ResponsiveThemeValue(theme,'#18768C' , '#2CDADA'),
      borderRadius: '10px',
      color:ResponsiveThemeValue(theme,'#FFFFFF' ,'#0B0C10'),
    },
  };

  const getDisabledDates = (date: any) => {
    let d = new Date(date.ts),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    const dateToValidate = [year, month, day].join('-');
    return !props?.coachAvailableDates?.includes(dateToValidate);
  };

  const getMaxDate = () => {
    const arrDates: any = props?.coachAvailableDates?.map((str) => new Date(str));
    const maxDate: any = new Date(Math.max(...arrDates));
    return maxDate;
  };

  const getMinDate = () => {
    const arrDates: any = props?.coachAvailableDates?.map((str) => new Date(str));
    const minDate: any = new Date(Math.min(...arrDates));
    return minDate;
  };

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <Box sx={StyledCalender}>
        <CalendarPicker
          date={(props?.defaultMonthDate) ? props.defaultMonthDate : undefined}
          onChange={(date: any) => { props.onDateSelect(date); }}
          autoFocus={true}
          showDaysOutsideCurrentMonth
          shouldDisableDate={(date) => getDisabledDates(date)}
          disablePast
          maxDate={getMaxDate()}
          minDate={getMinDate()}
          onMonthChange={(date: any) => { props?.onCalendarMonthChange(date); }}
        />
      </Box>
    </LocalizationProvider>
  );
};

export default HDCalendar;
