import React, { useEffect } from 'react';
import { Box, Typography, Container, useMediaQuery, useTheme, CssBaseline } from '@mui/material';
import Header from '../../commons/Header';
import Footer from '../../commons/Footer';
import HDButton from '../../hd-ui-kit/HDButton';
import { useNavigate } from 'react-router';
import Api from '../../Api';
import { reactGAEvents, titleStyle, styledText, styledSignInBtn, styledBoxMainWrapper, styledDividerLeft } from '../../commons/commonFunctions';

const ForgetPasswordActivate = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const themeMode = theme.palette.mode;

  const MainStyledBox = {
    display: 'flex',
    flexDirection: 'column',
  };

  const StyledForgetWrapper = {
    textAlign: 'left',
    marginTop: '20px',
  };

  useEffect(() => {
    let isAuth = Api.isAuthorized();
    if (isAuth) navigate('/home');
  }, []);

  const handleSingIn = () => {
    reactGAEvents('reset-password-activated', 'return-to-sign-in-screen');
    navigate('/login');
  };

  return (
    <>
      <CssBaseline />
      <Header />
      <Box sx={styledBoxMainWrapper(isMobile, themeMode)}>
        <Container maxWidth="lg">
          <Box sx={styledDividerLeft(isMobile, themeMode)}>
            <Box>
              <Box sx={MainStyledBox}>
                <Typography variant="h2" sx={titleStyle(isMobile, themeMode)}>
                  A password reset link was sent
                </Typography>
                <Typography variant="h2" sx={styledText(themeMode)}>
                  You have requested a password reset. Please check your inbox for an email from Heidrick and follow the instructions to reset it.
                </Typography>
              </Box>
              <Box sx={StyledForgetWrapper}>
                <Box>
                  <Box>
                    <HDButton sx={styledSignInBtn(themeMode)} variant={'text'} text={'Return to sign-in screen'} onClick={() => handleSingIn()} />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
      <Footer />
    </>
  );
};

export default ForgetPasswordActivate;
