import { DateTime, IANAZone } from 'luxon';
import isEmpty from 'lodash/fp/isEmpty';

type TimeZoneOption = {
    label: string,
    value: string,
}
export const getTimeZoneOptions = (country: string): TimeZoneOption[] => {
    /** For a given country in countries.COUNTRIES, return a list of dropdown options
     *  for user to select a timezone
     *  [{"value": "America/New_York", "label": "(GMT-05:00) - America/New York", ...]
     */
    if (!country || isEmpty(country)) {
        return getTimeZoneOptions('Other');
    }
    const zoneIDs = TimezoneIDsForCountry[country];
    const options = [];
    for (let i = 0; i < zoneIDs.length; i++) {
        options.push({
            'label': formatTimezoneID(zoneIDs[i]),
            'value': zoneIDs[i]
        });
    }
    return options;
};


export const formatTimezoneID = (timezone: string): string => {
    /** User-friendly string for an IANA timezone ID
    /* "America/New_York" --> "(GMT-05:00) - America/New York"
    /* Note: offset could be -5 or -4 depending on the time of the year, this func will handle tha
     */
    const zoneName = timezone.replace('_', ' ');
    const now = DateTime.local().setZone(timezone);
    if (!now.isValid) {
        // unsupported zone, this can happen for older browsers like internet explorer
        // if this happens we will omit UTC offset info
        return zoneName;
    }
    const zone = IANAZone.create(timezone);
    const prefix = zone.formatOffset(now.toMillis(), 'short');
    return `(GMT ${prefix}) - ${zoneName}`;
}


export const TimezoneIDsForCountry: { [country: string]: string[] } = {
    'Albania': ['Europe/Tirane'],
    'Algeria': ['Africa/Algiers'],
    'Andorra': ['Europe/Andorra'],
    'Angola': ['Africa/Luanda'],
    'Antigua and Barbuda': ['America/Antigua'],
    'Argentina': ['America/Argentina/Buenos_Aires',
        'America/Argentina/Cordoba',
        'America/Argentina/Salta',
        'America/Argentina/Jujuy',
        'America/Argentina/Tucuman',
        'America/Argentina/Catamarca',
        'America/Argentina/La_Rioja',
        'America/Argentina/San_Juan',
        'America/Argentina/Mendoza',
        'America/Argentina/San_Luis',
        'America/Argentina/Rio_Gallegos',
        'America/Argentina/Ushuaia'],
    'Armenia': ['Asia/Yerevan'],
    'Australia': ['Australia/Perth',
        'Australia/Eucla',
        'Australia/Broken_Hill',
        'Australia/Adelaide',
        'Australia/Darwin',
        'Antarctica/Macquarie',
        'Australia/Hobart',
        'Australia/Melbourne',
        'Australia/Sydney',
        'Australia/Brisbane',
        'Australia/Lindeman',
        'Australia/Lord_Howe'],
    'Austria': ['Europe/Vienna'],
    'Azerbaijan': ['Asia/Baku'],
    'Bahamas': ['America/Nassau'],
    'Bahrain': ['Asia/Bahrain'],
    'Bangladesh': ['Asia/Dhaka'],
    'Barbados': ['America/Barbados'],
    'Belarus': ['Europe/Minsk'],
    'Belgium': ['Europe/Brussels'],
    'Belize': ['America/Belize'],
    'Benin': ['Africa/Porto-Novo'],
    'Bhutan': ['Asia/Thimphu'],
    'Bolivia': ['America/La_Paz'],
    'Bosnia and Herzegovina': ['Europe/Sarajevo'],
    'Botswana': ['Africa/Gaborone'],
    'Brazil': ['America/Eirunepe',
        'America/Rio_Branco',
        'America/Campo_Grande',
        'America/Cuiaba',
        'America/Porto_Velho',
        'America/Boa_Vista',
        'America/Manaus',
        'America/Belem',
        'America/Fortaleza',
        'America/Recife',
        'America/Araguaina',
        'America/Maceio',
        'America/Bahia',
        'America/Sao_Paulo',
        'America/Santarem',
        'America/Noronha'],
    'Brunei': ['Asia/Brunei'],
    'Bulgaria': ['Europe/Sofia'],
    'Burkina Faso': ['Africa/Ouagadougou'],
    'Burundi': ['Africa/Bujumbura'],
    'Cambodia': ['Asia/Phnom_Penh'],
    'Cameroon': ['Africa/Douala'],
    'Canada': ['America/Creston',
        'America/Dawson_Creek',
        'America/Fort_Nelson',
        'America/Whitehorse',
        'America/Dawson',
        'America/Vancouver',
        'America/Regina',
        'America/Swift_Current',
        'America/Edmonton',
        'America/Cambridge_Bay',
        'America/Yellowknife',
        'America/Inuvik',
        'America/Atikokan',
        'America/Winnipeg',
        'America/Resolute',
        'America/Rankin_Inlet',
        'America/Blanc-Sablon',
        'America/Toronto',
        'America/Iqaluit',
        'America/Halifax',
        'America/Glace_Bay',
        'America/Moncton',
        'America/Goose_Bay',
        'America/St_Johns'],
    'Cape Verde': ['Atlantic/Cape_Verde'],
    'Central African Republic': ['Africa/Bangui'],
    'Chad': ['Africa/Ndjamena'],
    'Chile': ['Pacific/Easter', 'America/Santiago', 'America/Punta_Arenas'],
    'China': ['Asia/Urumqi', 'Asia/Shanghai'],
    'Colombia': ['America/Bogota'],
    'Comoros': ['Indian/Comoro'],
    'Congo (Brazzaville)': ['Africa/Brazzaville'],
    'Congo (Kinshasa)': ['Africa/Kinshasa', 'Africa/Lubumbashi'],
    'Costa Rica': ['America/Costa_Rica'],
    "Cote d'Ivoire": ['Africa/Abidjan'],
    'Croatia': ['Europe/Zagreb'],
    'Cuba': ['America/Havana'],
    'Cyprus': ['Asia/Nicosia', 'Asia/Famagusta'],
    'Czech Republic': ['Europe/Prague'],
    'Denmark': ['Europe/Copenhagen'],
    'Djibouti': ['Africa/Djibouti'],
    'Dominica': ['America/Dominica'],
    'Dominican Republic': ['America/Santo_Domingo'],
    'Ecuador': ['Pacific/Galapagos', 'America/Guayaquil'],
    'Egypt': ['Africa/Cairo'],
    'El Salvador': ['America/El_Salvador'],
    'Equatorial Guinea': ['Africa/Malabo'],
    'Eritrea': ['Africa/Asmara'],
    'Estonia': ['Europe/Tallinn'],
    'Eswatini': ['Africa/Mbabane'],
    'Ethiopia': ['Africa/Addis_Ababa'],
    'Fiji': ['Pacific/Fiji'],
    'Finland': ['Europe/Helsinki'],
    'France': ['Europe/Paris'],
    'Gabon': ['Africa/Libreville'],
    'Gambia': ['Africa/Banjul'],
    'Georgia': ['Asia/Tbilisi'],
    'Germany': ['Europe/Berlin', 'Europe/Busingen'],
    'Ghana': ['Africa/Accra'],
    'Greece': ['Europe/Athens'],
    'Grenada': ['America/Grenada'],
    'Guatemala': ['America/Guatemala'],
    'Guinea': ['Africa/Conakry'],
    'Guinea-Bissau': ['Africa/Bissau'],
    'Guyana': ['America/Guyana'],
    'Haiti': ['America/Port-au-Prince'],
    'Holy See': ['Europe/Vatican'],
    'Honduras': ['America/Tegucigalpa'],
    'Hong Kong': ['Asia/Hong_Kong'],
    'Hungary': ['Europe/Budapest'],
    'Iceland': ['Atlantic/Reykjavik'],
    'India': ['Asia/Kolkata'],
    'Indonesia': ['Asia/Jakarta',
        'Asia/Pontianak',
        'Asia/Makassar',
        'Asia/Jayapura'],
    'Iran': ['Asia/Tehran'],
    'Iraq': ['Asia/Baghdad'],
    'Ireland': ['Europe/Dublin'],
    'Israel': ['Asia/Jerusalem'],
    'Italy': ['Europe/Rome'],
    'Jamaica': ['America/Jamaica'],
    'Japan': ['Asia/Tokyo'],
    'Jordan': ['Asia/Amman'],
    'Kazakhstan': ['Asia/Qyzylorda',
        'Asia/Aqtobe',
        'Asia/Aqtau',
        'Asia/Atyrau',
        'Asia/Oral',
        'Asia/Almaty',
        'Asia/Qostanay'],
    'Kenya': ['Africa/Nairobi'],
    'Kiribati': ['Pacific/Tarawa', 'Pacific/Kanton', 'Pacific/Kiritimati'],
    'Kuwait': ['Asia/Kuwait'],
    'Kyrgyzstan': ['Asia/Bishkek'],
    'Laos': ['Asia/Vientiane'],
    'Latvia': ['Europe/Riga'],
    'Lebanon': ['Asia/Beirut'],
    'Lesotho': ['Africa/Maseru'],
    'Liberia': ['Africa/Monrovia'],
    'Libya': ['Africa/Tripoli'],
    'Liechtenstein': ['Europe/Vaduz'],
    'Lithuania': ['Europe/Vilnius'],
    'Luxembourg': ['Europe/Luxembourg'],
    'Macau': ['Asia/Macau'],
    'Madagascar': ['Indian/Antananarivo'],
    'Malawi': ['Africa/Blantyre'],
    'Malaysia': ['Asia/Kuala_Lumpur', 'Asia/Kuching'],
    'Maldives': ['Indian/Maldives'],
    'Mali': ['Africa/Bamako'],
    'Malta': ['Europe/Malta'],
    'Marshall Islands': ['Pacific/Majuro', 'Pacific/Kwajalein'],
    'Mauritania': ['Africa/Nouakchott'],
    'Mauritius': ['Indian/Mauritius'],
    'Mexico': ['America/Mazatlan',
        'America/Hermosillo',
        'America/Tijuana',
        'America/Mexico_City',
        'America/Merida',
        'America/Monterrey',
        'America/Chihuahua',
        'America/Ciudad_Juarez',
        'America/Bahia_Banderas',
        'America/Cancun',
        'America/Matamoros',
        'America/Ojinaga'],
    'Micronesia': ['Pacific/Chuuk', 'Pacific/Pohnpei', 'Pacific/Kosrae'],
    'Moldova': ['Europe/Chisinau'],
    'Monaco': ['Europe/Monaco'],
    'Mongolia': ['Asia/Hovd', 'Asia/Ulaanbaatar', 'Asia/Choibalsan'],
    'Montenegro': ['Europe/Podgorica'],
    'Morocco': ['Africa/Casablanca'],
    'Mozambique': ['Africa/Maputo'],
    'Myanmar': ['Asia/Yangon'],
    'Namibia': ['Africa/Windhoek'],
    'Nauru': ['Pacific/Nauru'],
    'Nepal': ['Asia/Kathmandu'],
    'Netherlands': ['Europe/Amsterdam'],
    'New Zealand': ['Pacific/Auckland', 'Pacific/Chatham'],
    'Nicaragua': ['America/Managua'],
    'Niger': ['Africa/Niamey'],
    'Nigeria': ['Africa/Lagos'],
    'North Macedonia': ['Europe/Skopje'],
    'Norway': ['Europe/Oslo'],
    'Oman': ['Asia/Muscat'],
    'Other': ['Pacific/Niue',
        'Pacific/Pago_Pago',
        'Pacific/Honolulu',
        'Pacific/Rarotonga',
        'Pacific/Tahiti',
        'Pacific/Marquesas',
        'America/Adak',
        'Pacific/Gambier',
        'America/Anchorage',
        'America/Juneau',
        'America/Metlakatla',
        'America/Nome',
        'America/Sitka',
        'America/Yakutat',
        'Pacific/Pitcairn',
        'America/Creston',
        'America/Dawson',
        'America/Dawson_Creek',
        'America/Fort_Nelson',
        'America/Hermosillo',
        'America/Los_Angeles',
        'America/Mazatlan',
        'America/Phoenix',
        'America/Tijuana',
        'America/Vancouver',
        'America/Whitehorse',
        'America/Bahia_Banderas',
        'America/Belize',
        'America/Boise',
        'America/Cambridge_Bay',
        'America/Chihuahua',
        'America/Costa_Rica',
        'America/Denver',
        'America/Edmonton',
        'America/El_Salvador',
        'America/Guatemala',
        'America/Inuvik',
        'America/Managua',
        'America/Merida',
        'America/Mexico_City',
        'America/Monterrey',
        'America/Regina',
        'America/Swift_Current',
        'America/Tegucigalpa',
        'America/Yellowknife',
        'Pacific/Easter',
        'Pacific/Galapagos',
        'America/Atikokan',
        'America/Bogota',
        'America/Cancun',
        'America/Chicago',
        'America/Eirunepe',
        'America/Guayaquil',
        'America/Indiana/Knox',
        'America/Indiana/Tell_City',
        'America/Jamaica',
        'America/Lima',
        'America/Matamoros',
        'America/Menominee',
        'America/North_Dakota/Beulah',
        'America/North_Dakota/Center',
        'America/North_Dakota/New_Salem',
        'America/Ojinaga',
        'America/Panama',
        'America/Rainy_River',
        'America/Rankin_Inlet',
        'America/Resolute',
        'America/Rio_Branco',
        'America/Winnipeg',
        'America/Asuncion',
        'America/Barbados',
        'America/Blanc-Sablon',
        'America/Boa_Vista',
        'America/Campo_Grande',
        'America/Caracas',
        'America/Cuiaba',
        'America/Curacao',
        'America/Detroit',
        'America/Grand_Turk',
        'America/Guyana',
        'America/Havana',
        'America/Indiana/Indianapolis',
        'America/Indiana/Marengo',
        'America/Indiana/Petersburg',
        'America/Indiana/Vevay',
        'America/Indiana/Vincennes',
        'America/Indiana/Winamac',
        'America/Iqaluit',
        'America/La_Paz',
        'America/Manaus',
        'America/Martinique',
        'America/Nassau',
        'America/New_York',
        'America/Nipigon',
        'America/Pangnirtung',
        'America/Port-au-Prince',
        'America/Port_of_Spain',
        'America/Porto_Velho',
        'America/Puerto_Rico',
        'America/Santiago',
        'America/Santo_Domingo',
        'America/Thunder_Bay',
        'America/Toronto',
        'America/Araguaina',
        'America/Argentina/Buenos_Aires',
        'America/Argentina/Catamarca',
        'America/Argentina/Cordoba',
        'America/Argentina/Jujuy',
        'America/Argentina/La_Rioja',
        'America/Argentina/Mendoza',
        'America/Argentina/Rio_Gallegos',
        'America/Argentina/Salta',
        'America/Argentina/San_Juan',
        'America/Argentina/San_Luis',
        'America/Argentina/Tucuman',
        'America/Argentina/Ushuaia',
        'America/Bahia',
        'America/Belem',
        'America/Cayenne',
        'America/Fortaleza',
        'America/Glace_Bay',
        'America/Goose_Bay',
        'America/Halifax',
        'America/Maceio',
        'America/Moncton',
        'America/Montevideo',
        'America/Paramaribo',
        'America/Punta_Arenas',
        'America/Recife',
        'America/Santarem',
        'America/Sao_Paulo',
        'America/Thule',
        'Antarctica/Palmer',
        'Antarctica/Rothera',
        'Atlantic/Bermuda',
        'Atlantic/Stanley',
        'America/St_Johns',
        'America/Miquelon',
        'America/Noronha',
        'America/Nuuk',
        'Atlantic/South_Georgia',
        'Atlantic/Cape_Verde',
        'Africa/Abidjan',
        'Africa/Accra',
        'Africa/Bissau',
        'Africa/Monrovia',
        'Africa/Sao_Tome',
        'America/Danmarkshavn',
        'America/Scoresbysund',
        'Atlantic/Azores',
        'Atlantic/Reykjavik',
        'Africa/Algiers',
        'Africa/Casablanca',
        'Africa/El_Aaiun',
        'Africa/Lagos',
        'Africa/Ndjamena',
        'Africa/Tunis',
        'Atlantic/Canary',
        'Atlantic/Faroe',
        'Atlantic/Madeira',
        'Europe/Dublin',
        'Europe/Lisbon',
        'Europe/London',
        'Africa/Cairo',
        'Africa/Ceuta',
        'Africa/Johannesburg',
        'Africa/Juba',
        'Africa/Khartoum',
        'Africa/Maputo',
        'Africa/Tripoli',
        'Africa/Windhoek',
        'Antarctica/Troll',
        'Europe/Amsterdam',
        'Europe/Andorra',
        'Europe/Belgrade',
        'Europe/Berlin',
        'Europe/Brussels',
        'Europe/Budapest',
        'Europe/Copenhagen',
        'Europe/Gibraltar',
        'Europe/Kaliningrad',
        'Europe/Luxembourg',
        'Europe/Madrid',
        'Europe/Malta',
        'Europe/Monaco',
        'Europe/Oslo',
        'Europe/Paris',
        'Europe/Prague',
        'Europe/Rome',
        'Europe/Stockholm',
        'Europe/Tirane',
        'Europe/Vienna',
        'Europe/Warsaw',
        'Europe/Zurich',
        'Africa/Nairobi',
        'Antarctica/Syowa',
        'Asia/Amman',
        'Asia/Baghdad',
        'Asia/Beirut',
        'Asia/Damascus',
        'Asia/Famagusta',
        'Asia/Gaza',
        'Asia/Hebron',
        'Asia/Jerusalem',
        'Asia/Nicosia',
        'Asia/Qatar',
        'Asia/Riyadh',
        'Europe/Athens',
        'Europe/Bucharest',
        'Europe/Chisinau',
        'Europe/Helsinki',
        'Europe/Istanbul',
        'Europe/Kiev',
        'Europe/Kirov',
        'Europe/Minsk',
        'Europe/Moscow',
        'Europe/Riga',
        'Europe/Simferopol',
        'Europe/Sofia',
        'Europe/Tallinn',
        'Europe/Uzhgorod',
        'Europe/Vilnius',
        'Europe/Volgograd',
        'Europe/Zaporozhye',
        'Asia/Tehran',
        'Asia/Baku',
        'Asia/Dubai',
        'Asia/Tbilisi',
        'Asia/Yerevan',
        'Europe/Astrakhan',
        'Europe/Samara',
        'Europe/Saratov',
        'Europe/Ulyanovsk',
        'Indian/Mahe',
        'Indian/Mauritius',
        'Indian/Reunion',
        'Asia/Kabul',
        'Antarctica/Mawson',
        'Asia/Aqtau',
        'Asia/Aqtobe',
        'Asia/Ashgabat',
        'Asia/Atyrau',
        'Asia/Dushanbe',
        'Asia/Karachi',
        'Asia/Oral',
        'Asia/Qyzylorda',
        'Asia/Samarkand',
        'Asia/Tashkent',
        'Asia/Yekaterinburg',
        'Indian/Kerguelen',
        'Indian/Maldives',
        'Asia/Colombo',
        'Asia/Kolkata',
        'Asia/Kathmandu',
        'Antarctica/Vostok',
        'Asia/Almaty',
        'Asia/Bishkek',
        'Asia/Dhaka',
        'Asia/Omsk',
        'Asia/Qostanay',
        'Asia/Thimphu',
        'Asia/Urumqi',
        'Indian/Chagos',
        'Asia/Yangon',
        'Indian/Cocos',
        'Antarctica/Davis',
        'Asia/Bangkok',
        'Asia/Barnaul',
        'Asia/Ho_Chi_Minh',
        'Asia/Hovd',
        'Asia/Jakarta',
        'Asia/Krasnoyarsk',
        'Asia/Novokuznetsk',
        'Asia/Novosibirsk',
        'Asia/Pontianak',
        'Asia/Tomsk',
        'Indian/Christmas',
        'Asia/Brunei',
        'Asia/Choibalsan',
        'Asia/Hong_Kong',
        'Asia/Irkutsk',
        'Asia/Kuala_Lumpur',
        'Asia/Kuching',
        'Asia/Macau',
        'Asia/Makassar',
        'Asia/Manila',
        'Asia/Shanghai',
        'Asia/Singapore',
        'Asia/Taipei',
        'Asia/Ulaanbaatar',
        'Australia/Perth',
        'Australia/Eucla',
        'Asia/Chita',
        'Asia/Dili',
        'Asia/Jayapura',
        'Asia/Khandyga',
        'Asia/Pyongyang',
        'Asia/Seoul',
        'Asia/Tokyo',
        'Asia/Yakutsk',
        'Pacific/Palau',
        'Australia/Adelaide',
        'Australia/Broken_Hill',
        'Australia/Darwin',
        'Antarctica/Macquarie',
        'Asia/Ust-Nera',
        'Asia/Vladivostok',
        'Australia/Brisbane',
        'Australia/Hobart',
        'Australia/Lindeman',
        'Australia/Melbourne',
        'Australia/Sydney',
        'Pacific/Chuuk',
        'Pacific/Guam',
        'Pacific/Port_Moresby',
        'Australia/Lord_Howe',
        'Antarctica/Casey',
        'Asia/Magadan',
        'Asia/Sakhalin',
        'Asia/Srednekolymsk',
        'Pacific/Bougainville',
        'Pacific/Efate',
        'Pacific/Guadalcanal',
        'Pacific/Kosrae',
        'Pacific/Norfolk',
        'Pacific/Noumea',
        'Pacific/Pohnpei',
        'Asia/Anadyr',
        'Asia/Kamchatka',
        'Pacific/Auckland',
        'Pacific/Fiji',
        'Pacific/Funafuti',
        'Pacific/Kwajalein',
        'Pacific/Majuro',
        'Pacific/Nauru',
        'Pacific/Tarawa',
        'Pacific/Wake',
        'Pacific/Wallis',
        'Pacific/Chatham',
        'Pacific/Apia',
        'Pacific/Enderbury',
        'Pacific/Fakaofo',
        'Pacific/Tongatapu',
        'Pacific/Kiritimati'],
    'Pakistan': ['Asia/Karachi'],
    'Palau': ['Pacific/Palau'],
    'Palestine State': ['Asia/Gaza', 'Asia/Hebron'],
    'Panama': ['America/Panama'],
    'Papua New Guinea': ['Pacific/Port_Moresby', 'Pacific/Bougainville'],
    'Paraguay': ['America/Asuncion'],
    'Peru': ['America/Lima'],
    'Philippines': ['Asia/Manila'],
    'Poland': ['Europe/Warsaw'],
    'Portugal': ['Atlantic/Azores', 'Europe/Lisbon', 'Atlantic/Madeira'],
    'Qatar': ['Asia/Qatar'],
    'Romania': ['Europe/Bucharest'],
    'Russia': ['Europe/Kaliningrad',
        'Europe/Moscow',
        'Europe/Kirov',
        'Europe/Volgograd',
        'Europe/Astrakhan',
        'Europe/Saratov',
        'Europe/Ulyanovsk',
        'Europe/Samara',
        'Asia/Yekaterinburg',
        'Asia/Omsk',
        'Asia/Novosibirsk',
        'Asia/Barnaul',
        'Asia/Tomsk',
        'Asia/Novokuznetsk',
        'Asia/Krasnoyarsk',
        'Asia/Irkutsk',
        'Asia/Chita',
        'Asia/Yakutsk',
        'Asia/Khandyga',
        'Asia/Vladivostok',
        'Asia/Ust-Nera',
        'Asia/Magadan',
        'Asia/Sakhalin',
        'Asia/Srednekolymsk',
        'Asia/Kamchatka',
        'Asia/Anadyr'],
    'Rwanda': ['Africa/Kigali'],
    'Saint Kitts and Nevis': ['America/St_Kitts'],
    'Saint Lucia': ['America/St_Lucia'],
    'Saint Vincent and the Grenadines': ['America/St_Vincent'],
    'Samoa': ['Pacific/Pago_Pago'],
    'San Marino': ['Europe/San_Marino'],
    'Sao Tome and Principe': ['Africa/Sao_Tome'],
    'Saudi Arabia': ['Asia/Riyadh'],
    'Senegal': ['Africa/Dakar'],
    'Serbia': ['Europe/Belgrade'],
    'Seychelles': ['Indian/Mahe'],
    'Sierra Leone': ['Africa/Freetown'],
    'Singapore': ['Asia/Singapore'],
    'Slovakia': ['Europe/Bratislava'],
    'Slovenia': ['Europe/Ljubljana'],
    'Solomon Islands': ['Pacific/Guadalcanal'],
    'Somalia': ['Africa/Mogadishu'],
    'South Africa': ['Africa/Johannesburg'],
    'South Korea': ['Asia/Seoul'],
    'Spain': ['Atlantic/Canary', 'Europe/Madrid', 'Africa/Ceuta'],
    'Sri Lanka': ['Asia/Colombo'],
    'Sudan': ['Africa/Khartoum'],
    'Suriname': ['America/Paramaribo'],
    'Sweden': ['Europe/Stockholm'],
    'Switzerland': ['Europe/Zurich'],
    'Syria': ['Asia/Damascus'],
    'Taiwan': ['Asia/Taipei'],
    'Tajikistan': ['Asia/Dushanbe'],
    'Tanzania': ['Africa/Dar_es_Salaam'],
    'Thailand': ['Asia/Bangkok'],
    'Timor-Leste': ['Asia/Dili'],
    'Togo': ['Africa/Lome'],
    'Tonga': ['Pacific/Tongatapu'],
    'Trinidad and Tobago': ['America/Port_of_Spain'],
    'Tunisia': ['Africa/Tunis'],
    'Turkey': ['Europe/Istanbul'],
    'Turkmenistan': ['Asia/Ashgabat'],
    'Tuvalu': ['Pacific/Funafuti'],
    'Uganda': ['Africa/Kampala'],
    'Ukraine': ['Europe/Simferopol', 'Europe/Kyiv'],
    'United Arab Emirates': ['Asia/Dubai'],
    'United Kingdom': ['Europe/London'],
    'United States of America': ['Pacific/Honolulu',
        'America/Adak',
        'America/Anchorage',
        'America/Juneau',
        'America/Sitka',
        'America/Metlakatla',
        'America/Yakutat',
        'America/Nome',
        'America/Phoenix',
        'America/Los_Angeles',
        'America/Denver',
        'America/Boise',
        'America/Chicago',
        'America/Indiana/Tell_City',
        'America/Indiana/Knox',
        'America/Menominee',
        'America/North_Dakota/Center',
        'America/North_Dakota/New_Salem',
        'America/North_Dakota/Beulah',
        'America/New_York',
        'America/Detroit',
        'America/Kentucky/Louisville',
        'America/Kentucky/Monticello',
        'America/Indiana/Indianapolis',
        'America/Indiana/Vincennes',
        'America/Indiana/Winamac',
        'America/Indiana/Marengo',
        'America/Indiana/Petersburg',
        'America/Indiana/Vevay'],
    'Uruguay': ['America/Montevideo'],
    'Uzbekistan': ['Asia/Samarkand', 'Asia/Tashkent'],
    'Vanuatu': ['Pacific/Efate'],
    'Venezuela': ['America/Caracas'],
    'Vietnam': ['Asia/Ho_Chi_Minh'],
    'Yemen': ['Asia/Aden'],
    'Zambia': ['Africa/Lusaka'],
    'Zimbabwe': ['Africa/Harare']
}
