import React, { useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import Api from '../../Api';
import { Box, Typography, Container, useMediaQuery, useTheme, CssBaseline } from '@mui/material';
import Header from '../../commons/Header';
import Footer from '../../commons/Footer';

function EmailChangeActivation() {
  let [params, SetParams] = useSearchParams();
  let navigate = useNavigate();
  let code = params.get('code'); // code from email for email activation
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    // send off code to verify
    Api.confirmNewEmail(code)
      .then((res) => {
        navigate('/login'); // should login again with new email
      })
      .catch((err) => {
        navigate('/home');
      });
  }, []); // <-- empty array means 'run once'

  const TitleStyle = {
    fontFamily: 'Aventa',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: isMobile ? '31px' : '41px',
    lineHeight: isMobile ? '41px' : '54px',
    color: theme.palette.mode == 'light' ? '#002136' : '#FFFFFF',
    textAlign: 'left',
  };

  const MainStyledBox = {
    display: 'flex',
    flexDirection: 'column',
  };

  const StyledDividerLeft = {
    borderLeft: isMobile ? ' ' : theme.palette.mode == 'light' ? '1px solid rgba(0, 33, 54, 0.2)' : '1px solid  rgba(255, 255, 255, 0.2)',
    paddingLeft: isMobile ? ' ' : '40px',
    marginTop: isMobile ? '40px' : '140px',
    width: isMobile ? '100%' : '50%',
  };

  const StyledBoxMainWrapper = {
    background: theme.palette.mode == 'light' ? (isMobile ? 'url(res/SignInBGLightMobile.svg) no-repeat top' : 'url(res/SignInBGLight.svg) no-repeat top') : isMobile ? 'url(res/SignInBGDarkMobile.svg) no-repeat top' : 'url(res/SignInBGDark.svg) no-repeat top',
    backgroundPosition: isMobile ? 'right bottom' : 'right',
    height: isMobile ? '510px' : '570px',
  };

  return (
    <>
      <CssBaseline />
      <Header />
      <Box sx={StyledBoxMainWrapper}>
        <Container maxWidth="lg">
          <Box sx={StyledDividerLeft}>
            <Box>
              <Box sx={MainStyledBox}>
                <Typography variant="h2" sx={TitleStyle}>
                  Activating New Email
                </Typography>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
      <Footer />
    </>
  );
}

export default EmailChangeActivation;
