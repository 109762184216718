import * as React from 'react';
import { Box, Typography, Tabs, Tab, Avatar, useTheme } from '@mui/material';

interface TabProps {
  label: string;
  avatar: string;
  coachId: any;
}

export interface HDCoachTabsTypes {
  tabs: Array<TabProps>;
  data: any;
  selectedTab: number;
  handleChange?: (event: React.SyntheticEvent, newValue: number) => void;
}

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  const StyledTabPadding = { padding: '45px 0px 0px 0px' };

  return (
    <div role="tabpanel" hidden={value !== index} id={`full-width-tabpanel-${index}`} aria-labelledby={`full-width-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={StyledTabPadding}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

function HDCoachTabs(props: HDCoachTabsTypes) {
  const { tabs, data, selectedTab } = props;
  const theme = useTheme();
  const isDark = theme.palette.mode === 'dark';
  const [value, setTabSelected] = React.useState(selectedTab);


  React.useEffect(() => {
    setTabSelected(selectedTab);
  }, [selectedTab]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabSelected(newValue);
    props?.handleChange(event, newValue);
  };

  const cxTabContainer = {
    display: 'block',
  };

  const cxTabs = {
    '.MuiTabs-flexContainer': {
      backgroundColor: 'transparent',
    },
    '.MuiTabs-indicator': {
      left: 0,
    },
    '& .MuiTab-root.Mui-selected': {
      color: isDark ? '#fff' : '#18768C',
    },
    backgroundColor: isDark ? '#0B0C10' : '#fff',
    borderBottom: '1px solid ' + (isDark ? '#3C3D40' : '#E0E1E5'),
  };

  const tabIndicatorProps = {
    style: {
      backgroundColor: isDark ? '#2CDADA' : '#18768C',
      marginTop: '12px',
    },
  };

  const cxTab = {
    fontFamily: 'Avenir',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '16px',
    lineHeight: '24px',
    color: isDark ? '#A9A9A9' : '#334255',
    textTransform: 'none',

    '&.Mui-selected': {
      color: isDark ? '#fff' : '#0B0C10 !important',
    },
  };

  const cxAvatar = {
    width: '80px',
    height: '80px',
  };

  return (
    <Box sx={cxTabContainer}>
      <Tabs value={value} onChange={handleChange} indicatorColor="secondary" sx={cxTabs} variant="fullWidth" aria-label="coach overlay tabs" TabIndicatorProps={tabIndicatorProps}>
        {tabs && tabs.map((tab, idx) => (
          <Tab label={tab.label} key={`coach_${tab.coachId}`} icon={<Avatar src={tab.avatar} sx={cxAvatar} />} {...a11yProps(idx)} sx={cxTab} />
       ))}
      </Tabs>
      <div>
        {data?.map((panel: any, idx: any) => (
          <TabPanel value={value} index={idx} dir={theme.direction} key={`coach_${panel.key}`}>
            {panel}
          </TabPanel>
        ))}
      </div>
    </Box>
  );
}

export default HDCoachTabs;
