import React from 'react';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Box } from '@mui/system';
import {
  MenuItem,
  MenuList,
  useTheme,
  useMediaQuery,
  styled,
  Container
} from '@mui/material';
import HDFooterMobile from './HDFooterMobile';
import { GenerateFooterSocialIcons } from '../../commons/commonFunctions';

export interface HDFooterTypes {
  logoUrls: {
    twitterUrl: string,
    linkedInUrl: string,
    youtubeUrl: string,
    mainLogo: string,
  };
  footerItems: Array<{ url: string, itemName: string }>;
  logoClick: () => void;
  // eslint-disable-next-line
  onNavigationClick: (arg: string) => void;
}

function HDFooter(props: HDFooterTypes) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const urls = { linkedInUrl: props?.logoUrls?.linkedInUrl, youtubeUrl: props?.logoUrls?.youtubeUrl, twitterUrl: props?.logoUrls?.twitterUrl };

  const StyledBoxMainWrapper = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#334255',
    height: '66px',
    width: '100%',
  }));

  const StyledBoxWrapper = styled(Box)(() => ({
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    height: '66px',
  }));

  const StyledBoxMenuWrapper = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'center',
  }));

  const StyledBoxMenuListWrapper = styled(Box)(() => ({
    display: 'flex',
    marginTop: '3px',
  }));

  const StyledMenuList = styled(MenuList)(() => ({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  }));

  const StyledMenuItem = styled(MenuItem)(() => ({
    color: '#CCCCCC',
    fontFamily: 'Avenir',
    marginLeft: '0px',
    right: '20px',
    fontWeight: '300',
    fontSize: '16px',
    background: '#334255',
    borderLeft: '1px solid rgba(255,255,255, 0.2)',
    padding: '0 24px',
    lineHeight: '14px',
    ':first-child': {
      marginLeft: '32px',
    },
    ':hover': {
      background: 'transparent'
    }
  }));

  const StyledImage = styled('img')(() => ({
    marginTop: '26px',
    marginRight: '20px',
  }));

  const onFooterNavClick = (url: string) => {
    props.onNavigationClick(url);
  };

  return (
    <>
      {isMobile ? (
        <HDFooterMobile {...props} />
      ) : (
        <StyledBoxMainWrapper>
          <Container>
            <StyledBoxWrapper>
              <StyledBoxMenuWrapper>
                <Box>
                  <a href={props.logoUrls.mainLogo} onClick={() => props.logoClick()} target='_blank'>
                    <StyledImage src={window.location.origin + '/res/hsn-word-logo-new.svg'} alt='Heidrick & Struggles word logo' />
                  </a>
                </Box>
                <StyledBoxMenuListWrapper>
                  <StyledMenuList>
                    {props.footerItems.map((element: any, index: number) => {
                      return (
                        <StyledMenuItem key={`footer_${element.itemName}`} onClick={() => onFooterNavClick(element.url)}>
                          <a style={{ color: 'inherit', textDecoration: 'none' }} href='javascript:void(0)' key={`footer_${element.itemName}`}>
                            {element.itemName}
                          </a>
                        </StyledMenuItem>
                      );
                    })}
                  </StyledMenuList>
                </StyledBoxMenuListWrapper>
              </StyledBoxMenuWrapper>
              <GenerateFooterSocialIcons
                LinkedInIcon={LinkedInIcon}
                YouTubeIcon={YouTubeIcon}
                theme={theme}
                urls={urls}
              />
            </StyledBoxWrapper>
          </Container>
        </StyledBoxMainWrapper>
      )}
    </>
  );
}

export default HDFooter;
