import React, { useEffect, useState, useContext, useRef } from 'react';
import { Container, useMediaQuery, useTheme, CssBaseline } from '@mui/material';
import { useLocation, useNavigate } from 'react-router';

import Header from '../../commons/Header';
import Footer from '../../commons/Footer';
import Loader from '../../commons/Loader';
import Api from '../../Api';
import HDProfileNav from '../../hd-ui-kit/HDProfileNav';

import AccountDetails from './form-component/AccountDetails';
import PersonalDetails from './form-component/PersonalDetails';
import Experience from './form-component/Experience';
import OpportunityPreferences from './form-component/OpportunityPreferences';
import LocationPreferences from './form-component/LocationPreferences';
import Resume from './form-component/Resume';
import MyCoachingSubscription from './form-component/MyCoachingSubscription';
import Compensation from './form-component/Compensation';
import { globalContext } from '../../store';

function ProfileEditNew() {
  const search = useLocation().search;
  const { state }: any = useLocation();
  const activeTab = parseInt(new URLSearchParams(search).get('active'));
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const [activeTabIndex, setActiveTabIndex] = useState<number>(activeTab ?? state ?? 0);
  const [success, setSuccess] = useState<boolean>(false);
  const [emailSuccess, setEmailSuccess] = useState(false);
  const { dispatch, globalState } = useContext(globalContext);
  const location = useLocation();
  const linkedInURLRef = useRef(null);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTabIndex(newValue);
    setSuccess(false);
    navigate(`/profile-edit?active=${newValue}`);
    setEmailSuccess(false);
  };

  const getProfileData = () => {
    Api.getAccount()
      .then((res) => {
        if (res.success) {
          let data = res.response.data;
          dispatch({ type: 'SET_ACCOUNT', payload: data });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    setActiveTabIndex(activeTab > 7 ? 0 : activeTab);
    setLoading(false);
    window?.scrollTo({
      behavior: 'smooth',
      top: 0,
    });
  }, [activeTab]);

  useEffect(() => {
    let isAuth = Api.isAuthorized();
    if (!isAuth) navigate('/login');

    if (location?.state?.scrollToLinkedIn !== undefined && location?.state?.scrollToLinkedIn === true) {
      if (isMobile) {
        setTimeout(function () {
          scrollToView();
        }, 1500);
      } else {
        scrollToView();
      }
    }
  }, [isMobile]);

  const callOnSuccess = (value: boolean) => {
    setSuccess(value);
    if (value) {
      getProfileData();
    }
  };

  const scrollToView = () => {
    linkedInURLRef?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  };

  let navData = [
    [
      <AccountDetails
        data={{
          uuid: globalState?.account?.uuid,
          email: globalState?.account?.email,
          job_opportunities_reminders_opt_in: globalState?.account?.job_opportunities_reminders_opt_in,
          profile_reminders_opt_in: globalState?.account?.profile_reminders_opt_in,
          assessment_reminders_opt_in: globalState?.account?.assessment_reminders_opt_in,
        }}
        onSuccess={(value: boolean, value2: boolean) => {
          setSuccess(value);
          setEmailSuccess(value2);
          getProfileData();
        }}
        key={0}
      />,
      'Account Details',
    ],
    [<PersonalDetails onSuccess={(value: boolean) => callOnSuccess(value)} data={globalState?.account?.personal_details} timeZoneValue={globalState?.account?.iana_timezone} key={1} linkedInURLRef={linkedInURLRef} />, 'Personal Details'],
    [<Experience onSuccess={(value: boolean) => callOnSuccess(value)} data={globalState?.account?.experience} key={2} />, 'Experience'],
    [<Compensation onSuccess={(value: boolean) => callOnSuccess(value)} data={globalState?.account?.annual_compensation} key={3} />, 'Compensation'],
    [<OpportunityPreferences onSuccess={(value: boolean) => callOnSuccess(value)} data={globalState?.account?.opportunity_preferences} key={4} />, 'Opportunity Preference'],
    [<LocationPreferences onSuccess={(value: boolean) => callOnSuccess(value)} data={globalState?.account?.location} key={5} />, 'Location Preference'],
    [<Resume onSuccess={(value: boolean) => callOnSuccess(value)} data={globalState?.account?.resume} key={6} />, 'My Resume'],
  ];

  if (globalState?.subscriptionStatus?.status != 'NOT_SIGNED_UP') {
    //|| data?.personal_details?.country === 'United States of America'
    navData.splice(7, 0, [<MyCoachingSubscription key={7} />, 'My Coaching Subscription']);
  }

  const containerStyle = {
    padding: '0 !important',
  };

  return (
    <>
      <CssBaseline />
      {loading && <Loader />}
      <Header dataFromEditProfile={globalState?.account} />
      <Container maxWidth="lg" sx={isMobile ? containerStyle : {}}>
        <HDProfileNav tabsData={navData} selectedTab={activeTabIndex} handleChange={handleTabChange} success={success} emailSuccess={emailSuccess} />
      </Container>
      <Footer />
    </>
  );
}

export default ProfileEditNew;
