import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, styled, useTheme } from '@mui/material';
import Link from '@mui/material/Link';

type commonType = string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null;
export interface HDModalTypes {
  sx?: Object,
  open: boolean,
  title?: commonType,
  description?: commonType,
  actions?: commonType,
  handleModal: (arg0: any) => void,
  children?: any,
  backdropProps?: any,
  styledDescription?: any,
  error?: any
}

const HDModal = (props: HDModalTypes) => {
  const themeMode = useTheme()?.palette.mode;

  const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '1.87rem',
    padding: 0,
    [theme.breakpoints.down('sm')]: { marginTop: '20px', paddingBottom: '0px' },
    color: theme.palette.mode == 'dark' ? '#FFFFFF' : '#002136',
  }));

  const StyledDialogContentText = styled(DialogContentText)(({ theme }) => ({
    fontFamily: 'Avenir',
    paddingTop: '7px',
    paddingBottom: 0,
    color: theme.palette.mode == 'dark' ? '#CCCCCC' : '#334255',
    fontWeight: 300,
    fontSize: '1rem'
  }));

  const StyledDialogActions = styled(DialogActions)(() => ({
    justifyContent: 'flex-start !important',
    padding: '0'
  }));

  const StyledBox = {
    overflow: 'auto',
    marginBottom: '24px',
    //padding: '0px 20px 24px'

  };

  const titleWrapStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  };

  const errorStyle = {
    color: '#cc33a1',
    marginTop: '20px',
    fontFamily: 'Avenir',
    fontSize: '16px'
  };

  return (
    <div>
      {/* <Button variant='outlined' onClick={props.handleModal}>
        Open alert dialog
      </Button> */}
      <Dialog
        sx={props?.sx}
        open={props.open}
        onClose={props.handleModal}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        BackdropProps={props.backdropProps}
        PaperProps={{
          style: {
            padding: '24px 24px 32px 24px'
          },
        }}
      >
        <StyledDialogTitle id='alert-dialog-title'>
          <Box sx={titleWrapStyle}>
            <div>{props.title}</div>
            <Link
              href='javascript:void(0)'
              aria-label='close'
              onClick={props.handleModal}
              style={{ color: 'inherit', textDecoration: 'none' }}
            >
              <img alt='Close modal' src={(window.location.origin) + '/res/hd-ui-kit-images/' + ((themeMode === 'light') ? 'close.svg' : 'close-dark.svg')} />
            </Link>
          </Box>
        </StyledDialogTitle>

        <Box sx={props?.styledDescription ? props?.styledDescription : StyledBox}>
          <StyledDialogContentText id='alert-dialog-description'>
            {props.description}
          </StyledDialogContentText>
          {props.children}
        </Box>

        <StyledDialogActions>
          {props.actions}
        </StyledDialogActions>
        {props?.error && <Box sx={errorStyle}>
          {props?.error}
        </Box>}
      </Dialog>
    </div >
  );
};

export default HDModal;

